import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketAnswerAttachmentDto } from '../../dtos/ticket-answer-attachment.dto';
import { TicketAnswerDto } from '../../dtos/ticket-answer.dto';
import { TicketDto } from '../../dtos/ticket.dto';
import { asyncForEach, showError, waitForElm } from '../../helper/utils.helper';
import { TicketApiService } from '../../services/ticket-api.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, debounceTime, distinctUntilChanged, first, switchMap, take, tap } from 'rxjs';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { PredefinedAnswerDto } from '../../dtos/predefined-answer.dto';
import { AppDto } from '../../dtos/app.dto';
import * as CustomBuildEditor from 'src/ckeditor5/build/ckeditor';
import { MailDataAddress } from '../../dtos/mail-data-address.dto';
import { MailDataDto } from '../../dtos/mail-data.dto';
import { SignatureDto } from '../../dtos/signature.dto';
import { Select2SearchEvent, Select2UpdateEvent, Select2UpdateValue } from 'ng-select2-component';
import { LabelDto } from '../../dtos/label.dto';
import { TicketNextDto } from '../../dtos/ticket-next.dto';
import { PreloaderService } from '../../services/preloader.service';
import { ViewportScroller } from '@angular/common';
import { ScrollModeType } from 'ngx-extended-pdf-viewer';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ApiApiService } from '../../services/api-api.service';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { PreloadedAudioAttachmentDto } from '../../dtos/preloaded-audio-attachment.dto';
import { TicketAnswerHistoryDto } from '../../dtos/ticket-answer-history.dto';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-manage-ticket',
  templateUrl: './manage-ticket.component.html',
  styleUrls: ['./manage-ticket.component.scss'],
  host: { class: 'd-block' },
})
export class ManageTicketComponent implements OnInit {


  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  innerWidth: number = 0;

  ticket_id: string = "";
  ticket: TicketDto | undefined;
  nextTickets: TicketNextDto | undefined;
  ticketAnswers: TicketAnswerDto[] = [];
  groups: GroupDto[] = [];
  users: UserDto[] = [];
  apps: AppDto[] = [];
  labels: LabelDto[] = [];
  visibleLabels: LabelDto[] = [];
  selectedAnswer: string = '';

  allowAnswerInMergedTicket = false;

  answerText: string = "";
  answerFiles: File[] = [];
  preloadedAudioAttachments: PreloadedAudioAttachmentDto[] = [];

  showSignature = false;

  isLoading: boolean = false;
  isLoadingAttachment: boolean = false;
  editorLoaded: boolean = false;

  editInternalAnswerMode = false;
  editInternalAnswerId = "";

  disableAutoSignature = false;

  editSubjectMode = false;

  ticketStatusUpdate = {
    priority: "",
    status: "",
    group_id: "",
    assigned_to_user_sso_id: "",
    announceChange: false,
  }

  newRecipient = {
    name: "",
    email: "",
    mode: "CC",
  }

  mentionedUsers: string[] = [];
  mentionedGroups: string[] = [];

  recipientsCC: MailDataAddress[] = [];
  recipientsTO: MailDataAddress[] = [];

  directRecipient: MailDataAddress | undefined;

  recipientMode: "all" | "direct" = 'all';
  answerMode: "public" | "internal" = 'internal';

  mergeableTickets: any = [];
  mergableTicketSearch: string = "";
  mergeTicketId: string = "";
  selectedMergeTicket: TicketDto | undefined;
  mergableSearchEvent = new Subject<string | undefined>();

  incidents: any = [];
  incidentsSearch: string = "";
  incidentsId: string = "";
  incidentsSearchEvent = new Subject<string | undefined>();

  userData: any;

  signature: SignatureDto | undefined;
  selectedSignature = "";

  myPredefinedAnswers: PredefinedAnswerDto[] = [];
  sharedPredefinedAnswers: PredefinedAnswerDto[] = [];
  officialPredefinedAnswers: PredefinedAnswerDto[] = [];

  browserLocale = window.navigator.language;

  public editor: any = CustomBuildEditor;

  blobUrl: any;
  pdfBlob: any;
  pdfFilename: string = "";
  showPdf = false;
  scrollmode: ScrollModeType = ScrollModeType.vertical;

  @ViewChild('answerEditor') editorComponent: CKEditorComponent | undefined;

  editorConfig = {}

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected ticketApi: TicketApiService,
    protected apiService: ApiApiService,
    protected domSanitizer: DomSanitizer,
    protected landingpageService: LandingpageApiService,
    public oidcSecurityService: OidcSecurityService,
    private preloaderService: PreloaderService,
    private viewportScroller: ViewportScroller,
    protected titleService: Title
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.ticket_id = params['id'];

        // Check if localstorage has saved data
        if (localStorage.getItem('savedTicketAnswes')) {
          const savedData = JSON.parse(localStorage.getItem('savedTicketAnswes') !== null ? localStorage.getItem('savedTicketAnswes')! : '[]');
          const savedAnswer = savedData.find((el: any) => el.ticketId === this.ticket_id);
          if (savedAnswer) {
            this.answerText = savedAnswer.data;
          }
        }

        this.preloaderService.dataLoaded.subscribe({
          next: () => {
            this.loadData();
          },
          error: (err) => {
            this.isLoading = false;
            showError(err);
          },
        });
      }
    })
  }

  removeDirectMention(name: string) {
    this.mentionedUsers = this.mentionedUsers.filter(x => x != name);
  }

  removeDirectMentionGroup(name: string) {
    this.mentionedGroups = this.mentionedGroups.filter(x => x != name);
  }

  addDirectMention(name: string) {
    if (!this.mentionedUsers.includes(name)) {
      this.mentionedUsers.push(name);
    }
  }

  addDirectMentionGroup(name: string) {
    if (!this.mentionedGroups.includes(name)) {
      this.mentionedGroups.push(name);
    }
  }

  switchToCC(recipient: MailDataAddress) {
    this.recipientsCC.push(recipient);
    this.recipientsTO = this.recipientsTO.filter(x => x != recipient);
    this.checkRecipients();
  }

  switchToTo(recipient: MailDataAddress) {
    this.recipientsTO.push(recipient);
    this.recipientsCC = this.recipientsCC.filter(x => x != recipient);
    this.checkRecipients();
  }

  removeRecipient(recipient: MailDataAddress) {
    this.recipientsTO = this.recipientsTO.filter(x => x != recipient);
    this.recipientsCC = this.recipientsCC.filter(x => x != recipient);

    this.checkRecipients();
  }

  addNewRecipient() {
    if (this.newRecipient.email === '' || this.newRecipient.name === '' || this.newRecipient.mode === '') {
      Swal.fire({
        title: 'Bitte geben Sie einen Namen und eine E-Mail Adresse ein.',
        icon: 'warning',
      })
      return;
    }

    if (this.recipientsTO.find(el => el.address === this.newRecipient.email) || this.recipientsCC.find(el => el.address === this.newRecipient.email)) {
      Swal.fire({
        title: 'Diese E-Mail Adresse wurde bereits hinzugefügt.',
        icon: 'warning',
      })
      return;
    }

    if (this.newRecipient.mode === 'CC') {
      this.recipientsCC.push({
        name: this.newRecipient.name,
        address: this.newRecipient.email,
      });
    } else {
      this.recipientsTO.push({
        name: this.newRecipient.name,
        address: this.newRecipient.email,
      });
    }

    this.newRecipient.email = "";
    this.newRecipient.name = "";

    this.checkRecipients();
  }

  fileUploadEvent(event: any) {
    this.answerFiles = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.answerFiles.push(event.target.files[i]);
    }
  }

  loadData() {
    this.isLoading = true;
    this.ticketApi.getTicket(this.ticket_id).subscribe({
      next: (data) => {
        this.ticket = data;

        this.directRecipient = {
          name: this.ticket.author_name,
          address: this.ticket.author_email,
        }

        this.titleService.setTitle(`${this.ticket.subject} - EFSsupport System`);

        this.ticketStatusUpdate.status = this.ticket.status!;
        this.ticketStatusUpdate.group_id = this.ticket.group_id!;
        this.ticketStatusUpdate.assigned_to_user_sso_id = this.ticket.assigned_to_user_sso_id!;
        this.ticketStatusUpdate.priority = this.ticket.priority;

        // Load the ticket answers after the ticket
        this.ticketApi.getTicketAnswers(this.ticket_id).subscribe({
          next: (data) => {
            this.isLoading = false;
            this.ticketAnswers = data;

            this.preloadAudioAttachments();

            this.scrollToAnswer();

            this.getAllCCs(data);
          },
          error: (error) => {
            showError(error);
            this.isLoading = false;
          },
        });

        this.ticketApi.getLabels().subscribe({
          next: (labels) => {
            this.labels = labels;
            this.visibleLabels = labels.filter(x => !x.hidden);
          },
          error: (error) => {
            showError(error);
          }
        });

        this.ticketApi.getNextTicket(this.ticket_id).subscribe({
          next: (data) => {
            this.nextTickets = data;
          },
          error: (error) => {
            showError(error);
          }
        });

        this.landingpageService.getMyAppsAll().subscribe({
          next: (data) => {
            this.apps = data;
          },
          error: (error) => {
            showError(error);
          }
        })

      },
      error: (error) => {
        showError(error);
      },
    });

    this.ticketApi.getPredefinedAnswers().subscribe({
      next: (data) => {
        this.myPredefinedAnswers = data.filter(x => x.user_sso_id == this.userData.nickname && !x.official);
        this.sharedPredefinedAnswers = data.filter(x => x.user_sso_id != this.userData.nickname && x.public && !x.official);
        this.officialPredefinedAnswers = data.filter(x => x.official == true);
      },
      error: (error) => {
        showError(error);
      }
    });

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.ticketApi.getGroups().subscribe({
      next: async (data) => {
        this.groups = data;

        const supportHandles = (await this.ticketApi.getSupportUsers().toPromise())?.filter(el => !el.terminationDate).map(el => `@${el.name}`).concat(this.groups.map(el => `@Gruppe: ${el.name}`));

        const saveDataInner = this.saveData;
        const instance = this;

        this.editorConfig = {
          placeholder: "Schreibe eine Antwort - Markiere Personen / Gruppen mit @ zum Benachrichtigen - Deine Signatur wird automatisch angehängt",
          toolbar: {
            items: [
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              '|',
              'fontSize',
              'fontColor',
              '|',
              'outdent',
              'indent',
              'alignment',
              '|',
              'imageUpload',
              'blockQuote',
              'insertTable',
              'mediaEmbed',
              'undo',
              'redo',
              'codeBlock',
              'code'
            ]
          },
          link: {
            decorators: {
              isExternal: {
                mode: 'automatic',
                callback: (url: String) => url.startsWith('http://') || url.startsWith('https://') || url.startsWith('www'),
                attributes: {
                  target: '_blank'
                }
              }
            }
          },
          autosave: {
            save(editor: any) {
              return saveDataInner(editor.getData(), instance.ticket?._id!);
            }
          },
          mention: {
            feeds: [
              {
                marker: '@',
                feed: supportHandles,
                dropdownLimit: Infinity,
              },
            ],
          },
          language: 'de',
        };

        this.editorLoaded = true;
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      }
    })

    this.mergableSearchEvent
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.mergeableTickets = [{ value: "", label: "Lade..." }];
        }),
        switchMap((searchQuery) => this.ticketApi.getMyTicketsUnpagedAllTypes(searchQuery!))
      ).subscribe((results) => {
        this.mergeableTickets = results.data.map(el => {
          return {
            value: el._id,
            label: `${el.ticket_number} - ${el.subject}`,
            data: el
          }
        })

        if (results.data.length === 0) {
          this.mergeableTickets = [{ value: "", label: "Kein passendes Anliegen gefunden!", data: null }];
        }
      });

    this.incidentsSearchEvent
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.incidents = [{ value: "", label: "Lade..." }];
        }),
        switchMap((searchQuery) => this.ticketApi.getIncidentsUnpaged(searchQuery!))
      ).subscribe((results) => {
        this.incidents = results.data.map(el => {
          return {
            value: el._id,
            label: `${el.description}`,
          }
        })

        if (results.data.length === 0) {
          this.incidents = [{ value: "", label: "Kein passenden Störungen gefunden!" }];
        }
      });

    this.ticketApi.getSupportUsers().subscribe({
      next: (data: UserDto[]) => {
        this.users = data.filter(el => !el.terminationDate);
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      }
    })

    this.ticketApi.getMySignature().subscribe({
      next: (data) => {
        this.signature = data;
        this.selectedSignature = data.signature;
      },
      error: (err) => {
        showError(err);
        this.isLoading = false;
      }
    });

    this.getMergableTickets();
    this.getIncidents();
  }

  public onEditorReady(event: any) {
    /* // Register the keystroke to enable the edit mode on the last answer
    event.keystrokes.set('arrowup', (data: any, stop: any) => {

      // Check if the editor has no changes
      const filteredLastAnswer = this.ticketAnswers.filter(el => el.type === 'internalAnswer');
      const lastAnswer = filteredLastAnswer[filteredLastAnswer.length - 1];

      console.log(lastAnswer);

      if (this.answerText.trim().length === 0 && lastAnswer.author_user_sso_id === this.userData.nickname) {
        this.editAnswer(lastAnswer._id!);
      }
    }); */
  }

  saveSendReport(messageId: string) {
    this.isLoading = true;
    messageId = messageId.replace('<', '').replace('>', '');
    this.apiService.getReport(messageId).subscribe({
      next: (data) => {
        this.isLoading = false;
        const blob = new Blob([data], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, `Report_${messageId}_${Date.now()}.pdf`);
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      },
    })
  }

  getIncidents() {
    this.ticketApi.getIncidentsUnpaged(this.incidentsSearch).subscribe({
      next: (data) => {

        this.incidents = data.data.map(el => {
          return {
            value: el._id,
            label: `${el.description}`,
          }
        })
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    })
  }

  getMergableTickets() {
    this.ticketApi.getMyTicketsUnpaged(this.mergableTicketSearch).subscribe({
      next: (data) => {

        this.mergeableTickets = data.data.map(el => {
          return {
            value: el._id,
            label: `${el.ticket_number} - ${el.subject}`,
          }
        })
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    })
  }

  htmlContent(content: string) {
    return this.domSanitizer.sanitize(SecurityContext.NONE, content);
  }

  toggleFold(answer: TicketAnswerDto) {

    if (answer.folded === undefined) {
      answer.folded = false;
    } else {
      answer.folded = !answer.folded;
    }
  }

  downloadAttachment(ticketAnswerId: string, attachment: TicketAnswerAttachmentDto) {
    if (["image/png", "image/gif", "image/jpeg", "image/*", "application/pdf", "video/mp4"].includes(attachment.mimetype)) {
      // open in new tab
      const url = this.router.serializeUrl(this.router.createUrlTree(['/admin/attachment', ticketAnswerId, attachment.slug]));
      window.open(url, '_blank');

    } else {
      this.isLoadingAttachment = true;
      this.ticketApi.downloadFile(ticketAnswerId, attachment.slug).subscribe({
        next: (data) => {
          const blob = new Blob([data], { type: attachment.mimetype });
          this.isLoadingAttachment = false;
          saveAs(blob, attachment.filename);
        },
        error: (error) => {
          this.isLoadingAttachment = false;
          showError(error);
        }
      });
    }
  }

  insertAnswer(answer: PredefinedAnswerDto) {

    if (this.ticket?.author_user_sso_id) {

      this.ticketApi.searchUsers(this.ticket?.author_user_sso_id).subscribe({
        next: (data) => {
          if (data.length > 0) {
            const user = data[0];
            let salutation = `Sehr geehrter Herr ${user.last_name}`;

            if (user.gender === 'female') {
              salutation = `Sehr geehrte Frau ${user.last_name}`;
            }

            if (user.gender === 'company') {
              salutation = `Hallo ${this.ticket!.author_name}`;
            }

            this.answerText = answer.text.replace('%name', salutation);

          } else {
            this.answerText = answer.text.replace('%name', `Hallo ${this.ticket!.author_name}`);
          }
        },
        error: (error) => {
          this.answerText = answer.text.replace('%name', `Hallo ${this.ticket!.author_name}`);
        }
      })

    } else {
      this.answerText = answer.text.replace('%name', `Hallo ${this.ticket!.author_name}`);
    }
  }

  async updateInternalAnswer(answer: TicketAnswerDto) {
    this.isLoading = true;

    this.ticketApi.updateTicketAnswer(answer._id!, answer).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.editInternalAnswerMode = false;
        this.loadData();
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });
  }

  async answerTicket(type: 'supportAnswer' | 'internalAnswer' = 'supportAnswer') {

    if (this.answerMode === 'internal') {
      type = 'internalAnswer';
    }

    this.isLoading = true;

    let uploadedData: TicketAnswerAttachmentDto[] = [];

    if (this.answerText.trim().length === 0) {
      this.isLoading = false;
      await Swal.fire({
        title: 'Bitte gebe eine Antwort ein!',
        icon: 'warning',
      })
      return;
    }

    if (this.answerText.includes('Anhang') && this.answerFiles.length === 0) {
      this.isLoading = false;
      await Swal.fire({
        text: 'Deine Antwort enthält den Text "Anhang" aber du hast keine Datei angehängt. Möchtest du trotzdem fortfahren?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Nein',
        cancelButtonText: 'Ja'
      }).then((result) => {
        if (result.isConfirmed) {
          throw new Error('Abort send');
        } else {
          this.isLoading = true;
        }
      })
    }


    /**
     * False Ticket Mode detection
     * 
     * This feature should test if the supporter selected the right ticket mode for his answer.
     * We create a list of possible salutations for all possible recipients and check if the answer contains one of them.
     * If the answer contains a salutation, but the answer mode is internal we consider this as a false ticket mode.
     * The same applies if the answer mode is public but the answer does not contain a salutation.
     */

    const recipients = this.recipientsCC.concat(this.recipientsTO);

    // If the recipients contain a recipient without a name, we cannot check for the correct answer mode
    const doNotCheckMode = recipients.find(el => el.name === '');

    if (!doNotCheckMode) {
      // All possible recipients have a name so we create a list of possible salutations of all possible recipients
      // Default salutations of the ticket author
      const possibleSalutations = [`Sehr geehrte Damen und Herren`, `Liebe Damen und Herren`];
      const possibleSalutationStarts = ['Hallo', 'Hallo Herr', 'Lieber Herr', 'Liebe Frau', 'Hallo Frau', 'Hallo', 'Sehr geehrter Herr', 'Sehr geehrte Frau', 'Hallo lieber', 'Hallo liebe', 'Lieber'];

      await asyncForEach(recipients, async (recipient: MailDataAddress) => {
        await asyncForEach(possibleSalutationStarts, async (salutation: string) => {
          // Use the full name as for the salutation
          if (!possibleSalutations.includes(`${salutation} ${recipient.name}`)) {
            possibleSalutations.push(`${salutation} ${recipient.name}`);
          }

          // If the name contains a space, try to find the last name and add it to the salutation
          if (recipient.name.includes(' ')) {
            const nameParts = recipient.name.split(' ');
            const lastName = nameParts[nameParts.length - 1];

            if (!possibleSalutations.includes(`${salutation} ${lastName}`)) {
              possibleSalutations.push(`${salutation} ${lastName}`);
            }
          }
        });
      });

      if (type === 'internalAnswer' && possibleSalutations.some(v => this.answerText.includes(v))) {
        this.isLoading = false;
        await Swal.fire({
          text: 'Es scheint, als würdest du eine öffentliche Antwort senden wollen, aber der Antwort-Modus ist aktuell auf "Intern" gesetzt. Trotzdem fortfahren?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Nein',
          cancelButtonText: 'Ja, Antwort senden!'
        }).then((result) => {
          if (result.isConfirmed) {
            throw new Error('Abort send');
          } else {
            this.isLoading = true;
          }
        })
      }

      if (type === 'supportAnswer' && !possibleSalutations.some(v => this.answerText.includes(v))) {
        this.isLoading = false;
        await Swal.fire({
          text: 'Es scheint, als würdest du eine interne Antwort senden wollen, aber der Antwort-Modus ist aktuell auf "Öffentlich" gesetzt. Trotzdem fortfahren?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Nein',
          cancelButtonText: 'Ja, Antwort senden!'
        }).then((result) => {
          if (result.isConfirmed) {
            throw new Error('Abort send');
          } else {
            this.isLoading = true;
          }
        })
      }
    }

    if (type === 'supportAnswer' && recipients.length === 0) {
      this.isLoading = false;
      await Swal.fire({
        title: 'Bitte füge mindestens einen Empfänger hinzu!',
        icon: 'warning',
      })
      return;
    }

    const answers = await this.ticketApi.getTicketAnswers(this.ticket_id).toPromise().catch(error => {
      showError(error);
      this.isLoading = false;
    });

    if (
      answers &&
      answers.filter(el => ['supportAnswer', 'internalAnswer', 'customerAnswer'].includes(el.type)).length !==
      this.ticketAnswers.filter(el => ['supportAnswer', 'internalAnswer', 'customerAnswer'].includes(el.type)).length
    ) {
      this.ticketAnswers = answers;

      this.isLoading = false;
      await Swal.fire({
        text: 'Während du deine Antwort geschrieben hast, ist eine neue Antwort eingegangen. Die Übersicht wurde aktualisiert. Trotzdem fortfahren?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Nein',
        cancelButtonText: 'Ja, Antwort senden!'
      }).then((result) => {
        if (result.isConfirmed) {

          setTimeout(() => {
            const textAnswers = answers.filter(el => ['supportAnswer', 'internalAnswer', 'customerAnswer'].includes(el.type));
            this.viewportScroller.scrollToAnchor(`ID${textAnswers[textAnswers.length - 1]._id!}`);
          }, 250)

          throw new Error('Abort send');
        } else {
          this.isLoading = true;
        }
      })
    }

    if (this.answerFiles.length > 0) {
      await asyncForEach(this.answerFiles, async (file: File) => {
        const uploadedDataResponse = await this.ticketApi.uploadFiles(file).toPromise().catch((error) => {
          showError(error);
          this.isLoading = false;

          throw new Error('Upload failed!');
        });

        if (uploadedDataResponse) {
          uploadedData.push(uploadedDataResponse)
        }
      });
    }

    this.ticketStatusUpdate.announceChange = false;

    // Update the ticket to answered if the status is open and no new status ist set
    if (type === 'supportAnswer' && this.ticket?.status! === 'open' && this.ticketStatusUpdate.status === this.ticket?.status!) {
      this.ticketStatusUpdate.status = 'answered';
    }

    let mailData: MailDataDto = {
      to: this.recipientsTO,
      cc: this.recipientsCC,
    }

    if (this.recipientMode === 'direct') {
      mailData.to = [this.directRecipient!];
      mailData.cc = [];
    }

    this.answerText += `<br>${this.selectedSignature}`;
    // Replace the background color of the editor
    this.answerText = this.answerText.replaceAll('span style="background-color: rgb(39,49,70); color: rgb(252,253,254)"', 'span');

    this.ticketApi.answerTicket(this.ticket!._id!, {
      text: this.answerText,
      attachments: uploadedData,
      type,
      author_name: this.userData.name,
      author_email: this.userData.email,
      author_user_sso_id: this.userData.nickname,
      source: 'landingpage',
      template_data: [],
      mail_data: mailData,
      internal_mentions: this.mentionedUsers,
      internal_mentions_group: this.mentionedGroups,
    }).subscribe({
      next: async (data: TicketAnswerDto) => {

        if (this.ticketStatusUpdate.status !== this.ticket?.status) {
          await this.updateStatus(true);
        }

        if (this.ticketStatusUpdate.group_id !== this.ticket?.group_id) {
          await this.assignGroup(true);
        }

        if (this.ticketStatusUpdate.assigned_to_user_sso_id !== this.ticket?.assigned_to_user_sso_id) {
          await this.assignUser(true);
        }

        if (this.ticketStatusUpdate.priority !== this.ticket?.priority) {
          await this.updatePriority(true);
        }

        if (
          this.ticketStatusUpdate.status !== this.ticket?.status ||
          this.ticketStatusUpdate.group_id !== this.ticket?.group_id ||
          this.ticketStatusUpdate.assigned_to_user_sso_id !== this.ticket?.assigned_to_user_sso_id ||
          this.ticketStatusUpdate.priority !== this.ticket?.priority
        ) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        this.loadData();
        this.answerText = "";
        this.answerFiles = [];
        this.mentionedUsers = [];
        this.mentionedGroups = [];
        this.answerMode = 'internal';
        this.isLoading = false;

        if (localStorage.getItem('savedTicketAnswes')) {
          const savedData = JSON.parse(localStorage.getItem('savedTicketAnswes') !== null ? localStorage.getItem('savedTicketAnswes')! : '[]');
          const savedAnswer = savedData.find((el: any) => el.ticketId === this.ticket_id);
          if (savedAnswer) {
            savedData.splice(savedData.indexOf(savedAnswer), 1);
            localStorage.setItem('savedTicketAnswes', JSON.stringify(savedData));
          }
        }

        if (data._id) {
          waitForElm(`#ID${data._id!}`).then((elm) => {
            this.viewportScroller.scrollToAnchor(`ID${data._id!}`);
          });
        }

        Swal.fire({
          title: 'Deine Antwort wurde erfolgreich gesendet!',
          icon: 'success',
        })
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      },
    })

  }

  getAppName(id: string): string {
    const app = this.apps.find(g => g._id === id);

    if (app) {
      return app.name;
    }
    return '';
  }

  updateTicketType() {
    this.isLoading = true;

    this.ticketApi.updateTicketType(this.ticket!._id!, this.ticket!.type!).subscribe({
      next: () => {
        this.isLoading = false;
        Swal.fire({
          title: 'Der Anliegen-Typ wurde geändert!',
          icon: 'success',
        }).then(() => {
          this.preloaderService.refreshSidebarData();
          this.loadData();
          this.isLoading = false;
        })
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      }
    })
  }

  assignGroup(silent = false) {
    if (this.ticket?.group_id !== this.ticketStatusUpdate.group_id) {
      this.isLoading = true;

      this.ticketApi.assign(this.ticket!._id!, {
        assign_value: this.ticketStatusUpdate.group_id,
        assign_type: 'group',
        announce_assignment: this.ticketStatusUpdate.announceChange,

      }).subscribe({
        next: (data) => {
          if (!silent) {
            this.loadData();
            this.isLoading = false;
            Swal.fire({
              title: 'Die Zuordnung wurde erfolgreich aktualisiert!',
              icon: 'success',
            })
          } else {
            this.loadData();
            this.isLoading = false;
          }
        },
        error: (error) => {
          showError(error);
          this.isLoading = false;
        },
      })
    } else {
      Swal.fire({
        title: 'Das Anliegen ist bereits der Gruppe zugeordnet!',
      })
    }
  }

  downloadRawMail(answer: TicketAnswerDto) {

    if (answer.mail_data && answer.mail_data.raw_mail && answer.mail_data.raw_mail.startsWith('./storage')) {
      this.isLoading = true;
      this.ticketApi.downloadMail(answer._id!).subscribe({
        next: (data) => {
          this.isLoading = false;
          const blob = new Blob([data], { type: "message/rfc822" });
          saveAs(blob, `Anliegen-#${this.ticket?.ticket_number!}_mail_${answer._id}.eml`);
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        },
      })
    }

    if (answer.mail_data && answer.mail_data.raw_mail && !answer.mail_data.raw_mail.startsWith('./storage')) {
      const blob = new Blob([answer.mail_data?.raw_mail!], { type: "message/rfc822" });
      saveAs(blob, `Anliegen-#${this.ticket?.ticket_number!}_mail_${answer._id}.eml`);
    }
  }

  quickAssignMe() {
    this.ticketStatusUpdate.assigned_to_user_sso_id = this.userData.nickname;
    this.assignUser();
  }

  quickUpdateStatus(status: string) {
    this.ticketStatusUpdate.status = status;
    this.updateStatus(true);
  }

  async getAllCCs(ansers: TicketAnswerDto[]) {
    const ccs: MailDataAddress[] = [];
    const tos: MailDataAddress[] = [];

    const ingestMails: string[] = [];
    await asyncForEach(this.groups, async (group: GroupDto) => {
      ingestMails.push(...group.ingest_mailboxes);
    });


    tos.push({
      name: this.ticket?.author_name!,
      address: this.ticket?.author_email!,
    })

    await asyncForEach(ansers, async (answer: TicketAnswerDto) => {
      if (answer.mail_data && answer.mail_data.cc) {
        await asyncForEach(answer.mail_data.cc, async (cc: MailDataAddress) => {
          if (
            cc.address &&
            !ccs.find(el => el.address.toLowerCase() === cc.address.toLowerCase().trim()) &&
            !tos.find(el => el.address.toLowerCase() === cc.address.toLowerCase().trim()) &&
            !ingestMails.includes(cc.address.toLowerCase().trim())
          ) {
            cc.address = cc.address.toLowerCase().trim();
            ccs.push(cc);
          }
        });
      }

      if (answer.mail_data && answer.mail_data.to) {
        await asyncForEach(answer.mail_data.to, async (to: MailDataAddress) => {
          if (
            to.address &&
            !tos.find(el => el.address.toLowerCase() === to.address.toLowerCase().trim()) &&
            !ccs.find(el => el.address.toLowerCase() === to.address.toLowerCase().trim()) &&
            !ingestMails.includes(to.address.toLowerCase().trim())
          ) {
            to.address = to.address.toLowerCase().trim();
            tos.push(to);
          }
        });
      }
    });

    this.recipientsCC = ccs;
    this.recipientsTO = tos;
    this.checkRecipients();
  }

  updateSubject() {
    this.isLoading = true;

    this.ticketApi.updateSubject(this.ticket!._id!, this.ticket?.subject!).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.editSubjectMode = false;
        this.loadData();
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      },
    });
  }

  assignUser(silent = false) {
    if (this.ticket?.assigned_to_user_sso_id !== this.ticketStatusUpdate.assigned_to_user_sso_id) {
      this.isLoading = true;

      this.ticketApi.assign(this.ticket!._id!, {
        assign_value: this.ticketStatusUpdate.assigned_to_user_sso_id,
        assign_type: 'user',
        announce_assignment: this.ticketStatusUpdate.announceChange,

      }).subscribe({
        next: (data) => {
          if (!silent) {
            this.loadData();
            this.isLoading = false;
            Swal.fire({
              title: 'Die Zuordnung wurde erfolgreich aktualisiert!',
              icon: 'success',
            });
          } else {
            this.loadData();
            this.isLoading = false;
          }
        },
        error: (error) => {
          showError(error);
          this.isLoading = false;
        },
      })
    } else {
      Swal.fire({
        title: 'Das Anliegen ist bereits dem Benutzer zugeordnet!',
      })
    }
  }

  updatePriority(silent = false) {
    if (this.ticket?.priority !== this.ticketStatusUpdate.priority) {
      this.isLoading = true;

      this.ticketApi.updatePriority(this.ticket!._id!, {
        priority: this.ticketStatusUpdate.priority,
        announce_update: this.ticketStatusUpdate.announceChange,

      }).subscribe({
        next: (data) => {
          if (!silent) {
            this.isLoading = false;
            Swal.fire({
              title: 'Die Priorität wurde erfolgreich aktualisiert!',
              icon: 'success',
            }).then(() => {
              this.loadData();
            })
          } else {
            this.loadData();
            this.isLoading = false;
          }
        },
        error: (error) => {
          showError(error);
          this.isLoading = false;
        },
      })
    } else {
      Swal.fire({
        title: 'Das Anliegen ist bereits der Priorität zugeordnet!',
      })
    }
  }

  async updateStatus(silent = false) {
    if (this.ticket?.status !== this.ticketStatusUpdate.status) {
      this.isLoading = true;

      this.ticketApi.updateStatus(this.ticket!._id!, {
        status: this.ticketStatusUpdate.status,
        announce_update: this.ticketStatusUpdate.announceChange,

      }).subscribe({
        next: (data) => {
          if (!silent) {
            this.isLoading = false;
            Swal.fire({
              title: 'Der Status wurde erfolgreich aktualisiert!',
              icon: 'success',
            }).then(() => {
              this.loadData();
            })
          } else {
            this.loadData();
            this.isLoading = false;
          }
          this.preloaderService.refreshSidebarData();
        },
        error: (error) => {
          showError(error);
          this.isLoading = false;
        },
      })
    } else {
      Swal.fire({
        title: 'Das Anliegen ist bereits dem Status zugeordnet!',
      })
    }
  }

  groupById(id: string) {
    return this.groups.find(group => group._id === id)?.name;
  }

  groupsByIds(ids: string[]) {
    return ids.map(el => { return this.groups.find(group => group._id === el) });
  }

  userById(id: string) {
    return this.users.find(user => user.user_sso_id === id)?.name;
  }

  assignIncident() {
    if (this.incidentsId === '') {
      Swal.fire({
        icon: 'warning',
        text: 'Bitte wähle eine Störung aus der Liste aus, die diesem Anliegen zugeordnet werden soll.'
      });
      return;
    }

    this.ticketApi.addIncientToTicket(this.ticket!._id!, this.incidentsId).subscribe({
      next: () => {
        this.isLoading = false;
        Swal.fire({
          icon: 'success',
          text: 'Das Anliegen wurde der Störung zugeordnet.',
        })
        this.loadData();
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });
  }

  async mergeTicket() {
    if (this.mergeTicketId === '') {
      Swal.fire({
        icon: 'warning',
        text: 'Bitte wähle ein Anliegen aus der Liste aus, mit dem dieses Anliegen zusammengeführt werden soll.'
      });
      return;
    }

    if (this.mergeTicketId === this.ticket?._id) {
      Swal.fire({
        icon: 'warning',
        text: 'Du kannst das Anliegen nicht mit sich selbst zusammenführen.'
      });
      return;
    }

    // check if the merge ticket is already merged

    if (this.selectedMergeTicket && this.selectedMergeTicket.merged_with_ticket_id) {
      await Swal.fire({
        text: 'Der Zielticket wurde bereits mit einem anderen Anliegen zusammengeführt. Möchtest du trotzdem fortfahren?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Nein',
        cancelButtonText: 'Ja'
      }).then((result) => {
        if (result.isConfirmed) {
          throw new Error('Abort send');
        } else {
          this.isLoading = true;
        }
      })
    }

    this.ticketApi.mergeTicket(this.ticket!._id!, this.mergeTicketId).subscribe({
      next: () => {
        this.isLoading = false;
        Swal.fire({
          icon: 'success',
          text: 'Das Anliegen wurde erfolgreich zusammengeführt.',
        }).then(() => {
          this.router.navigate(['/admin/tickets', this.mergeTicketId]);
        })
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });
  }

  revertMerge() {
    Swal.fire({
      text: 'Wirklich die Zusammenführung rückgängig machen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ticketApi.unmergeTicket(this.ticket?._id!, this.ticket?.merged_with_ticket_id!).subscribe({
          next: () => {
            this.loadData();
          },
          error: (error) => {
            showError(error);
          }
        });
      }
    })
  }

  openAdminpanel(user_sso_id: string) {
    if (user_sso_id.startsWith('A0') || user_sso_id.startsWith('U0')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Austria/${user_sso_id}`, '_blank');
    } else if (user_sso_id.startsWith('D0')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Germany/${user_sso_id}`, '_blank');
    } else if (user_sso_id.startsWith('H0')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Hungary/${user_sso_id}`, '_blank');
    } else if (user_sso_id.startsWith('E0')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Extern/${user_sso_id}`, '_blank');
    } else if (user_sso_id.startsWith('I0')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Innendienst/${user_sso_id}`, '_blank');
    } else if (user_sso_id.startsWith('X0')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Assistenten/${user_sso_id}`, '_blank');
    } else if (user_sso_id.includes('@')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Extern/${user_sso_id}`, '_blank');
    }
  }

  editAnswer(answerId: string) {
    this.editInternalAnswerMode = true;
    this.editInternalAnswerId = answerId;
  }

  editSubject() {
    this.editSubjectMode = true;
  }

  changeAnswerMode(newAnswerMode: "public" | "internal") {
    this.answerMode = newAnswerMode;

    this.checkRecipients();
  }

  checkRecipients() {
    if (this.disableAutoSignature) {
      this.selectedSignature = '';
      return;
    }

    const internalDomains = ['@efs-ag.de', '@efs-ag.at', '@efs-ag.com', '@efs-ag.net', '@efs-mail.de', '@efs-mail.at'];
    let containsPublicRecipients = false;
    const recipients = this.recipientsCC.concat(this.recipientsTO);

    recipients.forEach(recipient => {
      if (!internalDomains.some(domain => recipient.address.includes(domain))) {
        containsPublicRecipients = true;
      }
    });

    if (containsPublicRecipients) {
      this.selectedSignature = this.signature?.signature_public!;
    } else {
      this.selectedSignature = this.signature?.signature_company!;
    }

    if (this.answerMode === 'internal') {
      this.selectedSignature = this.signature?.signature!;
    }
  }

  updateMergableTicket(event: Select2UpdateEvent<Select2UpdateValue>) {
    if (event.value) {
      this.mergeTicketId = event.value as string;
      this.selectedMergeTicket = this.mergeableTickets.find((el: any) => el.value === this.mergeTicketId)?.data;
    }
  }

  updateSearchMergableTickets(event: Select2SearchEvent<Select2UpdateValue>) {
    if (event.search && event.search !== '') {
      this.mergableSearchEvent.next(event.search);
    }
  }

  updateIncident(event: Select2UpdateEvent<Select2UpdateValue>) {
    if (event.value) {
      this.incidentsId = event.value as string;
    }
  }

  updateSearchIncident(event: Select2SearchEvent<Select2UpdateValue>) {
    if (event.search && event.search !== '') {
      this.incidentsSearchEvent.next(event.search);
    }
  }

  getLabel(id: string): LabelDto {
    const label = this.labels.find(g => g._id === id);
    if (label) {
      return label;
    }
    return {
      _id: '',
      name: '',
      color: '',
      hidden: false,
    };
  }

  removeSubGroup(group: GroupDto) {
    Swal.fire({
      text: `Wirklich die Gruppe "${group.name}" aus dem Anliegen entfernen?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ticketApi.unassignSubGroup(this.ticket!._id!, group._id!).subscribe({
          next: () => {
            this.loadData();
          },
          error: (error) => {
            showError(error);
          }
        });
      }
    })
  }

  removeLabel(labelId: string) {
    Swal.fire({
      text: 'Wirklich das Label von der Anfrage entfernen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ticketApi.unattachLabel(this.ticket!._id!, labelId).subscribe({
          next: () => {
            this.loadData();
          },
          error: (error) => {
            showError(error);
          }
        });
      }
    })
  }

  addLabel(label: LabelDto) {
    this.ticketApi.attachLabel(this.ticket!._id!, label._id!).subscribe({
      next: () => {
        this.loadData();
      },
      error: (error) => {
        showError(error);
      }
    });
  }

  prevTicket() {
    this.router.navigate(['/admin/tickets', this.nextTickets?.previousTicketId]);
  }

  nextTicket() {
    this.router.navigate(['/admin/tickets', this.nextTickets?.nextTicketId]);
  }

  scrollToAnswer() {
    this.route.fragment.pipe(
      first()
    ).subscribe(fragment => {
      if (fragment) {
        this.selectedAnswer = fragment;
        waitForElm(`#ID${fragment}`).then((elm) => {
          this.viewportScroller.scrollToAnchor(`ID${fragment}`);
        });
      }
    });
  }

  addReaction(event: any, answer: TicketAnswerDto) {
    console.log(`Reaction ${event.emoji.name} added to answer ${answer._id}`);
    answer.showReactionPicker = false;
  }

  toggleHidden(answer: TicketAnswerDto) {
    if (answer.showHidden || answer.showHidden === undefined) {
      answer.showHidden = false;
    } else {
      answer.showHidden = true;
    }
  }

  copyDirectLink(answer: TicketAnswerDto) {
    // create link with ticket id and answer id and copy to clipboard
    const link = `${window.location.origin}/admin/tickets/${this.ticket_id}#${answer._id}`;
    navigator.clipboard.writeText(link);
  }

  saveData(data: any, ticketId: string) {
    const savedData = JSON.parse(localStorage.getItem('savedTicketAnswes') !== null ? localStorage.getItem('savedTicketAnswes')! : '[]');
    const entry = savedData.find((el: any) => el.ticketId === ticketId);

    if (data !== '') {
      if (entry) {
        entry.data = data;
      } else {
        savedData.push({
          ticketId,
          data
        });
      }
      localStorage.setItem('savedTicketAnswes', JSON.stringify(savedData))
    } else {
      // Remove entry
      if (entry) {
        savedData.splice(savedData.indexOf(entry), 1);
        localStorage.setItem('savedTicketAnswes', JSON.stringify(savedData))
      }
    }
  }

  isExternalSender(answer: TicketAnswerDto) {
    const internalDomains = ['@efs-ag.de', '@efs-ag.at', '@efs-ag.com', '@efs-ag.net', '@efs-mail.de', '@efs-mail.at', '@efs-zrt.hu', '@efs-ag.services'];
    return (!internalDomains.some(domain => answer.author_email.includes(domain)) && !answer.author_user_sso_id);
  }

  async preloadAudioAttachments() {
    await asyncForEach(this.ticketAnswers, async (answer: TicketAnswerDto) => {
      await asyncForEach(answer.attachments, async (attachment: TicketAnswerAttachmentDto) => {
        if (attachment.mimetype === 'audio/webm' && attachment.filename.startsWith('Sprachmitteilung_')) {
          const data = await this.ticketApi.downloadFile(answer._id!, attachment.slug).toPromise().catch((error) => {
            showError(error);
          });

          if (data) {
            const blob = new Blob([data], { type: attachment.mimetype });
            const blobUrl = URL.createObjectURL(blob);

            this.preloadedAudioAttachments.push({
              ticketAnswerId: answer._id!,
              fileName: attachment.filename,
              data: blobUrl
            });
          }
        }
      });
    });
  }

  hideAnswer(answer: TicketAnswerDto) {
    answer.hidden = true;
    this.ticketApi.updateTicketAnswerDirect(answer._id!, answer).subscribe({
      next: (data) => {
        this.loadData();
      },
      error: (error) => {
        showError(error);
      }
    });
  }

  async showHistory(answer: TicketAnswerDto) {

    let text = ``;

    await asyncForEach(answer.history, async (history: TicketAnswerHistoryDto) => {
      text += `Geändert am: ${new Date(history.date).toLocaleString()}<br><br><pre>${history.text}</pre><br><br>`;
    });

    Swal.fire({
      title: 'Historie',
      grow: 'fullscreen',
      html: text,
      animation: false,
      confirmButtonText: 'Schließen',
    })
  }

  showInvalidAttachments(attachments: string[]) {
    Swal.fire({
      html: `Die nachfolgenden Anhänge wurden nicht automatisch importiert:<br><br><code>${attachments.join('\n')}</code><br><br>Die Anhänge sind in der Roh-Mail vorhanden.`,
    })
  }

  deleteAnswer(answer: TicketAnswerDto) {
    Swal.fire({
      text: 'Wirklich die Antwort löschen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ticketApi.deleteTicketAnswer(answer._id!).subscribe({
          next: () => {
            this.loadData();
          },
          error: (error) => {
            showError(error);
          }
        });
      }

    })
  }

  showAnswer(answer: TicketAnswerDto) {
    answer.hidden = false;
    this.ticketApi.updateTicketAnswerDirect(answer._id!, answer).subscribe({
      next: () => {
        this.loadData();
      },
      error: (error) => {
        showError(error);
      }
    });
  }

  quickEditRecipient(recipient: MailDataAddress) {
    // Swal dialog to edit name and address
    Swal.fire({
      title: 'Empfänger bearbeiten',
      html: `
        <div class="mb-2">
        <label>Name:</label>
        <input id="swal-input1" class="swal2-input ms-0 me-0" style="width: 100%;" value="${recipient.name}">
        </div>
        <div>
        <label>E-Mail Adresse:</label>
        <input id="swal-input2" class="swal2-input ms-0 me-0" style="width: 100%;" value="${recipient.address}">
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Speichern',
      cancelButtonText: 'Abbrechen',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return [
          (document.getElementById('swal-input1') as HTMLInputElement).value,
          (document.getElementById('swal-input2') as HTMLInputElement).value
        ]
      }
    }).then((result) => {
      if (result.isConfirmed) {
        recipient.name = result.value[0];
        recipient.address = result.value[1];
        this.checkRecipients();
      }
    })
  }

  getPreloadedAudioAttachment(answerId: string): PreloadedAudioAttachmentDto[] {
    return this.preloadedAudioAttachments.filter(el => el.ticketAnswerId === answerId);
  }

  filterAttachments(attachments: TicketAnswerAttachmentDto[],) {
    return attachments.filter(el => el.mimetype !== 'audio/webm' && !el.filename.startsWith('Sprachmitteilung_'));
  }
}

