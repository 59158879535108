import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, throwError } from "rxjs";
import { GroupDto } from "../dtos/group.dto";
import { PredefinedAnswerDto } from "../dtos/predefined-answer.dto";
import { SignatureDto } from "../dtos/signature.dto";
import { TicketAnalyticsDto } from "../dtos/ticket-analytics.dto";
import { TicketAnswerAttachmentDto } from "../dtos/ticket-answer-attachment.dto";
import { TicketAnswerDto } from "../dtos/ticket-answer.dto";
import { TicketAssignDto } from "../dtos/ticket-assign.dto";
import { TicketPriorityDto } from "../dtos/ticket-priority.dto";
import { TicketTemplateDto } from "../dtos/ticket-template.dto";
import { TicketUpdateDto } from "../dtos/ticket-update.dto";
import { TicketDto } from "../dtos/ticket.dto";
import { UserDto } from "../dtos/user.dto";
import { Page, queryPaginated } from "../models/page.model";
import { TicketBaseService } from "./ticket-base.service";
import { IncidentDto } from "../dtos/incident.dto.ts";
import { AddUserGroupDto } from "../dtos/add-user-group.dto";
import { RemoveUserGroupDto } from "../dtos/remove-user-group.dto";
import { UserLookupDto } from "../dtos/user-lookup.dto";
import { DashboardChartDto } from "../dtos/dashboard-chart.dto";
import { PagedTicketDto } from "../dtos/paged-ticket.dto";
import { LabelDto } from "../dtos/label.dto";
import { TicketNextDto } from "../dtos/ticket-next.dto";
import { ActivityDto } from "../dtos/activity.dto";
import { TicketOverviewDto } from "../dtos/ticket-overview.dto";
import { PriorityDto } from "../dtos/priority.dto";
import { IncidentHistoryDto } from "../dtos/incident-history.dto";
import { PagedIncidentDto } from "../dtos/paged-incident.dto";
import { AudioTranscriptionDto } from "../dtos/audio-transcription.dto";
import { SearchTicketDto } from "../dtos/search-ticket.dto";
import { NotificationDto } from "../dtos/notification.dto";
import { queryPaginatedPost } from "../models/page-post.model";

@Injectable({ providedIn: 'root' })
export class TicketApiService extends TicketBaseService {

    // ****** User based API calls ******

    check(token = ""): Observable<UserDto> {
        return this.http
            .get<UserDto>(
                this.apiUrl(`user/check`),
                (token !== "") ? {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                      }),
                } : this.httpOptions,
            );
    }

    getMyTickets(urlOrFilter?: object): Observable<Page<TicketDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginatedPost<TicketDto>(
            this.http,
            this.apiUrl('ticket/my'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<TicketDto>(uOb);
        return page;
    }

    getMyTicketsAllTypes(urlOrFilter: string | object): Observable<Page<TicketDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<TicketDto>(
            this.http,
            this.apiUrl(`ticket`),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<TicketDto>(uOb);
        return page;
    }

    searchTickets(urlOrFilter: string | object): Observable<Page<SearchTicketDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<SearchTicketDto>(
            this.http,
            this.apiUrl(`ticket/search`),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<SearchTicketDto>(uOb);
        return page;
    }

    getActivities(urlOrFilter?: string | object): Observable<Page<ActivityDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<ActivityDto>(
            this.http,
            this.apiUrl('activity'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<ActivityDto>(uOb);
        return page;
    }

    getNotifications(urlOrFilter?: string | object): Observable<Page<NotificationDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<NotificationDto>(
            this.http,
            this.apiUrl('notification/my'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<NotificationDto>(uOb);
        return page;
    }

    markNotificationAsRead(notificationId: string): Observable<NotificationDto> {
        return this.http
            .patch<NotificationDto>(
                this.apiUrl(`notification/read/${notificationId}`),
                {},
                this.httpOptions,
            );
    }

    getMyTicketsUnpagedAllTypes(search: string): Observable<PagedTicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<PagedTicketDto>(
                this.apiUrl(`ticket?search=${encodeURIComponent(search)}&sort=ticket_number&sortOrder=desc&type=none`),
                this.httpOptions,
            );
    }

    getMyTicketsUnpaged(search: string): Observable<PagedTicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<PagedTicketDto>(
                this.apiUrl(`ticket?search=${encodeURIComponent(search)}&sort=ticket_number&sortOrder=desc`),
                this.httpOptions,
            );
    }

    getIncidentsUnpaged(search: string): Observable<PagedIncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<PagedIncidentDto>(
                this.apiUrl(`incident?search=${search}&sort=created_at&sortOrder=desc`),
                this.httpOptions,
            );
    }

    getMyTicket(ticket_number: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketDto>(
                this.apiUrl(`ticket/my/${ticket_number}`),
                this.httpOptions,
            );
    }

    getPublicIncidents(): Observable<IncidentDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<IncidentDto[]>(
                this.apiUrl(`incident/public`),
                this.httpOptions,
            );
    }
    
    getMyTicketAnswers(ticketId: string): Observable<TicketAnswerDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketAnswerDto[]>(
                this.apiUrl(`ticket/my/${ticketId}/answers`),
                this.httpOptions,
            );
    }

    updateTicketAnswer(ticket_answer_id: string, answerDto: TicketAnswerDto): Observable<TicketAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketAnswerDto>(
                this.apiUrl(`ticket/action/update-answer/${ticket_answer_id}`),
                answerDto,
                this.httpOptions,
            );
    }

    deleteTicketAnswer(ticket_answer_id: string): Observable<TicketAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<TicketAnswerDto>(
                this.apiUrl(`answer/${ticket_answer_id}`),
                this.httpOptions,
            );
    }

    updateTicketAnswerDirect(ticket_answer_id: string, answerDto: TicketAnswerDto): Observable<TicketAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketAnswerDto>(
                this.apiUrl(`answer/${ticket_answer_id}`),
                answerDto,
                this.httpOptions,
            );
    }

    answerTicket(ticket_id: string, answerDto: TicketAnswerDto): Observable<TicketAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketAnswerDto>(
                this.apiUrl(`ticket/action/answer/${ticket_id}`),
                answerDto,
                this.httpOptions,
            );
    }

    submitTicket(ticket: TicketDto): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<TicketDto>(
                this.apiUrl(`ticket/submit`),
                ticket,
                this.httpOptions,
            );
    }

    updateStatus(ticket_id: string, updateDto: TicketUpdateDto): Observable<TicketUpdateDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketUpdateDto>(
                this.apiUrl(`ticket/action/status/${ticket_id}`),
                updateDto,
                this.httpOptions,
            );
    }

    attachLabel(ticket_id: string, labelId: string): Observable<any> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<any>(
                this.apiUrl(`label/${labelId}/attach/${ticket_id}`),
                {},
                this.httpOptions,
            );
    }

    unattachLabel(ticket_id: string, labelId: string): Observable<any> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<any>(
                this.apiUrl(`label/${labelId}/unattach/${ticket_id}`),
                {},
                this.httpOptions,
            );
    }

    addIncientToTicket(ticket_id: string, incidentId: string): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<IncidentDto>(
                this.apiUrl(`incident/${incidentId}/add-ticket/${ticket_id}`),
                null,
                this.httpOptions,
            );
    }

    mergeTicket(ticket_id: string, merge_id: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketDto>(
                this.apiUrl(`ticket/action/merge/${ticket_id}/${merge_id}`),
                null,
                this.httpOptions,
            );
    }

    unmergeTicket(ticket_id: string, merge_id: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketDto>(
                this.apiUrl(`ticket/action/unmerge/${ticket_id}/${merge_id}`),
                null,
                this.httpOptions,
            );
    }

    updatePriority(ticket_id: string, updateDto: TicketPriorityDto): Observable<TicketPriorityDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketPriorityDto>(
                this.apiUrl(`ticket/action/priority/${ticket_id}`),
                updateDto,
                this.httpOptions,
            );
    }

    updateTicketType(ticket_id: string, type: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
        .patch<TicketDto>(
            this.apiUrl(`ticket/action/updatetype/${ticket_id}/${type}`),
            {},
            this.httpOptions,
        );
    }

    assign(ticket_id: string, assignDto: TicketAssignDto): Observable<TicketAssignDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketAssignDto>(
                this.apiUrl(`ticket/action/assign/${ticket_id}`),
                assignDto,
                this.httpOptions,
            );
    }

    unassignSubGroup(ticket_id: string, groupId: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketDto>(
                this.apiUrl(`ticket/action/unassign-sub-group/${ticket_id}/${groupId}`),
                {},
                this.httpOptions,
            );
    }

    updateSubject(ticket_id: string, newSubject: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketDto>(
                this.apiUrl(`ticket/action/updatesubject/${ticket_id}`),
                {
                    subject: newSubject
                },
                this.httpOptions,
            );
    }

    uploadFiles(file: File): Observable<TicketAnswerAttachmentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        let formData: FormData = new FormData();
        formData.append('file', file);

        return this.http.post<TicketAnswerAttachmentDto>(
            this.apiUrl(`ticket/upload-attachment`),
            formData,
            this.httpOptions
        );
    }

    transcribeAudio(file: File, duration: number): Observable<AudioTranscriptionDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('duration', duration.toString());

        return this.http.post<AudioTranscriptionDto>(
            this.apiUrl(`ticket/transcribe-audio`),
            formData,
            this.httpOptions
        );
    }

    downloadFile(ticketAnswerId: string, fileSlug: string): Observable<any> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get(
                this.apiUrl(`ticket/download-attachment/${ticketAnswerId}/${fileSlug}`),
                {
                    headers: new HttpHeaders({
                      Authorization: 'Bearer ' + this.token(),
                    }),
                    responseType: 'arraybuffer',
                  },
            );
    }

    exportActivities(search: string, filter: string, filterValue: string): Observable<any> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get(
                this.apiUrl(`activity/export?search=${search}&filter=${filter}&filterValue=${filterValue}`),
                {
                    headers: new HttpHeaders({
                      Authorization: 'Bearer ' + this.token(),
                    }),
                    responseType: 'arraybuffer',
                  },
            );
    }

    downloadMail(ticketAnswerId: string): Observable<any> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get(
                this.apiUrl(`ticket/download-mail/${ticketAnswerId}`),
                {
                    headers: new HttpHeaders({
                      Authorization: 'Bearer ' + this.token(),
                    }),
                    responseType: 'arraybuffer',
                  },
            );
    }

    getMySignature(): Observable<SignatureDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<SignatureDto>(
                this.apiUrl(`user/my/signature`),
                this.httpOptions,
            );
    }

    updateMySignature(signatureDto: SignatureDto): Observable<SignatureDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<SignatureDto>(
                this.apiUrl(`user/my/signature`),
                signatureDto,
                this.httpOptions,
            );
    }

    getTicketTemplatesByAppId(app_id: string): Observable<TicketTemplateDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketTemplateDto[]>(
                this.apiUrl(`template/byapp/${app_id}`),
                this.httpOptions,
            );
    }

    getTicketTemplatesByCategory(category: string): Observable<TicketTemplateDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketTemplateDto[]>(
                this.apiUrl(`template/bycategory/${category}`),
                this.httpOptions,
            );
    }

    // ****** Admin based API calls ******    

    getAllTickets(urlOrFilter?: object): Observable<Page<TicketDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginatedPost<TicketDto>(
            this.http,
            this.apiUrl('ticket/get-tickets'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<TicketDto>(uOb);
        return page;
    }

    getAllIncidents(urlOrFilter?: string | object): Observable<Page<IncidentDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<IncidentDto>(
            this.http,
            this.apiUrl('incident'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<IncidentDto>(uOb);
        return page;
    }

    getAllTicketTemplates(urlOrFilter?: string | object): Observable<Page<TicketTemplateDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<TicketTemplateDto>(
            this.http,
            this.apiUrl('template'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<TicketTemplateDto>(uOb);
        return page;
    }

    getAllGroups(urlOrFilter?: string | object): Observable<Page<GroupDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<GroupDto>(
            this.http,
            this.apiUrl('group'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<GroupDto>(uOb);
        return page;
    }

    getAllLabels(urlOrFilter?: string | object): Observable<Page<LabelDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<LabelDto>(
            this.http,
            this.apiUrl('label'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<LabelDto>(uOb);
        return page;
    }

    getAllPriorities(urlOrFilter?: string | object): Observable<Page<PriorityDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<PriorityDto>(
            this.http,
            this.apiUrl('priority'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<PriorityDto>(uOb);
        return page;
    }

    getAllUsers(urlOrFilter?: string | object): Observable<Page<UserDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginated<UserDto>(
            this.http,
            this.apiUrl('user'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<UserDto>(uOb);
        return page;
    }

    getMyAdminTickets(urlOrFilter?: object): Observable<Page<TicketDto>> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        const uOb = queryPaginatedPost<TicketDto>(
            this.http,
            this.apiUrl('ticket/get-my-admin-tickets'),
            urlOrFilter,
            this.httpOptions,
        );
        const page = this.toPage<TicketDto>(uOb);
        return page;
    }

    getGroups(): Observable<GroupDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<GroupDto[]>(
                this.apiUrl(`group/all/unpaged`),
                this.httpOptions,
            );
    }

    getLabels(): Observable<LabelDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<LabelDto[]>(
                this.apiUrl(`label/all/unpaged`),
                this.httpOptions,
            );
    }

    searchUsers(search: string): Observable<UserLookupDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<UserLookupDto[]>(
                this.apiUrl(`user/search/vermittler/${search}`),
                this.httpOptions,
            );
    }

    getPredefinedAnswers(): Observable<PredefinedAnswerDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<PredefinedAnswerDto[]>(
                this.apiUrl(`predefined-answer/my`),
                this.httpOptions,
            );
    }

    getPredefinedAnswer(id: string): Observable<PredefinedAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<PredefinedAnswerDto>(
                this.apiUrl(`predefined-answer/${id}`),
                this.httpOptions,
            );
    }

    getTicketTemplate(id: string): Observable<TicketTemplateDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketTemplateDto>(
                this.apiUrl(`template/${id}`),
                this.httpOptions,
            );
    }

    getIncident(id: string): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<IncidentDto>(
                this.apiUrl(`incident/${id}`),
                this.httpOptions,
            );
    }

    getGroup(id: string): Observable<GroupDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<GroupDto>(
                this.apiUrl(`group/${id}`),
                this.httpOptions,
            );
    }

    getLabel(id: string): Observable<LabelDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<LabelDto>(
                this.apiUrl(`label/${id}`),
                this.httpOptions,
            );
    }

    getPriority(id: string): Observable<PriorityDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<PriorityDto>(
                this.apiUrl(`priority/${id}`),
                this.httpOptions,
            );
    }

    updatePredefinedAnswer(id: string, update: PredefinedAnswerDto): Observable<PredefinedAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<PredefinedAnswerDto>(
                this.apiUrl(`predefined-answer/${id}`),
                update,
                this.httpOptions,
            );
    }

    updateTicketTemplate(id: string, update: TicketTemplateDto): Observable<TicketTemplateDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<TicketTemplateDto>(
                this.apiUrl(`template/${id}`),
                update,
                this.httpOptions,
            );
    }

    addIncidentUpdate(id: string, update: IncidentHistoryDto): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<IncidentDto>(
                this.apiUrl(`incident/${id}/update`),
                update,
                this.httpOptions,
            );
    }

    updateIncident(id: string, update: IncidentDto): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<IncidentDto>(
                this.apiUrl(`incident/${id}`),
                update,
                this.httpOptions,
            );
    }

    updateGroup(id: string, update: GroupDto): Observable<GroupDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<GroupDto>(
                this.apiUrl(`group/${id}`),
                update,
                this.httpOptions,
            );
    }

    updateLabel(id: string, update: LabelDto): Observable<LabelDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<LabelDto>(
                this.apiUrl(`label/${id}`),
                update,
                this.httpOptions,
            );
    }

    updatePriorityEntry(id: string, update: PriorityDto): Observable<PriorityDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<PriorityDto>(
                this.apiUrl(`priority/${id}`),
                update,
                this.httpOptions,
            );
    }

    assignUserToGroup(addUserGroupDto: AddUserGroupDto): Observable<AddUserGroupDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<AddUserGroupDto>(
                this.apiUrl(`user/assign-group`),
                addUserGroupDto,
                this.httpOptions,
            );
    }

    removeUserFromGroup(removeUserGroupDto: RemoveUserGroupDto): Observable<RemoveUserGroupDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .patch<RemoveUserGroupDto>(
                this.apiUrl(`user/unassign-group`),
                removeUserGroupDto,
                this.httpOptions,
            );
    }

    addPredefinedAnswer(add: PredefinedAnswerDto): Observable<PredefinedAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<PredefinedAnswerDto>(
                this.apiUrl(`predefined-answer`),
                add,
                this.httpOptions,
            );
    }

    addTicketTemplate(add: TicketTemplateDto): Observable<TicketTemplateDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<TicketTemplateDto>(
                this.apiUrl(`template`),
                add,
                this.httpOptions,
            );
    }

    addIncident(add: IncidentDto): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<IncidentDto>(
                this.apiUrl(`incident`),
                add,
                this.httpOptions,
            );
    }

    addGroup(add: GroupDto): Observable<GroupDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<GroupDto>(
                this.apiUrl(`group`),
                add,
                this.httpOptions,
            );
    }

    addLabel(add: LabelDto): Observable<LabelDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<LabelDto>(
                this.apiUrl(`label`),
                add,
                this.httpOptions,
            );
    }

    addPriority(add: PriorityDto): Observable<PriorityDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .post<PriorityDto>(
                this.apiUrl(`priority`),
                add,
                this.httpOptions,
            );
    }

    deletePredefinedAnswer(id: string): Observable<PredefinedAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<PredefinedAnswerDto>(
                this.apiUrl(`predefined-answer/${id}`),
                this.httpOptions,
            );
    }

    deleteGroup(id: string): Observable<GroupDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<GroupDto>(
                this.apiUrl(`group/${id}`),
                this.httpOptions,
            );
    }

    deleteLabel(id: string): Observable<LabelDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<LabelDto>(
                this.apiUrl(`label/${id}`),
                this.httpOptions,
            );
    }

    deleteIncident(id: string): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<IncidentDto>(
                this.apiUrl(`incident/${id}`),
                this.httpOptions,
            );
    }

    deleteIncidentHistory(id: string): Observable<IncidentDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<IncidentDto>(
                this.apiUrl(`incident/${id}/history`),
                this.httpOptions,
            );
    }

    deleteTemplates(id: string): Observable<TicketTemplateDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .delete<TicketTemplateDto>(
                this.apiUrl(`template/${id}`),
                this.httpOptions,
            );
    }

    getSupportUsers(): Observable<UserDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<UserDto[]>(
                this.apiUrl(`user/all/support`),
                this.httpOptions,
            );
    }

    getSupportUserNames(): Observable<string[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<string[]>(
                this.apiUrl(`user/all/support/names`),
                this.httpOptions,
            );
    }

    getTicketOverview(): Observable<TicketOverviewDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketOverviewDto>(
                this.apiUrl(`ticket/analytics/overview`),
                this.httpOptions,
            );
    }

    getAnalytics(timespan: string): Observable<TicketAnalyticsDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketAnalyticsDto>(
                this.apiUrl(`ticket/analytics/dashboard/${timespan}`),
                this.httpOptions,
            );
    }

    getNewTicketsChart(timespan: string): Observable<DashboardChartDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<DashboardChartDto>(
                this.apiUrl(`ticket/analytics/dashboard/chart/newtickets/${timespan}`),
                this.httpOptions,
            );
    }

    getTicket(ticket_number: string): Observable<TicketDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketDto>(
                this.apiUrl(`ticket/${ticket_number}`),
                this.httpOptions,
            );
    }

    getNextTicket(ticket_id: string): Observable<TicketNextDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketNextDto>(
                this.apiUrl(`ticket/${ticket_id}/next-tickets`),
                this.httpOptions,
            );
    }

    getMergableTickets(ticket_id: string): Observable<TicketDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketDto[]>(
                this.apiUrl(`ticket/${ticket_id}/mergable-tickets`),
                this.httpOptions,
            );
    }
    
    getTicketAnswers(ticketId: string): Observable<TicketAnswerDto[]> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketAnswerDto[]>(
                this.apiUrl(`ticket/${ticketId}/answers`),
                this.httpOptions,
            );
    }

    getTicketAnswer(ticketAnswerId: string): Observable<TicketAnswerDto> {
        if (!this.checkTokenExpiry()) {
            return throwError(() => new Error(`Token expired`))
        }

        return this.http
            .get<TicketAnswerDto>(
                this.apiUrl(`answer/${ticketAnswerId}`),
                this.httpOptions,
            );
    }

    // ****** Helper ******

    toPage<T>(observable: any): Observable<Page<T>> {
        return observable.pipe(
            map((pageData: any) => {
                const page = new Page();
                if (pageData == null) {
                    return throwError(() => new Error('Page data is null'));
                }

                page.total = pageData.totalCount ? pageData.totalCount : 0;
                page.currentPage = pageData.page ? parseInt(pageData.page) : 1;
                page.pageSize = pageData.size ? pageData.size : 25;
                page.data = pageData.data ? pageData.data as T[] : [];
                return page;
            },
            ),
        );
    }
}