import { Injectable } from "@angular/core";
import { Observable, map, throwError } from "rxjs";
import { AppDto } from "../dtos/app.dto";
import { FaqDto } from "../dtos/faq.dto";
import { GroupDto } from "../dtos/group.dto";
import { SignatureDto } from "../dtos/signature.dto";
import { TicketAnalyticsDto } from "../dtos/ticket-analytics.dto";
import { TicketAnswerAttachmentDto } from "../dtos/ticket-answer-attachment.dto";
import { TicketAnswerDto } from "../dtos/ticket-answer.dto";
import { TicketDto } from "../dtos/ticket.dto";
import { Page, queryPaginated } from "../models/page.model";
import { LandingpageBaseService } from "./landingpage-base.service";
import { TicketBaseService } from "./ticket-base.service";
import { ApiBaseService } from "./api-base.service";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class ApiApiService extends ApiBaseService {

    getReport(messageId: string): Observable<any> {
        return this.http
          .get(
            this.apiUrl(`nsp/report/${messageId}`),
            {
              headers: new HttpHeaders({
                'Access-Control-Allow-Origin': environment.usermgmtEndpoint,
                Authorization: 'Bearer ' + this.token(),
              }),
              responseType: 'arraybuffer',
            },
          );
      }
}