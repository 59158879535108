<div class="main mt-8" *ngIf="loading">
    <div class="wrapper">
        <div class="w-100 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="container mt-5 text-center">
            <h1>Bitte warten ...</h1>
        </div>
    </div>
</div>

<div *ngIf="!loading">
    <div class="container-fluid pt-2 pb-2 ps-2 pe-2" *ngIf="showPdf">
        <ngx-extended-pdf-viewer [src]="this.pdfBlob" [filenameForDownload]="this.pdfFilename"
            [showUnverifiedSignatures]="true" [useBrowserLocale]="true" [showBorders]="true" [scrollMode]="scrollmode"
            [zoom]="'100%'">
        </ngx-extended-pdf-viewer>
    </div>


    <div *ngIf="(this.showImage || this.showVideo) && attachment">
        <div class="container mt-4">
            <ul class="list-inline list-inline-dots">
                <li class="list-inline-item">
                    📁 {{ attachment.filename }}
                </li>
                <li class="list-inline-item">
                    <strong>Typ:</strong> {{ attachment.mimetype }}
                </li>
                <li class="list-inline-item">
                    <strong>Dateigröße</strong>: {{ attachment.size | filesize }}
                </li>
            </ul>

            <img style="max-height: 98vh;" [src]="this.blobUrl" *ngIf="this.showImage" alt="">
        </div>

        <div class="container-fluid">
            <div *ngIf="this.showVideo">
                <video style="max-height: 90vh; display: block; margin: 0 auto;" controls>
                    <source [src]="this.blobUrl" type="video/mp4">
                </video>
            </div>

            <div class="text-center" *ngIf="this.showImage || this.showVideo">
                <button class="btn btn-efs mt-3" (click)="saveAttachment()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-download">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                        <path d="M7 11l5 5l5 -5" />
                        <path d="M12 4l0 12" />
                    </svg>
                    Anhang herunterladen
                </button>
            </div>
        </div>
    </div>
</div>