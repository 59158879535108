import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import Swal from 'sweetalert2';
import { TicketApiService } from '../../services/ticket-api.service';
import { showError } from '../../helper/utils.helper';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { AddUserGroupDto } from '../../dtos/add-user-group.dto';
import { PreloaderService } from '../../services/preloader.service';


@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {

  groupId: string | undefined;
  mode = 'new';
  isLoading = false;

  group: GroupDto = new GroupDto();
  users: UserDto[] = [];

  addUserToGroup: AddUserGroupDto = new AddUserGroupDto();

  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    protected route: ActivatedRoute,
    protected router: Router,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        if (params['id'] === 'new') {
          this.mode = 'new';
        } else {
          this.mode = 'edit';
          this.groupId = params['id'];
          this.addUserToGroup.group_id = this.groupId!;
          
          this.preloaderService.dataLoaded.subscribe({
            next: () => {
              this.loadData();
            },
            error: (err) => {
              this.isLoading = false;
              showError(err);
            },
          });
        }
      }
    })
  }

  loadData() {
    this.isLoading = true;
    this.ticketApi.getGroup(this.groupId!).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.group = data;
        this.group.ingest_mailboxes_string = this.group.ingest_mailboxes.join(',');
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });

    this.ticketApi.getSupportUsers().subscribe({
      next: (data) => {
        this.isLoading = false;
        this.users = data;
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      },
    });
  }

  save() {
    if (this.checkInput()) {
      this.isLoading = true;
      this.ticketApi.updateGroup(this.groupId!, {
        name: this.group.name,
        ingest_mailboxes: this.group.ingest_mailboxes_string!.trim().split(','),
      }).subscribe({
        next: (data) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Gruppe gespeichert',
          }).then(() => {
            this.loadData();
          })
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        }
      });
    }
  }

  create() {
    if (this.checkInput()) {
      this.isLoading = true;
      this.ticketApi.addGroup({
        name: this.group.name,
        ingest_mailboxes: this.group.ingest_mailboxes_string!.trim().split(','),
      }).subscribe({
        next: (data) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Gruppe erstellt',
          }).then(() => {
            this.router.navigate(['/admin/groups'])
          })
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        }
      });
    }
  }

  removeUserFromGroup(user_sso_id: string, group_id: string) {
    Swal.fire({
      title: 'Benutzer entfernen?',
      text: 'Möchten Sie den Benutzer wirklich aus der Gruppe entfernen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, entfernen',
      cancelButtonText: 'Abbrechen'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        this.ticketApi.removeUserFromGroup({
          user_sso_id, group_id
        }).subscribe({
          next: () => {
            this.isLoading = false;
            this.loadData();
          },
          error: (error) => {
            this.isLoading = false;
            showError(error);
          },
        });
      }
    });
  }

  addUserToGroupSubmit() {
    if (this.checkInputAssignment()) {
      this.isLoading = true;
      this.ticketApi.assignUserToGroup(this.addUserToGroup).subscribe({
        next: () => {
          this.isLoading = false;
          this.loadData();
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        },
      });
    }
  }

  checkInput() {
    if (this.group.name === '' || this.group.name === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Namen ein',
      })

      return false;
    }

    return true;
  }

  checkInputAssignment() {
    if (this.addUserToGroup.user_sso_id === undefined || this.addUserToGroup.user_sso_id === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte wählen Sie eine Benutzer aus',
      })

      return false;
    }

    if (this.addUserToGroup.user_sso_id === undefined || this.addUserToGroup.permissions.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte wählen Sie mindestens eine Berechtigung aus',
      })

      return false;
    }

    return true;
  }

  getUsersInGroup(group: GroupDto) {
    return this.users.filter(user => user.groups.find(g => g.group_id === group._id));
  }

  getUserPermissions(user: UserDto) {
    return user.groups.find(g => g.group_id === this.group._id)?.permissions;
  }

  translatePermissions(permissions: string[]) {
    return permissions.map(permission => {
      switch (permission) {
        case 'read':
          return 'Lesen';
        case 'write':
          return 'Schreiben';
        case 'supervise':
          return 'Verwalten';
        default:
          return permission;
      }
    }).join(', ');
  }
}
