<div class="page-body">
    <div class="container-fluid">
        <div class="row row-cards">
            <div [className]="mode === 'new' ? 'col-lg-12' : 'col-lg-6'">
                <div class="card mb-3">
                    <div class="card-header">
                        <h3 class="card-title" *ngIf="mode === 'new'">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="24"
                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 5l0 14"></path>
                                <path d="M5 12l14 0"></path>
                            </svg>
                            Störung erstellen
                        </h3>
                        <h3 class="card-title" *ngIf="mode === 'edit'">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24"
                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                            Störung bearbeiten
                            <p class="text-muted small mb-0">
                                Durch das Hinzufügen eines Updates wird der Status der Störung automatisch aktualisiert.
                            </p>
                        </h3>
                    </div>

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-6 mb-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Der Status der Störung">Status</span></label>

                                    <select class="form-control" [(ngModel)]="incident.state">
                                        <option [value]="'NEW'">Störung aktiv</option>
                                        <option [value]="'UNDER_INVESTIGATION'">Störung in Prüfung</option>
                                        <option [value]="'IN_PROGRESS'">Störung in Bearbeitung</option>
                                        <option [value]="'RESOLVED'">Störung behoben</option>
                                        <option [value]="'CLOSED'">Störung geschlossen</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Der Schweregrad der Störung">Schweregrad</span></label>

                                    <select class="form-control" [(ngModel)]="incident.severity">
                                        <option [value]="'PARTIAL_OUTAGE'">Teilausfall</option>
                                        <option [value]="'OUTAGE'">Ausfall</option>
                                        <option [value]="'DEGRADED_PERFORMANCE'">System verlangsamt</option>
                                        <option [value]="'UNDER_MAINTENANCE'">Wartung</option>
                                        <option [value]="'OPERATIONAL'">Alle Systeme normal</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-12 mb-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Die Kurzbeschreibung wird als Titel der Störung angezeigt">Kurzbeschreibung</span></label>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="incident.description">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Der Text sollte so kurz wie nötig, aber so genau wie möglich die Störung beschreiben">Text</span></label>

                                    <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="incident.text">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Das Datum ab wann die Störung gilt">Start</span></label>

                                    <div class="input-group input-group-flat">
                                        <input type="text" class="form-control" required name="" id="start"
                                            [(ngModel)]="incident.startReadable">
                                        <span class="input-group-text" *ngIf="incident.startReadable">
                                            <a (click)="deleteStartDate()" class="input-group-link">Datum löschen</a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Das Datum bis die Störung gilt">Ende</span></label>

                                    <div class="input-group input-group-flat">
                                        <input type="text" class="form-control" required name="" id="end"
                                            [(ngModel)]="incident.endReadable">
                                        <span class="input-group-text" *ngIf="incident.endReadable">
                                            <a (click)="deleteEndDate()" class="input-group-link">Datum löschen</a>
                                        </span>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Gibt an ob die Störung nach Ablauf des End-Datums automatisch gelöscht werden soll">Nach
                                            Ablauf löschen</span></label>
                                    <select class="form-control" [(ngModel)]="incident.deleteAfterExpiry">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Gibt an ob die Störung nach Ablauf automatisch gelöst werden soll. Es wird eine automatisches Update erzeugt.">Nach
                                            Ablauf lösen</span></label>
                                    <select class="form-control" [(ngModel)]="incident.autoResolveAfterEndDate">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="row">

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Gibt an ob die Störung für Vertriebspartner im EFSsupport System sichtbar sind">Öffentlich</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="incident.public">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Gibt an ob die Störung auf der Startseite angezeigt werden soll">Auf
                                            Startseite anzeigen</span></label>
                                    <select class="form-control" required name="" id=""
                                        [(ngModel)]="incident.publishOnLandingpage">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Gibt an in weler Sprache die Störung geschrieben ist">Sprache</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="incident.language">
                                        <option [ngValue]="'de'">Deutsch</option>
                                        <option [ngValue]="'hu'">Ungarisch</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-3 mb-3">
                                <label class="form-label"><span popperPlacement="left"
                                        popper="Gibt an ob das Update den angefügten Anliegen als Antwort gesendet werden soll">Anliegen informieren</span></label>
                                <select class="form-control" [(ngModel)]="newUpdate.announceToTickets">
                                    <option [ngValue]="true">Ja</option>
                                    <option [ngValue]="false">Nein</option>
                                </select>
                            </div>

                            <div class="col-md-3 mb-3">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right"
                                            popper="Gibt an welche Apps von der Störung betroffen sind. Im Zusammenhang mit 'Öffentlich' wird die Störung beim Erstellen eines neuen Anliegen angezeigt">Betroffene
                                            App</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="incident.apps"
                                        multiple>
                                        <option value=""></option>
                                        <option *ngFor="let app of apps" [value]="app._id">{{ app.name }} [{{
                                            app.countries.join(', ').toUpperCase() }}]</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer">
                        <button class="btn btn-efs me-2" *ngIf="mode === 'edit'" (click)="save()">Speichern</button>
                        <span class="me-2" *ngIf="mode === 'edit'"> - Oder - </span>
                        <button class="btn btn-efs" *ngIf="mode === 'edit'" (click)="addUpdate()">Update hinzufügen</button>
                        <button class="btn btn-efs" *ngIf="mode === 'new'" (click)="create()">Anlegen</button>
                    </div>
                </div>

                <div class="card" *ngIf="incident && incident.attachedTickets && incident.attachedTickets.length > 0">
                    <div class="card-header">
                        <h3 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link" width="24"
                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 15l6 -6"></path>
                                <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
                                <path
                                    d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463">
                                </path>
                            </svg>
                            Verknüpfte Anliegen
                        </h3>
                    </div>


                    <div class="list-group list-group-flush">
                        <div class="list-group-item" *ngFor="let item of incident.attachedTickets">
                            <div class="row align-items-center">
                                <div class="col text-truncate">
                                    <a href="#" class="text-reset d-block">#{{ item }}</a>
                                    <div class="d-block text-secondary text-truncate mt-n1"></div>
                                </div>
                                <div class="col-auto">
                                    <a href="#" class="list-group-item-actions"
                                        [ngbTooltip]="'Verknüpfung trennen'">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-unlink" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M17 22v-2"></path>
                                            <path d="M9 15l6 -6"></path>
                                            <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
                                            <path
                                                d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463">
                                            </path>
                                            <path d="M20 17h2"></path>
                                            <path d="M2 7h2"></path>
                                            <path d="M7 2v2"></path>
                                        </svg>
                                    </a>
                                </div>

                                <div class="col-auto">
                                    <a href="#" class="list-group-item-actions"
                                        [ngbTooltip]="'Zu Anliegen gehen'">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-external-link" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                            </path>
                                            <path d="M11 13l9 -9"></path>
                                            <path d="M15 4h5v5"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-6" *ngIf="mode === 'edit'">
                <div class="card" *ngIf="incident && incident.history">
                    <div class="card-header">
                        <h3 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969"></path>
                                <path d="M14 3.223a9.003 9.003 0 0 1 0 17.554"></path>
                                <path d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592"></path>
                                <path d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305"></path>
                                <path d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356"></path>
                                <path d="M12 8v4l3 3"></path>
                            </svg>
                            Störungsverlauf
                        </h3>
                    </div>

                    <div class="card-body">

                        <div class="alert" *ngIf="incident.history.length === 0">
                            Keine Einträge vorhanden!
                        </div>

                        <ul class="timeline" *ngFor="let entry of incident.history">
                            <li class="timeline-event">
                                <div class="timeline-event-icon">

                                    <svg *ngIf="entry.state === 'NEW'" xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-alert-hexagon" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z">
                                        </path>
                                        <path d="M12 8v4"></path>
                                        <path d="M12 16h.01"></path>
                                    </svg>

                                    <svg *ngIf="entry.state === 'UNDER_INVESTIGATION'"
                                        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search"
                                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                        <path d="M21 21l-6 -6"></path>
                                    </svg>

                                    <svg *ngIf="entry.state === 'IN_PROGRESS'" xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-repeat" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3"></path>
                                        <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3"></path>
                                    </svg>

                                    <svg *ngIf="entry.state === 'RESOLVED'" xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-checks" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M7 12l5 5l10 -10"></path>
                                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                                    </svg>

                                    <svg *ngIf="entry.state === 'CLOSED'" xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-lock-square-rounded" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
                                        <path
                                            d="M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z">
                                        </path>
                                        <path d="M10 11v-2a2 2 0 1 1 4 0v2"></path>
                                    </svg>

                                </div>
                                <div class="card timeline-event-card">
                                    <div class="card-body">
                                        <div class="text-secondary float-end">
                                            <small>{{ entry.created_at | date: 'dd.MM.yyyy HH:mm' }}</small>

                                            <a title="Eintrag löschen" class="link ms-2" (click)="deleteHistoryEntry(entry)"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-x me-0" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M4 7h16"></path>
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                <path d="M10 12l4 4m0 -4l-4 4"></path>
                                             </svg></a>

                                        </div>
                                        <h4><span class="badge badge-outline bg-efs me-1">{{ renderState(entry.state) }}</span> <span class="badge badge-outline bg-efs">{{ renderSeverity(entry.severity) }}</span></h4>

                                        <strong>{{ entry.description }}</strong>
                                        <p class="mt-2" [innerHTML]="entry.text"></p>

                                        <p class="text-muted">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-user" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                            </svg>
                                            {{ entry.author_name ? entry.author_name : 'Unbekannter Autor' }}
                                        </p>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-md-4"><strong>Öffentlich: </strong> <span>{{ entry.public ?
                                                    'Ja' : 'Nein'
                                                    }}</span></div>
                                            <div class="col-md-4"><strong>Auf Startseite: </strong> <span>{{
                                                    entry.publishOnLandingpage ? 'Ja'
                                                    : 'Nein' }}</span></div>
                                            <div class="col-md-4"><strong>In Anliegen: </strong> <span>{{
                                                    entry.announceToTickets ? 'Ja' :
                                                    'Nein' }}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>