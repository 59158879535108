import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';
import Swal from 'sweetalert2';
import { AppDto } from '../../dtos/app.dto';
import { PredefinedAnswerDto } from '../../dtos/predefined-answer.dto';
import { showError } from '../../helper/utils.helper';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { TicketApiService } from '../../services/ticket-api.service';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'app-predefined-answers',
  templateUrl: './predefined-answers.component.html',
  styleUrls: ['./predefined-answers.component.scss']
})
export class PredefinedAnswersComponent implements OnInit {

  myPredefinedAnswers: PredefinedAnswerDto[] = [];
  sharedPredefinedAnswers: PredefinedAnswerDto[] = [];
  publicPredefinedAnswers: PredefinedAnswerDto[] = [];

  userData: any;

  isLoading = false;

  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.preloaderService.dataLoaded.subscribe({
      next: () => {
        this.loadData();
      },
      error: (err) => {
        this.isLoading = false;
        showError(err);
      },
    });
  }

  loadData() {
    this.isLoading = true;
    this.ticketApi.getPredefinedAnswers().subscribe({
      next: (data) => {
        this.myPredefinedAnswers = data.filter(x => x.user_sso_id == this.userData.nickname);
        this.sharedPredefinedAnswers = data.filter(x => x.user_sso_id != this.userData.nickname && x.public && !x.official);
        this.publicPredefinedAnswers = data.filter(x => x.official == true);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });
  }

  showPredefinedAnswer(predefinedAnswer: PredefinedAnswerDto) {
    Swal.fire({
      title: predefinedAnswer.description,
      html: predefinedAnswer.text,
    })
  }

  deletePredefinedAnswer(predefinedAnswer: PredefinedAnswerDto) {

    Swal.fire({
      title: 'Sicher?',
      text: "Möchtest du diese Antwort wirklich löschen?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        this.ticketApi.deletePredefinedAnswer(predefinedAnswer._id!).subscribe({
          next: (data) => {
            this.loadData();
          },
          error: (error) => {
            this.isLoading = false;
            showError(error);
          }
        });
      }
    })

  }

}
