import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { MyTicketsComponent } from './pages/my-tickets/my-tickets.component';
import { NewTicketComponent } from './pages/new-ticket/new-ticket.component';
import { ViewTicketComponent } from './pages/view-ticket/view-ticket.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'tickets',
    component: MyTicketsComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'ticket/new',
    component: NewTicketComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'ticket/view/:id',
    component: ViewTicketComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'ticket/:id',
    component: ViewTicketComponent,
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: "admin", loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AutoLoginPartialRoutesGuard]
  },
  {
    path: '*',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
