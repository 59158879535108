import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import Swal from 'sweetalert2';
import { AppDto } from '../../dtos/app.dto';
import { PredefinedAnswerDto } from '../../dtos/predefined-answer.dto';
import { showError } from '../../helper/utils.helper';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { TicketApiService } from '../../services/ticket-api.service';
import { PreloaderService } from '../../services/preloader.service';
import CustomBuildEditor from 'src/ckeditor5/build/ckeditor';

@Component({
  selector: 'app-predefined-answer-edit',
  templateUrl: './predefined-answer-edit.component.html',
  styleUrls: ['./predefined-answer-edit.component.scss']
})
export class PredefinedAnswerEditComponent implements OnInit {

  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  predefinedAnswerId: string | undefined;
  mode = 'new';

  predefinedAnswer: PredefinedAnswerDto = new PredefinedAnswerDto();

  apps: AppDto[] = [];

  public editor = CustomBuildEditor;
  editorConfig: any = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'fontSize',
        'fontColor',
        '|',
        'outdent',
        'indent',
        'alignment',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'codeBlock',
        'code'
      ]
    },
    link: {
      decorators: {
        isExternal: {
          mode: 'automatic',
          callback: (url: any) => url.startsWith('http://') || url.startsWith('https://') || url.startsWith('www'),
          attributes: {
            target: '_blank'
          }
        }
      }
    },
    language: 'de',
  };

  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    protected route: ActivatedRoute,
    protected landingpageService: LandingpageApiService,
    protected router: Router,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        if (params['id'] === 'new') {
          this.mode = 'new';
        } else {
          this.mode = 'edit';
          this.predefinedAnswerId = params['id'];
          
          this.preloaderService.dataLoaded.subscribe({
            next: () => {
              this.loadData();
            },
            error: (err) => {
              showError(err);
            },
          });
        }
      }
    })

    this.landingpageService.getMyAppsAll().subscribe({
      next: (data) => {
        this.apps = data;
      },
      error: (error) => {
        showError(error);
      }
    })
  }

  loadData() {
    this.ticketApi.getPredefinedAnswer(this.predefinedAnswerId!).subscribe({
      next: (data) => {
        this.predefinedAnswer = data;
      },
      error: (error) => {
        showError(error);
      }
    });
  }

  save() {
    if (this.checkInput()) {
      this.ticketApi.updatePredefinedAnswer(this.predefinedAnswerId!, {
        app_id: this.predefinedAnswer.app_id,
        description: this.predefinedAnswer.description,
        text: this.predefinedAnswer.text,
        public: this.predefinedAnswer.public,
        official: this.predefinedAnswer.official,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Vordefinierte Antwort gespeichert',
          }).then(() => {
            this.loadData();
          })
        },
        error: (error) => {
          showError(error);
        }
      });
    }
  }

  create() {
    if (this.checkInput()) {
      this.ticketApi.addPredefinedAnswer({
        app_id: this.predefinedAnswer.app_id,
        description: this.predefinedAnswer.description,
        text: this.predefinedAnswer.text,
        public: this.predefinedAnswer.public,
        official: this.predefinedAnswer.official,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Vordefinierte Antwort erstellt',
          }).then(() => {
            this.router.navigate(['/admin/predefined-answers'])
          })
        },
        error: (error) => {
          showError(error);
        }
      });
    }
  }

  checkInput() {
    if (this.predefinedAnswer.description === '' || this.predefinedAnswer.description === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Beschreibung ein',
      })

      return false;
    }

    if (this.predefinedAnswer.text === '' || this.predefinedAnswer.text === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie ein Text ein',
      })

      return false;
    }

    return true;
  }

}
