import { MailDataDto } from "./mail-data.dto";
import { TicketAnswerAttachmentDto } from "./ticket-answer-attachment.dto";
import { TicketAnswerHistoryDto } from "./ticket-answer-history.dto";
import { TicketTemplateDataDto } from "./ticket-template-data.dto";
import { TicketTemplateFieldDto } from "./ticket-template-field.dto";

export class TicketAnswerDto extends Object {
    public _id?: string = "";
    public ticket_id?: string = "";
    public created_at?: Date = new Date();
    public type: string = "";

    public author_name: string = "";
    public author_email: string = "";
    public author_user_sso_id: string = "";

    public created_for_user?: boolean = false;
    public created_for_user_visible?: boolean = false;

    public created_for_user_cn?: string = "";
    public created_for_user_name?: string = "";
    public created_for_user_mail?: string = "";

    public source: string = "";

    public mail_data?: MailDataDto = new MailDataDto();

    public audit_answer_type?: string = "";
    public audit_visible_in_ticket?: boolean = false;
    public text: string = "";
    public attachments: TicketAnswerAttachmentDto[] = [];
    public unsupportedAttachments?: string[] = [];
    public template_data: TicketTemplateFieldDto[] = [];

    public merge_source?: string = "";

    public folded?: boolean = true;
    public showHidden?: boolean = false;

    public showReactionPicker?: boolean = false;

    public hidden?: boolean = false;
    public edited?: boolean = false;
    public history?: TicketAnswerHistoryDto[] = [];

    public internal_mentions?: string[] = [];
    public internal_mentions_group?: string[] = [];
  
    public static fromObject(src: any) {
      return Object.assign(new TicketAnswerDto(), src);
    }
  }