<footer class="footer footer-transparent d-print-none">
    <div class="container-xl">
        
        <div class="row">
            <div class="col">
                <strong>Ihr EFS-IT-Support</strong><br>
                Direkt. Nah. Persönlich.
            </div>

            <div class="col">
                <strong>Noch Fragen oder Probleme?</strong><br>
                EFS-IT-Support<br>+49 6131 58388-55
            </div>

            <div class="col">
                <strong>Wichtige Links:</strong><br>
                <a href="https://efs-ag.com">EFS Website</a> - <a href="https://start.efs-ag.com">EFS-IT-World</a><br>
                <a target="_blank" href="https://get.teamviewer.com/EFS_IT-Support">Fernwartung</a>
            </div>
        </div>
        
    </div>
</footer>