<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title" *ngIf="mode === 'new'">Label erstellen</h2>
                        <h2 class="card-title" *ngIf="mode === 'edit'">Label bearbeiten</h2>
                    </div>

                    <div class="card-body">

                        <div class="row mb-3">

                            <div class="row ms-2 me-2 mt-2 mb-2" *ngIf="isLoading">
                                <div class="col-12">
                                    <div class="progress mt-2 mb-2">
                                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 mb-3" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Name</label>
                                    <p class="text-muted">
                                        Aussagekräftiger Name für das Label, welcher in der Übersicht angezeigt wird.
                                    </p>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="label.name">
                                </div>
                            </div>

                            <div class="col-6 mb-3" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Farbe</label>
                                    <p class="text-muted">
                                        HTML Farbcode für das Label
                                    </p>

                                    <input type="color" class="form-control form-control-color" [(ngModel)]="label.color" title="Choose your color">
                                </div>
                            </div>

                            <div class="col-6 mb-3" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Sichtbarkeit</label>
                                    
                                    <p class="text-muted">
                                        Gibt an ob das Label im Ticket System angezeigt werden soll.
                                    </p>

                                    <select class="form-control" name="hidden" id="hidden" [(ngModel)]="label.hidden">
                                        <option [ngValue]="true">Ausgeblendet</option>
                                        <option [ngValue]="false">Sichtbar</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-6 mb-3" *ngIf="!isLoading && (label.name !== '' && label.color !== '')">
                                <div class="form-group">
                                    <label class="form-label">Vorschau</label>

                                    <p class="text-muted">
                                        Zeigt wie das Label im Ticket System aussieht
                                    </p>
                                    
                                    <div class="badge" [style.background-color]="label.color">{{ label.name }}</div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card-footer">
                        <button class="btn btn-efs" *ngIf="mode === 'edit'" (click)="save()"
                            [disabled]="isLoading">Speichern</button>
                        <button class="btn btn-efs" *ngIf="mode === 'new'" (click)="create()"
                            [disabled]="isLoading">Anlegen</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>