import { Injectable } from "@angular/core";
import { Observable, map, throwError } from "rxjs";
import { AppDto } from "../dtos/app.dto";
import { FaqDto } from "../dtos/faq.dto";
import { GroupDto } from "../dtos/group.dto";
import { SignatureDto } from "../dtos/signature.dto";
import { TicketAnalyticsDto } from "../dtos/ticket-analytics.dto";
import { TicketAnswerAttachmentDto } from "../dtos/ticket-answer-attachment.dto";
import { TicketAnswerDto } from "../dtos/ticket-answer.dto";
import { TicketDto } from "../dtos/ticket.dto";
import { Page, queryPaginated } from "../models/page.model";
import { LandingpageBaseService } from "./landingpage-base.service";
import { TicketBaseService } from "./ticket-base.service";

@Injectable({ providedIn: 'root' })
export class LandingpageApiService extends LandingpageBaseService {

    // ****** User based API calls ******

    getMyApps(): Observable<AppDto[]> {
        return this.http
            .get<AppDto[]>(
                this.apiUrl(`app/my-ticket-user`),
                this.httpOptions,
            );
    }

    getMyAppsAll(): Observable<AppDto[]> {
        return this.http
            .get<AppDto[]>(
                this.apiUrl(`app/my-ticket`),
                this.httpOptions,
            );
    }

    searchFaqs(app_id: string, search: string): Observable<FaqDto[]> {
        return this.http
            .post<FaqDto[]>(
                this.apiUrl(`faq/search/${app_id}?search=${search}`),
                {},
                this.httpOptions,
            );
    }

    getFaqByApp(app_id: string): Observable<any> {
        return this.http
            .get<any>(
                this.apiUrl(`faq/byapp/${app_id}`),
                this.httpOptions,
            );
    }

    // ****** Admin based API calls ******

    getApps(): Observable<AppDto[]> {
        return this.http
            .get<AppDto[]>(
                this.apiUrl(`app`),
                this.httpOptions,
            );
    }

    // ****** Helper ******

    toPage<T>(observable: any): Observable<Page<T>> {
        return observable.pipe(
            map((pageData: any) => {
                const page = new Page();
                if (pageData == null) {
                    return throwError(() => new Error('Page data is null'));
                }

                page.total = pageData.totalCount ? pageData.totalCount : 0;
                page.currentPage = pageData.page ? parseInt(pageData.page) : 1;
                page.pageSize = pageData.size ? pageData.size : 25;
                page.data = pageData.data ? pageData.data as T[] : [];
                return page;
            },
            ),
        );
    }
}