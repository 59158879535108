import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workdays'
})
export class WorkdaysPipe implements PipeTransform {

  transform(input: number): string {
    
    if (input < 8) {
      return '0 Tage'
    } else {
      return Math.floor(input / 8) + ' Tage';
    }

  }
}
