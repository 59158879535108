import { TicketTemplateFieldDto } from "./ticket-template-field.dto";

export class TicketTemplateDto extends Object {  
    public app_id: string = "";
    public name: string = "";
    public description: string = "";
    public language: string = "";
    public category: string = "";
    public isGeneral: boolean = false;
    public fields: TicketTemplateFieldDto[] = [];
    public _id?: string = "";
  
    public static fromObject(src: any) {
      return Object.assign(new TicketTemplateDto(), src);
    }
  }