import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attachmentSize'
})
export class AttachmentSizePipe implements PipeTransform {

  transform(value: number): unknown {
    
    // convert bytes into readable format
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (value === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(value) / Math.log(1024));
    return Math.round(value / Math.pow(1024, i)) + ' ' + sizes[i];
  }

}
