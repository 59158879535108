<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Ereignisse</h2>
                        <div class="card-actions">
                            <a (click)="export()" class="btn btn-efs link">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-file-download" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z">
                                    </path>
                                    <path d="M12 17v-6"></path>
                                    <path d="M9.5 14.5l2.5 2.5l2.5 -2.5"></path>
                                </svg>
                                Exportieren
                            </a>
                        </div>
                    </div>
                    <div class="card-body border-bottom">
                        <div class="" [formGroup]="filterForm">

                            <div class="row row-deck row-cards">
                                <div class="col-lg-8 col-md-12 col-sm-12 mb-2">
                                    <div class="text-muted flex-center">
                                        <div class="badges-list" *ngIf="users && users.length > 0">
                                            <span><strong>Filter </strong></span>

                                            <div *ngFor="let user of users">

                                                <span
                                                    *ngIf="filter === 'user_sso_id' && filterValue === user.user_sso_id"
                                                    (click)="setFilter('user_sso_id', '')"
                                                    class="link badge badge-outline bg-primary">{{ user.name }}</span>
                                                <span
                                                    *ngIf="filter !== 'user_sso_id' || filterValue !== user.user_sso_id"
                                                    (click)="setFilter('user_sso_id', user.user_sso_id)"
                                                    class="link badge badge-outline bg-primary-lt">{{ user.name
                                                    }}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-12 col-sm-12">

                                    <div class="input-group">
                                        <input type="text" class="form-control " placeholder="Suche"
                                            [(ngModel)]="search" (change)="firstPage()" type="search" name="search"
                                            aria-label="Anliegen durchsuchen" formControlName="search">
                                        <input nbInput formControlName="page" type="hidden">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">

                        <div class="alert alert-warning m-3" *ngIf="pageData.data && pageData.data.length === 0">
                            Keine Ereignisse gefunden!
                        </div>

                        <div class="table-responsive" *ngIf="pageData.data && pageData.data.length > 0 && !loading">
                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th>Aktivität</th>
                                        <th>Datum</th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let activity of pageData.data" class="link" (click)="openLink(activity.target_id)">

                                        <td data-label="Aktivität" [innerHTML]="activity.text"></td>
                                        <td data-label="Datum">{{ activity.date | date: 'dd.MM.yyy HH:mm' }} - Vor {{
                                            activity.date | amLocale: browserLocale | amTimeAgo:true }}</td>

                                        <td data-label="Aktionen">
                                            <a class="link" *ngIf="!activity.target_sub_id"
                                                [routerLink]="['/admin/tickets', activity.target_id]"><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-external-link" width="24"
                                                    height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                    </path>
                                                    <path d="M11 13l9 -9"></path>
                                                    <path d="M15 4h5v5"></path>
                                                </svg></a>

                                            <a class="link" *ngIf="activity.target_sub_id"
                                                [routerLink]="['/admin/tickets', activity.target_id]"
                                                [fragment]="activity.target_sub_id"><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-external-link" width="24"
                                                    height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                    </path>
                                                    <path d="M11 13l9 -9"></path>
                                                    <path d="M15 4h5v5"></path>
                                                </svg></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{
                                        pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>