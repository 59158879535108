import { TicketAnswerAttachmentDto } from "./ticket-answer-attachment.dto";
import { TicketTemplateDataDto } from "./ticket-template-data.dto";
import { TicketTemplateFieldDto } from "./ticket-template-field.dto";

export class PredefinedAnswerDto extends Object {
    public _id?: string = "";
    
    public app_id: string = "";
    public user_sso_id?: string = "";
    public language?: string = "";
    public public: boolean = false;
    public description: string = "";
    public text: string = "";
    public official: boolean = false;
  
    public static fromObject(src: any) {
      return Object.assign(new PredefinedAnswerDto(), src);
    }
  }