import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { PredefinedAnswersComponent } from './predefined-answers/predefined-answers.component';
import { TemplatesComponent } from './templates/templates.component';
import { AuditComponent } from './audit/audit.component';
import { UsersComponent } from './users/users.component';
import { IncidentEditComponent } from './incidents-edit/incidents-edit.component';
import { PredefinedAnswerEditComponent } from './predefined-answer-edit/predefined-answer-edit.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { ManageTicketComponent } from './manage-ticket/manage-ticket.component';
import { ThemeModule } from '../theme/theme.module';
import { AdminRoutingModule } from './admin-routing.module';
import { TicketsComponent } from './tickets/tickets.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent, EditorModule } from '@tinymce/tinymce-angular';
import { GroupsComponent } from './groups/groups.component';
import { GroupEditComponent } from './group-edit/group-edit.component';
import { NgPipesModule } from 'ngx-pipes';
import { MomentModule } from 'ngx-moment';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { Select2Module } from 'ng-select2-component';
import { LabelsComponent } from './labels/labels.component';
import { LabelEditComponent } from './label-edit/label-edit.component';
import { ActivitiesComponent } from './activities/activities.component';
import { PrioritiesComponent } from './priorities/priorities.component';
import { PriorityEditComponent } from './priority-edit/priority-edit.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentShowComponent } from './attachment-show/attachment-show.component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [
    DashboardComponent,
    TicketsComponent,
    IncidentsComponent,
    PredefinedAnswersComponent,
    TemplatesComponent,
    AuditComponent,
    UsersComponent,
    IncidentEditComponent,
    PredefinedAnswerEditComponent,
    TemplateEditComponent,
    UserEditComponent,
    ManageTicketComponent,
    GroupsComponent,
    GroupEditComponent,
    LabelsComponent,
    LabelEditComponent,
    ActivitiesComponent,
    PrioritiesComponent,
    PriorityEditComponent,
    AttachmentShowComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    NgPipesModule,
    MomentModule,
    NgxChartsModule,
    CKEditorModule,
    Select2Module,
    NgxExtendedPdfViewerModule,
    NgbTooltipModule,
    PickerComponent,
  ],
  exports: [
    DashboardComponent,
    TicketsComponent,
    IncidentsComponent,
    PredefinedAnswersComponent,
    TemplatesComponent,
    AuditComponent,
    UsersComponent,
    IncidentEditComponent,
    PredefinedAnswerEditComponent,
    TemplateEditComponent,
    UserEditComponent,
    ManageTicketComponent,
    GroupsComponent,
    GroupEditComponent,
    SearchComponent,
  ]
})
export class AdminModule { }
