<div class="page-body mt-3">

    <div class="container container-slim py-4" *ngIf="isLoading">
        <div class="text-center">
            <div class="mb-3">
            </div>
            <div class="text-muted mb-3">Lade Daten</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="!isLoading">

        <div class="row" *ngIf="ticket !== undefined && ticket.created_for_user">
            <div class="col-12">
                <div class="alert alert-primary">Dieses Anliegen wurde vom EFS-IT-Support nach Ihrer Kontaktaufnahme für
                    Sie im EFSsupport System erstellt.</div>
            </div>
        </div>

        <div class="row row-deck row-cards" *ngIf="ticket !== undefined">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row w-100" *ngIf="ticket !== undefined">
                            <div class="col-lg-8 mt-0">
                                <h2 class="page-title" *ngIf="ticket !== undefined">
                                    <span class="text-muted me-1">#{{ ticket.ticket_number }}</span> - <span
                                        class="ms-1">{{ ticket.subject }}</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="datagrid">
                            <div class="datagrid-item">
                                <div class="datagrid-title">Anfragennummer</div>
                                <div class="datagrid-content">#{{ ticket.ticket_number }}</div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Status</div>
                                <div class="datagrid-content">

                                    <span *ngIf="ticket.status === 'open'" class="status status-green">Offen</span>
                                    <span *ngIf="ticket.status === 'closed'"
                                        class="status status-secondary">Geschlossen</span>
                                    <span *ngIf="ticket.status === 'answered'"
                                        class="status status-success">Beantwortet</span>
                                    <span *ngIf="ticket.status === 'in_progress'" class="status status-success">In
                                        Bearbeitung</span>
                                    <span *ngIf="ticket.status === 'more_information_needed'"
                                        class="status status-warning">Weitere Informationen benötigt</span>

                                </div>
                            </div>


                            <div class="datagrid-item" *ngIf="!ticket.created_for_user">
                                <div class="datagrid-title">Erstellt von</div>

                                <div class="datagrid-content " *ngIf="ticket.author_user_sso_id">
                                    <span>{{ ticket.author_name }}</span>
                                    <br>
                                    <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                        }}<br></span>
                                    <span class="text-muted"
                                        *ngIf="ticket.author_user_sso_id">{{ticket.author_user_sso_id }}</span>
                                </div>

                                <div class="datagrid-content" *ngIf="!ticket.author_user_sso_id">
                                    {{ ticket.author_name }}<br>
                                    <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                        }}<br></span>
                                </div>
                            </div>

                            <div class="datagrid-item" *ngIf="ticket.created_for_user">
                                <div class="datagrid-title">Erstellt von</div>

                                <div class="datagrid-content " *ngIf="ticket.created_for_user_cn">
                                    <span>{{ ticket.created_for_user_name }}</span>
                                    <br>
                                    <span class="text-muted" *ngIf="ticket.created_for_user_mail">{{
                                        ticket.created_for_user_mail
                                        }}<br></span>
                                    <span class="text-muted"
                                        *ngIf="ticket.created_for_user_cn">{{ticket.created_for_user_cn }}</span>
                                </div>

                                <div class="datagrid-content" *ngIf="!ticket.created_for_user_cn">
                                    {{ ticket.created_for_user_name }}<br>
                                    <span class="text-muted" *ngIf="ticket.created_for_user_mail">{{
                                        ticket.created_for_user_mail
                                        }}<br></span>
                                </div>
                            </div>

                            <div class="datagrid-item" *ngIf="ticket.created_for_user">
                                <div class="datagrid-title">Erstellt für</div>

                                <div class="datagrid-content " *ngIf="ticket.author_user_sso_id">
                                    <span>{{ ticket.author_name }}</span>
                                    <br>
                                    <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                        }}<br></span>
                                    <span class="text-muted"
                                        *ngIf="ticket.author_user_sso_id">{{ticket.author_user_sso_id }}</span>
                                </div>

                                <div class="datagrid-content" *ngIf="!ticket.author_user_sso_id">
                                    {{ ticket.author_name }}<br>
                                    <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                        }}<br></span>
                                </div>
                            </div>


                            <div class="datagrid-item">
                                <div class="datagrid-title">Quelle</div>
                                <div class="datagrid-content">

                                    <span *ngIf="ticket.source === 'mail'">E-Mail</span>
                                    <span *ngIf="ticket.source === 'landingpage'">EFSsupport System</span>

                                </div>
                            </div>
                            <div class="datagrid-item">
                                <div class="datagrid-title">Anliegen Typ</div>
                                <div class="datagrid-content" *ngIf="ticket.type === 'support_request'">
                                    Support-Anfrage</div>
                                <div class="datagrid-content" *ngIf="ticket.type === 'bug_report'">Bug-Report
                                </div>
                                <div class="datagrid-content" *ngIf="ticket.type === 'feature_request'">
                                    Feature-Wunsch</div>
                            </div>
                            <div class="datagrid-item" *ngIf="ticket.created_at">
                                <div class="datagrid-title">Erstellt am</div>
                                <div class="datagrid-content">{{ ticket.created_at | date: 'dd.MM.yyyy HH:mm' }}</div>
                            </div>
                            <div class="datagrid-item" *ngIf="ticket.first_answer_at">
                                <div class="datagrid-title">Erste Antwort</div>
                                <div class="datagrid-content">{{ ticket.first_answer_at | date: 'dd.MM.yyyy HH:mm' }}
                                </div>
                            </div>
                            <div class="datagrid-item" *ngIf="ticket.last_answer_at">
                                <div class="datagrid-title">Neueste Antwort</div>
                                <div class="datagrid-content">{{ ticket.last_answer_at | date: 'dd.MM.yyyy HH:mm' }}
                                </div>
                            </div>
                            <div class="datagrid-item" *ngIf="ticket.resolved_at">
                                <div class="datagrid-title">Anliegen erledigt</div>
                                <div class="datagrid-content">{{ ticket.resolved_at | date: 'dd.MM.yyyy HH:mm' }}</div>
                            </div>

                            <div class="datagrid-item" *ngIf="ticket.escalated_at">
                                <div class="datagrid-title">Anliegen eskaliert</div>
                                <div class="datagrid-content">{{ ticket.escalated_at | date: 'dd.MM.yyyy HH:mm' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" *ngFor="let answer of ticketAnswers">

                <!-- Initial Data submittet over the ticket system -->
                <div class="card"
                    *ngIf="answer.type === 'auditAnswer' &&  answer.audit_answer_type === 'ticketStatusUpdate' && answer.audit_visible_in_ticket">
                    <div class="card-status-start bg-primary"></div>
                    <div class="card-header card-small">
                        <h3 class="card-title">Statusänderung</h3>
                    </div>
                    <div class="card-body">
                        {{ answer.text }}
                    </div>
                </div>

                <!-- Customer Answers -->
                <div class="card" *ngIf="answer.type === 'customerAnswer' || answer.type === 'supportAnswer'">
                    <div class="card-header card-small">

                        <span *ngIf="answer.created_for_user" class="me-1 badge badge-warning"
                            [ngbTooltip]="'Diese Antwort wurde vom EFS-IT-Support erstellt'">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-shield"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h2"></path>
                                <path
                                    d="M22 16c0 4 -2.5 6 -3.5 6s-3.5 -2 -3.5 -6c1 0 2.5 -.5 3.5 -1.5c1 1 2.5 1.5 3.5 1.5z">
                                </path>
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                            </svg>
                        </span>

                        <strong class="me-1">{{ answer.author_name }}</strong> <span
                            *ngIf="answer.type === 'supportAnswer'"
                            class="badge badge-outline bg-primary me-1">Support</span> - <strong class="ms-1 me-1">{{
                            answer.created_at | date: 'dd.MM.yyyy HH:mm' }}</strong>

                        <span *ngIf="answer.merge_source" class="ms-1 link badge badge-outline bg-cyan"
                            [routerLink]="['/ticket/view', answer.merge_source]"
                            [ngbTooltip]="'Diese Antwort wurde aus der zusammengeführten Anfrage übernommen.'">Zusammengeführt</span>
                    </div>
                    <div [className]="answer.type === 'customerAnswer' ? 'card-body folded-message' : 'card-body folded-message notpublic'"
                        [innerHTML]="htmlContent(answer.text)" *ngIf="answer.folded || answer.folded === undefined">
                    </div>
                    <div [className]="answer.type === 'customerAnswer' ? 'card-body unfolded-message' : 'card-body unfolded-message notpublic'"
                        [innerHTML]="htmlContent(answer.text)" *ngIf="answer.folded === false"></div>

                    <div class="card-footer card-small" *ngIf="answer.folded || answer.folded === undefined">
                        <a (click)="toggleFold(answer);" class="link">Alles anzeigen</a>
                    </div>
                    <div class="card-footer card-small" *ngIf="answer.folded === false">
                        <a (click)="toggleFold(answer);" class="link">Weniger anzeigen</a>
                    </div>
                    <div class="card-footer card-small" *ngIf="answer.attachments && answer.attachments.length > 0">
                        <a class="link badge badge-gray me-1 mb-1 attachment-link"
                            (click)="downloadAttachment(answer._id!, attachment)"
                            *ngFor="let attachment of answer.attachments">
                            <span class="ms-1 v-middle">{{ attachment.filename }} {{ attachment.size | attachmentSize
                                }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">Neue Antwort schreiben</div>
                    </div>

                    <div class="card-body" *ngIf="ticket.internal_status === 'merged'">

                        <div class="alert alert-warning">
                            Diese Anfrage wurde vom EFS-IT-Support mit <a
                                [routerLink]="['/ticket', ticket.merged_with_ticket_id]">einer anderen Anfrage</a>
                            zusammengeführt. Bitte antworten Sie dort.
                        </div>

                    </div>

                    <div class="card-body" *ngIf="ticket.internal_status !== 'merged'">

                        <div class="row row-eq-height">
                            <div class="col-lg-8 mb-4">
                                <div class="form-group">
                                    <label class="form-label">Nachricht</label>
                                    <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="answerText"></ckeditor>
                                </div>
                            </div>

                            <div class="col-lg-4 text-center center-container mb-2">
                                <div class="justify-center">
                                    <div class="mb-3">
                                        <label *ngIf="!isProcessing" class="form-label"><strong>Nimm eine Sprachmitteilung auf</strong></label>
                                        <small *ngIf="!isProcessing">(maximal 5 Minuten)</small><br>

                                        <div *ngIf="isRecording && !blobUrl"><h3 class="mb-1">{{recordedTime}}</h3></div>

                                        <div *ngIf="!isRecording && blobUrl && !isProcessing">
                                            <audio class="mt-3 mb-2"  controls>
                                                <source [src]="blobUrl" type="audio/mpeg">
                                            </audio>
                                        </div>

                                        <div class="btn btn-efs btn-sm mt-2" *ngIf="!isRecording && !blobUrl && !isProcessing" (click)="startRecording()">Aufnahme starten</div>
                                        <div class="btn btn-efs btn-sm mt-2" *ngIf="!isProcessing && isRecording && !blobUrl" (click)="stopRecording()">Aufnahme stoppen</div>
                                        <div class="btn btn-efs btn-sm mt-2" *ngIf="!isProcessing && !isRecording && blobUrl" (click)="clearRecordedData()">Aufnahme löschen</div><br>
                                        <div class="btn btn-success btn-sm mt-2" *ngIf="!isProcessing && !isRecording && blobUrl" (click)="processRecording()">Aufnahme verwenden</div>

                                        <div class="ms-3 me-3 alert alert-warning mt-3" *ngIf="noTranscriptionFound">
                                            Der Text der Sprachmitteilung konnte nicht automatisch erkannt werden. Die Aufnahme wird dennoch an den EFS-IT-Support übermittelt.
                                        </div>

                                        <div class="ps-5 pe-5 mt-3" *ngIf="isProcessing">
                                            <h3 class="mb-1">Die Aufnahme wird verarbeitet.</h3>
                                            <small class="mb-3">Einen Moment bitte ...</small><br>
                                            <div class="progress ps-2 pe-2 mt-2">
                                                <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div>
                                        <label class="form-label"><strong>Nimm ein Desktop-Video auf</strong></label>
                                        <small>(maximal 5 Minuten)</small><br>
                                        <div class="ms-2 me-2 pt-2"><strong>TIPP:</strong> Wähle nach dem Starten den gesamten Bildschirm für die Aufnahme aus.<br>Damit erhält der EFS-IT-Support die beste Übersicht um dir bei deinem Anliegen zu helfen!</div><br>
                                        <div *ngIf="isVideoRecording"><h3 class="mb-1">{{recordedTimeVideo}}</h3></div>
                                        <div class="btn btn-efs btn-sm mt-2" *ngIf="!isVideoRecording" (click)="startVideoRecording()">Aufnahme starten</div>
                                        <div class="btn btn-efs btn-sm mt-2" *ngIf="isVideoRecording" (click)="stopVideoRecording()">Aufnahme stoppen</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <label class="form-label">Anhang</label>
                                    <p>
                                        Hier kannst du Bilder, Videos, PDFs und Textdateien hochladen. Die maximale
                                        Größe pro Datei beträgt 100 MB.
                                    </p>
        
                                    <input type="file" class="form-control" multiple
                                        accept="video/mp4, video/quicktime, message/rfc822, application/pdf, image/*, text/csv, text/xml, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/webm"
                                        (change)="fileUploadEvent($event)">

                                        <ul class="mt-3">


                                            <div *ngFor="let attachment of answerFiles">
    
                                                <div class="mb-0 mt-1 pb-1" *ngIf="attachment.type !== 'audio/webm'"> {{ attachment.name }} <a [ngbTooltip]="'Anhang entfernen'" class="link ms-2" (click)="removeAttachment(attachment)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 7l16 0" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 11l0 6" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                  </svg></a>
                                                </div>
    
                                                <div class="mb-0 mt-1 pb-1" *ngIf="attachment.type === 'audio/webm'" style="line-height: 0px;">
                                                    <audio  controls>
                                                        <source [src]="getBlobUrl(attachment)" type="audio/mpeg">
                                                    </audio>
    
                                                    <a [ngbTooltip]="'Anhang entfernen'" class="link ms-2" style="vertical-align: super;" (click)="removeAttachment(attachment)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M4 7l16 0" />
                                                        <path d="M10 11l0 6" />
                                                        <path d="M14 11l0 6" />
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                      </svg></a>
                                                </div>
    
                                            </div>                             
                                        </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer" *ngIf="ticket.internal_status !== 'merged'">

                        <div class="progress mb-2" *ngIf="isLoading">
                            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                        </div>

                        <button type="button" class="btn btn-efs {{ isLoading ? 'disabled' : '' }}"
                            (click)="answerTicket()" [disabled]="isLoading">Antwort senden</button>
                    </div>
                </div>
            </div>
        </div>
    </div>