<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Meine Anliegen</h3>

                        <div class="card-actions">
                            <a [routerLink]="'/ticket/new'" class="btn btn-efs me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus me-0"
                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 5l0 14"></path>
                                    <path d="M5 12l14 0"></path>
                                </svg>
                                <span class="d-none d-md-block ms-2">Neues Anliegen erstellen</span>
                            </a>
                        </div>
                    </div>

                    <div class="card-body border-bottom py-3">
                        <div class="" [formGroup]="filterForm">

                            <div class="row row-deck row-cards">
                                <div class="col-lg-3 col-sm-6 col-6">
                                    <div class="form-group w-100">
                                        <div class="form-label">Status</div>
                                        <select2 formControlName="statusFilters" [styleMode]="'default'"
                                            class="d-block w-100 with-badges" [data]="filtersSelect2"
                                            [templates]="{template: template}" placeholder="Status als Filter auswählen"
                                            multiple>
                                            <ng-template #template let-label="label" let-data="data">
                                                <span class="link badge" [style.background-color]="data?.color"
                                                    [style.border-color]="data?.color">{{label }}</span>
                                            </ng-template>
                                        </select2>
                                    </div>
                                </div>

                                <div class="col-auto ms-auto mb-3">

                                    <div class="form-group w-100">
                                        <div class="form-group w-100">
                                            <div class="form-label">Suche</div>
                                            <input type="text" class="form-control" placeholder="Suche"
                                                type="search" name="search" aria-label="Anliegen durchsuchen"
                                                formControlName="search">
                                            <input nbInput formControlName="page" type="hidden">

                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">
                        <div class="alert alert-warning m-3" *ngIf="pageData.data && pageData.data.length === 0">
                            Keine Anliegen gefunden!
                        </div>

                        <div class="table-responsive" *ngIf="pageData.data && pageData.data.length > 0">
                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th class="w-1">ID
                                            <!-- Download SVG icon from http://tabler-icons.io/i/chevron-up -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-sm icon-thick"
                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M6 15l6 -6l6 6" />
                                            </svg>
                                        </th>
                                        <th>Betreff</th>
                                        <th>Status</th>
                                        <th>Erstellt am</th>
                                        <th>Letzte Antwort</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of pageData.data" class="link"
                                        [routerLink]="['/ticket/view/', ticket._id]">
                                        <td data-label="Anliegen-ID"><span class="text-muted"><a
                                                    [routerLink]="['/ticket/view/', ticket._id]">#{{
                                                    ticket.ticket_number }}</a></span></td>
                                        <td data-label="Betreff">{{ ticket.subject }}</td>

                                        <td data-label="Status">
                                            <div class="btn-list flex-nowrap">
                                                <span *ngIf="ticket.status === 'open'"
                                                    class="status status-primary nowrap">Offen</span>
                                                <span *ngIf="ticket.status === 'closed'"
                                                    class="status status-secondary nowrap">Geschlossen</span>
                                                <span *ngIf="ticket.status === 'answered'"
                                                    class="status status-success nowrap">Beantwortet</span>
                                                <span *ngIf="ticket.status === 'in_progress'"
                                                    class="status status-success nowrap">In Bearbeitung</span>
                                                <span *ngIf="ticket.status === 'more_information_needed'"
                                                    class="status status-warning nowrap">Weitere Info. benötigt</span>
                                            </div>
                                        </td>

                                        <td data-label="Erstellt am">
                                            {{ ticket.created_at | date: 'dd.MM.yyyy HH:mm' }}
                                        </td>

                                        <td data-label="Letzte Antwort">
                                            {{ ticket.last_answer_at | date: 'dd.MM.yyyy HH:mm' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{
                                        pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>