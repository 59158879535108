import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import Swal from 'sweetalert2';
import { TicketApiService } from '../../services/ticket-api.service';
import { showError } from '../../helper/utils.helper';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { AddUserGroupDto } from '../../dtos/add-user-group.dto';
import { LabelDto } from '../../dtos/label.dto';
import { PreloaderService } from '../../services/preloader.service';


@Component({
  selector: 'app-label-edit',
  templateUrl: './label-edit.component.html',
  styleUrls: ['./label-edit.component.scss']
})
export class LabelEditComponent implements OnInit {

  labelId: string | undefined;
  mode = 'new';
  isLoading = false;

  label: LabelDto = new LabelDto();
  users: UserDto[] = [];


  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    protected route: ActivatedRoute,
    protected router: Router,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        if (params['id'] === 'new') {
          this.mode = 'new';
        } else {
          this.mode = 'edit';
          this.labelId = params['id'];
          
          this.preloaderService.dataLoaded.subscribe({
            next: () => {
              this.loadData();
            },
            error: (err) => {
              this.isLoading = false;
              showError(err);
            },
          });
        }
      }
    })
  }

  loadData() {
    this.isLoading = true;
    this.ticketApi.getLabel(this.labelId!).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.label = data;
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });
  }

  save() {
    if (this.checkInput()) {
      this.isLoading = true;
      this.ticketApi.updateLabel(this.labelId!, {
        name: this.label.name,
        color: this.label.color,
        hidden: this.label.hidden,
      }).subscribe({
        next: (data) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Label gespeichert',
          }).then(() => {
            this.loadData();
          })
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        }
      });
    }
  }

  create() {
    if (this.checkInput()) {
      this.isLoading = true;
      this.ticketApi.addLabel({
        name: this.label.name,
        color: this.label.color,
        hidden: this.label.hidden,
      }).subscribe({
        next: (data) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Label erstellt',
          }).then(() => {
            this.router.navigate(['/admin/labels'])
          })
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        }
      });
    }
  }



  checkInput() {
    if (this.label.name === '' || this.label.name === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Namen ein',
      })

      return false;
    }

    if (this.label.color === '' || this.label.color === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte wählen Sie eine Farbe aus',
      })

      return false;
    }

    return true;
  }
}
