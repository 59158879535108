<div class="page-body">
    <div class="container-fluid">

        <div class="row">
            <div class="col-12 col-md-12 mb-3">

                <div class="card mb-4" *ngIf="step > 1 && step !== 6">
                    <div class="card-body">
                        <a class="btn btn-efs me-2" (click)="stepBack()">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-left"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M5 12l6 6"></path>
                                <path d="M5 12l6 -6"></path>
                            </svg>
                            Schritt zurück
                        </a>

                        <span *ngIf="step > 1 && !isGeneral" class="me-1">
                            Ausgewählte App: <span class="badge bg-efs v-middle">{{ step1Selected!.name }}</span>
                        </span>

                        <span *ngIf="step > 3" class="ms-1">
                            Ausgewählte Vorlage: <span class="badge bg-efs v-middle">{{ step3Selected!.name }}</span>
                        </span>
                    </div>
                </div>

                <!-- Set 2 -->
                <div class="row row-deck row-cards" *ngIf="step === 2">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header justify-center">
                                <h1 class="card-title">Bitte prüfe die vorhandenen Lösungsvorschläge</h1>
                            </div>
                            <div class="card-body border-bottom py-3">

                                <div class="row ms-2 me-2 mt-2 mb-2" *ngIf="loading">
                                    <div class="col-12">
                                        <div class="progress mt-2 mb-2">
                                            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="alert alert-warning"
                                    *ngIf="step2Faq !== undefined && step2Faq.length === 0">Keine FAQs gefunden!</div>

                                <div id="faq-1" class="accordion" role="tablist" aria-multiselectable="true"
                                    *ngIf="step2Faq !== undefined">
                                    <div class="accordion-item" *ngFor="let faq of step2Faq; let i=index;">
                                        <div class="accordion-header" role="tab">
                                            <button class="accordion-button collapsed" data-bs-toggle="collapse"
                                                attr.data-bs-target="#faq-1-{{i+1}}">{{ faq.question }}</button>
                                        </div>
                                        <div id="faq-1-{{i+1}}" class="accordion-collapse collapse" role="tabpanel"
                                            data-bs-parent="#faq-1">
                                            <div class="accordion-body pt-0 mt-3">
                                                <div [innerHTML]="faq.answer">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                Keine passende Lösung dabei? <button class="btn btn-efs ms-2"
                                    (click)="step2Select()">Support-Anfrage erstellen</button>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- /Set 2 -->

                <!-- Set 3 -->
                <div class="row row-deck row-cards" *ngIf="step === 3">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header justify-center">
                                <h1 class="card-title">Bitte wähle eine passende Vorlage aus</h1>
                            </div>
                            <div class="card-body border-bottom py-3">

                                <div class="row ms-2 me-2 mt-2 mb-2" *ngIf="loading">
                                    <div class="col-12">
                                        <div class="progress mt-2 mb-2">
                                            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="alert alert-warning"
                                    *ngIf="step3Templates !== undefined && step3Templates.length === 0">Keine Vorlagen
                                    gefunden!</div>


                                <div class="form-selectgroup form-selectgroup-boxes d-flex flex-column"
                                    *ngIf="step3Templates !== undefined">
                                    <label class="form-selectgroup-item flex-fill" (click)="step3Select(template)"
                                        *ngFor="let template of step3Templates; let i=index;">
                                        <div class="form-selectgroup-label d-flex align-items-center p-3">

                                            <div class="ms-2">
                                                <div class="font-weight-medium">{{ template.name }}</div>
                                                <div class="text-muted">{{ template.description }}</div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div class="card-footer"
                                *ngIf="step3Templates !== undefined && step3Templates.length === 0"><button
                                    class="btn btn-efs" (click)="startQuickTicket()">Support-Anfrage erstellen</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Set 3 -->

                <!-- Set 4 -->
                <div class="row row-deck row-cards" *ngIf="step === 4">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header justify-center">
                                <h1 class="card-title">Bitte fülle alle geforderten Informationen aus</h1>
                            </div>
                            <div class="card-body border-bottom py-3">

                                <div class="alert alert-warning"
                                    *ngIf="step3Templates !== undefined && step3Templates.length === 0">Keine
                                    Vorlageninhalte gefunden!</div>


                                <div class="form-group mb-3" *ngFor="let templateItem of step3Selected!.fields">
                                    <label><strong>{{ templateItem.name }}</strong> <span *ngIf="templateItem.required"
                                            class="text-red ms-1">*</span></label>
                                    <p>
                                        <small>{{ templateItem.display_name }}</small>
                                    </p>

                                    <editor [(ngModel)]="templateItem.content" *ngIf="templateItem.type === 'text'"
                                        [init]="{ language: 'de', branding: false, skin: isDark, content_css: isDarkContent }"
                                        [apiKey]="'u6v9pmalq7oxl6e4zdd1iw40zjbozz4kkf4fi0fxjbys5u8z'"></editor>

                                    <input [(ngModel)]="templateItem.content" class="form-control" type="text"
                                        *ngIf="templateItem.type === 'string'">
                                    <input [(ngModel)]="templateItem.content" class="form-control" type="number"
                                        *ngIf="templateItem.type === 'number'">

                                    <select class="form-control" [(ngModel)]="templateItem.content"
                                        *ngIf="templateItem.type === 'select'">
                                        <option [value]="option" *ngFor="let option of templateItem!.options">{{ option
                                            }}</option>
                                    </select>
                                </div>


                                <div class="mt-5">Im nächsten Schritt kannst du Bilder oder Videos hochladen</div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-efs me-2" (click)="step4Select()">Weiter</button>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- /Set 4 -->

                <!-- Set 5 -->
                <div class="row row-deck row-cards" *ngIf="step === 5">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header justify-center">
                                <h1 class="card-title">Angaben überprüfen</h1>
                            </div>
                            <div class="card-body border-bottom py-3">

                                <div class="alert alert-warning"
                                    *ngIf="step3Templates !== undefined && step3Templates.length === 0">Keine
                                    Vorlageninhalte gefunden!</div>


                                <div class="form-group mb-3" *ngFor="let templateItem of step3Selected!.fields">
                                    <h3>{{ templateItem.name }}</h3>
                                    <p class="text-muted">
                                        {{ templateItem.display_name }}
                                    </p>

                                    <div class="alert" [innerHTML]="templateItem.content"></div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-header justify-center">
                                <h1 class="card-title">Dateien hochladen</h1>
                            </div>
                            <div class="card-body border-bottom py-3">
                                <p>
                                    Hier kannst du Bilder, Videos, PDFs und Textdateien hochladen. Die maximale
                                    Dateigröße beträgt 100 MB.
                                </p>


                                <div class="form-group mt-2">
                                    <label class="form-label">Anhang</label>
                                    <input type="file" class="form-control" multiple
                                    accept="video/mp4, video/quicktime, message/rfc822, application/pdf, image/*, text/csv, text/xml, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/webm"
                                    (change)="fileUploadEvent($event)">
                                </div>

                            </div>
                            <div class="card-footer">
                                <button class="btn btn-efs me-2" (click)="submit()" [disabled]="loading">Anfrage abschicken</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Set 5 -->

                <!-- Set 6 Quick Anliegen -->
                <div class="row row-deck row-cards" *ngIf="step === 6">
 
                    <div class="col-12" *ngIf="filteredIncidents && filteredIncidents.length > 0">
                        <div class="card">
                            <div class="card-header card-small">
                                <h3 class="card-title">Aktuelle Störungen</h3>
                            </div>
                            <div class="card-body card-small">

                                <div class="accordion mt-2 mb-2" [id]="incident._id" *ngFor="let incident of filteredIncidents">
                                    <div class="accordion-item">
                                        <div class="alert alert-warning alert-warning-black mb-0" id="heading-1">
                                            <button class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapse-1" aria-expanded="true">
                                                <strong>{{ incident.description }}</strong>
                                            </button>
                                            <p class="mb-0 incident-inner" [innerHTML]="incident.text">

                                            </p>
                                        </div>
                                        <div id="collapse-1" class="accordion-collapse collapse"
                                            [attr.data-bs-parent]="'#' + incident._id">
                                            <div class="accordion-body">
                                                
                                                <ul class="timeline" *ngFor="let entry of incident.history">
                                                    <li class="timeline-event">
                                                        <div class="timeline-event-icon">
                        
                                                            <svg *ngIf="entry.state === 'NEW'" xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-alert-hexagon" width="24" height="24"
                                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path
                                                                    d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z">
                                                                </path>
                                                                <path d="M12 8v4"></path>
                                                                <path d="M12 16h.01"></path>
                                                            </svg>
                        
                                                            <svg *ngIf="entry.state === 'UNDER_INVESTIGATION'"
                                                                xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search"
                                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                                stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                                <path d="M21 21l-6 -6"></path>
                                                            </svg>
                        
                                                            <svg *ngIf="entry.state === 'IN_PROGRESS'" xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-repeat" width="24" height="24"
                                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3"></path>
                                                                <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3"></path>
                                                            </svg>
                        
                                                            <svg *ngIf="entry.state === 'RESOLVED'" xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-checks" width="24" height="24"
                                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M7 12l5 5l10 -10"></path>
                                                                <path d="M2 12l5 5m5 -5l5 -5"></path>
                                                            </svg>
                        
                                                            <svg *ngIf="entry.state === 'CLOSED'" xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-lock-square-rounded" width="24" height="24"
                                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
                                                                <path
                                                                    d="M8 11m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z">
                                                                </path>
                                                                <path d="M10 11v-2a2 2 0 1 1 4 0v2"></path>
                                                            </svg>
                        
                                                        </div>
                                                        <div class="card timeline-event-card">
                                                            <div class="card-body">
                                                                <div class="text-secondary float-end">
                                                                    <small>{{ entry.created_at | date: 'dd.MM.yyyy HH:mm' }}</small>
                                                                </div>
                                                                <h4><span class="badge badge-outline bg-efs me-1">{{ renderState(entry.state) }}</span> <span class="badge badge-outline bg-efs">{{ renderSeverity(entry.severity) }}</span></h4>
                        
                                                                <strong>{{ entry.description }}</strong>
                                                                <p class="mt-2 incident-inner" [innerHTML]="entry.text"></p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h1 class="card-title">Anliegen erstellen</h1>
                            </div>
                            <div class="card-body border-bottom py-3">

                                <div class="row mb-4" *ngIf="this.userData.role.includes('efsticket_view')">
                                    <div class="col-12 col-md-4 mb-2">
                                        <div class="form-group">
                                            <label class="form-label"><strong [ngbTooltip]="'Gibt an ob das Anliegen im Namen eines Vertriebspartners erstellt werden soll. Dies ist Sinnvoll, wenn der Vertriebspartner sein Anliegen per Telefon geschildert hat.'">Anliegen
                                                    für Vertriebspartner erstellen</strong></label>


                                            <select class="form-control" [(ngModel)]="quickTicket.created_for_user">
                                                <option [ngValue]="false">Nein</option>
                                                <option [ngValue]="true">Ja</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-8 col-md-4" *ngIf="quickTicket.created_for_user">
                                        <div class="form-group">
                                            <label class="form-label"><strong
                                                    [ngbTooltip]="'Gebe den Namen oder die Vermittlernummer ein um den Vertriebspartner zu suchen'">Vertriebspartner
                                                    suchen</strong></label>


                                            <div class="row g-2" *ngIf="quickTicket.created_for_user_cn === ''">
                                                <div class="col">
                                                    <input type="text" class="form-control" [(ngModel)]="searchTerm" placeholder="Name oder Vermittlernummer">
                                                </div>
                                                <div class="col-auto">
                                                    <a (click)="searchUsers()" class="btn btn-icon" aria-label="Button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24"
                                                            height="24" viewBox="0 0 24 24" stroke-width="2"
                                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                                            stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                                            <path d="M21 21l-6 -6" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="row g-2" *ngIf="quickTicket.created_for_user_cn !== ''">
                                                <div class="col">
                                                    <input type="text" class="form-control" disabled [value]="(quickTicket.created_for_user_name + ' (' + quickTicket.created_for_user_cn + ')')" placeholder="Name oder Vermittlernummer">
                                                </div>
                                                <div class="col-auto">
                                                    <a (click)="resetUser()" class="btn btn-icon" aria-label="Button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M4 7l16 0"></path>
                                                            <path d="M10 11l0 6"></path>
                                                            <path d="M14 11l0 6"></path>
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                         </svg>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-4 col-md-4" *ngIf="quickTicket.created_for_user">
                                        <div class="form-group">
                                            <label class="form-label"><strong
                                                    [ngbTooltip]="'Gibt an ob der Vertriebspartner über das neue Anliegen informiert wird und darauf antworten kann. Wenn \'Nein\' ausgewählt wird, ist das Anliegen ausschließlich intern.'">Für Vermittler sichtbar</strong></label>


                                            <select class="form-control"
                                                [(ngModel)]="quickTicket.created_for_user_visible">
                                                <option [ngValue]="false">Nein</option>
                                                <option [ngValue]="true">Ja</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- Search hits -->
                                <div class="row mb-4" *ngIf="this.userData.role.includes('efsticket_view') && search && searchedUsers.length > 0">
                                    <div class="col-12 card card-body">
                                        
                                        <table class="table table-vcenter datatable">
                                            <thead>
                                                <tr>
                                                    <th>Vermittlernummer</th>
                                                    <th>Name</th>
                                                    <th>E-Mail Adresse</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let user of searchedUsers">
                                                    
                                                    <td>{{user.cn}}</td>
                                                    <td>{{user.name}}</td>
                                                    <td>{{user.mail}}</td>

                                                    <td class="text-end">
                                                        <button class="btn align-text-top" (click)="selectUser(user)">Auswählen</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <!-- Search hits -->

                                <div class="row mb-4" *ngIf="this.userData.role.includes('efsticket_view')">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="form-label"><strong [ngbTooltip]="'Über diese Option kann das Anliegen direkt einer Gruppe zugewiesen werden. Wenn keine Auswahl erfolgt, wird das Anliegen automatisch dem EFS First-Level Support zugewiesen.'">Zuständige Gruppe</strong></label>

                                            <select class="form-control" [(ngModel)]="quickTicket.group_id">
                                                <option [ngValue]="undefined">Keine Zuweisung</option>
                                                <option [ngValue]="group._id" *ngFor="let group of groups">{{ group.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="form-label"><strong [ngbTooltip]="'Über diese Option kann das Anliegen direkt einem Supporter zugeordnet werden'">Zuständiger Supporter</strong></label>

                                            <select class="form-control" [(ngModel)]="quickTicket.assigned_to_user_sso_id">
                                                <option [ngValue]="undefined">Keine Zuweisung</option>
                                                <option [ngValue]="user.user_sso_id" *ngFor="let user of users">{{ user.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="form-label"><strong [ngbTooltip]="'Über diese Option kann das Anliegen einem Typ zugeordnet werden'">Typ</strong></label>

                                            <select class="form-control" [(ngModel)]="quickTicket.type">
                                                <option [ngValue]="'bug_report'">Bug-Report</option>
                                                <option [ngValue]="'support_request'">Support-Anfrage</option>
                                                <option [ngValue]="'feature_request'">Feature-Wunsch</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group mb-2" *ngIf="!this.userData.role.includes('efsticket_view')">
                                            <label class="form-label"><strong>Art des Anliegens</strong> <span class="text-red ms-1">*</span></label>
        
                                            <select class="form-control" [(ngModel)]="quickTicket.type" (ngModelChange)="updateQuickTicketType($event)">
                                                <option [ngValue]="''">Bitte auswählen!</option>
                                                <option [ngValue]="'bug_report'">Einen Fehler oder ein Problem melden</option>
                                                <option [ngValue]="'support_request'">Hilfe erhalten</option>
                                                <option [ngValue]="'feature_request'">Neuanforderung oder Änderungswünsche</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group mb-2" *ngIf="!this.userData.role.includes('efsticket_view')">
                                            <label class="form-label"><strong>Betroffene App</strong> <span class="text-red ms-1">*</span></label>
        
                                            <select class="form-control" [(ngModel)]="quickTicket.app_id" (ngModelChange)="updateQuickTicketApp($event)">
                                                <option [ngValue]="''">Bitte auswählen!</option>
                                                <option [ngValue]="app._id" *ngFor="let app of step1Apps">{{ app.name }}</option>
                                                <option [ngValue]="'sonstiges'">Sonstiges</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group mb-2" *ngIf="!this.userData.role.includes('efsticket_view')">
                                            <label class="form-label"><strong>Priorität des Anliegens</strong> <span class="text-red ms-1">*</span></label>
        
                                            <select class="form-control" [(ngModel)]="quickTicket.priority">
                                                <option [ngValue]="'low'">Niedrig</option>
                                                <option [ngValue]="'medium'">Mittel</option>
                                                <option [ngValue]="'high'">Hoch</option>
                                                <option [ngValue]="'asap'">ASAP (Maximale Priorität)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <hr *ngIf="(quickTicket.app_id !== '' || quickTicket.type === 'feature_request') && quickTicket.type !== ''">

                                <div class="form-group mb-4" *ngIf="((quickTicket.app_id !== '' || quickTicket.type === 'feature_request') && quickTicket.type !== '') || this.userData.role.includes('efsticket_view')">
                                    <label class="form-label" *ngIf="!this.userData.role.includes('efsticket_view')">
                                        <strong *ngIf="quickTicket.type === 'bug_report'">Fasse das Problem zusammen</strong>
                                        <strong *ngIf="quickTicket.type === 'support_request'">Wobei brauchst du Hilfe?</strong>
                                        <strong *ngIf="quickTicket.type === 'feature_request'">Titel des Anliegens</strong>
                                        
                                        <span class="text-red ms-1">*</span>
                                        <span class="ms-1 text-muted">(maximal 100 Zeichen)</span>
                                    </label>
                                    
                                    <label class="form-label" *ngIf="this.userData.role.includes('efsticket_view')">
                                        <strong>Betreff</strong>
                                        <span class="text-red ms-1">*</span>
                                        <span class="ms-1 text-muted">(maximal 100 Zeichen)</span>
                                    </label>

                                    <input *ngIf="!this.userData.role.includes('efsticket_view')" maxlength="100" (ngModelChange)="this.faqSearchEvent.next($event);" type="text" class="form-control" [(ngModel)]="quickTicket.subject" required>
                                    <input *ngIf="this.userData.role.includes('efsticket_view')" maxlength="100" type="text" class="form-control" [(ngModel)]="quickTicket.subject" required>
                                </div>

                                <!-- Load FAQs based on Subject -->
                                <div class="form-group mb-4" *ngIf="faqSearchResults.length > 0">
                                    <hr class="mb-2">
                                    <label class="form-label"><strong class="me-2">Ähnliche Themen</strong> <small>Bitte prüfe ob einer der Artikel deine Anfrage löst</small></label>

                                    <div class="card card-small mb-1" *ngFor="let faq of faqSearchResults">
                                        <a target="_blank" [href]="'https://start.efs-ag.com/faq/' + faq._id"><strong><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-help-hexagon"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" /><path d="M12 16v.01" /><path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" /></svg> {{ faq.question }}</strong></a>
                                    </div>

                                    <hr class="mt-2">

                                    <div *ngIf="shouldReadFAQFirst">
                                        <h3>Keine passenden Lösungsvorschläge dabei?</h3>
                                        <button (click)="noMatchingFaqs()" class="btn btn-efs">Jetzt Anliegen erstellen</button>
                                    </div>
                                    
                                </div>

                                <div class="form-group mb-4" *ngIf="!shouldReadFAQFirst && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view')">
                                    <label class="form-label"><strong>Wo tritt das Problem auf?</strong> <span class="text-red ms-1">*</span></label>

                                    <div>
                                        <label class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio"  name="radios-inline" [checked]="quickTicket.issueSource === 'this_device'" (click)="quickTicket.issueSource = 'this_device'">
                                          <span class="form-check-label">Auf diesem Gerät</span>
                                        </label>

                                        <label class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" name="radios-inline" (click)="quickTicket.issueSource = 'other_device'">
                                          <span class="form-check-label">Auf einem anderen Gerät</span>
                                        </label>
                                      </div>
                                </div>

                                <div class="form-group mb-4" *ngIf="!shouldReadFAQFirst && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view') && quickTicket.issueSource === 'other_device'">
                                    <label class="form-label"><strong>Betroffenes Gerät</strong> <span class="text-red ms-1">*</span></label>
                                    <p>Gebe die genaue Gerätebezeichnung ein. Im besten Fall mit Modelljahr.</p>
                                    <input [(ngModel)]="quickTicket.metadata.device" type="text" class="form-control" placeholder="Windows 11 Laptop, IPad Pro, IPhone 15, ...">
                                </div>

                                <div class="form-group mb-4" *ngIf="!shouldReadFAQFirst && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view') && quickTicket.issueSource === 'other_device'">
                                    <label class="form-label"><strong>Installierte Betriebssystem Version</strong> <span class="text-red ms-1">*</span></label>
                                    <p>Trage bitte die Betriebssystem Version ein, die auf dem Gerät aktuell läuft. Du findest die Information in den Einstellungen.</p>
                                    <input [(ngModel)]="quickTicket.metadata.os_version" type="text" class="form-control" placeholder="Windows 11, IOS 17.4, Android 15, ...">
                                </div>

                                <div class="form-group mb-4" *ngIf="!shouldReadFAQFirst && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view') && quickTicket.issueSource === 'other_device'">
                                    <label class="form-label"><strong>Verwender Browser</strong> <span class="text-red ms-1">*</span></label>
                                    <p>Gebe an, welchen Browser du verwendet hast. Im besten Fall auch die Version des Browsers. Du findest die Information meistens unter Hilfe, oder Einstellungen.</p>
                                    <input [(ngModel)]="quickTicket.metadata.browser" type="text" class="form-control" placeholder="Chrome, Firefox, Safari, ...">
                                </div>

                                <div class="form-group mb-4" *ngIf="!shouldReadFAQFirst && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view')">
                                    <label class="form-label"><strong>Tritt das Problem zusätzlich auch auf anderen Geräten auf?</strong> <span class="text-red ms-1">*</span></label>

                                    <div>
                                        <label class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio"  name="radios-inline-different_device_affected" (click)="quickTicket.different_device_affected = 'Ja'">
                                          <span class="form-check-label">Ja</span>
                                        </label>

                                        <label class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" name="radios-inline-different_device_affected" (click)="quickTicket.different_device_affected = 'Nein'">
                                          <span class="form-check-label">Nein</span>
                                        </label>
                                      </div>
                                </div>

                                <div class="form-group mb-4" *ngIf="!shouldReadFAQFirst && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view')">
                                    <label class="form-label"><strong>Hast du bereits probiert den Browser-Cache und die Cookies zu löschen?</strong> <span class="text-red ms-1">*</span> <a class="link ms-2" (click)="browserCleanupHelp()"><small>(Mehr Informationen)</small></a></label>

                                    <div>
                                        <label class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio"  name="radios-inline-browser_cleanup" (click)="quickTicket.browser_cleanup = 'Ja'">
                                          <span class="form-check-label">Ja</span>
                                        </label>

                                        <label class="form-check form-check-inline">
                                          <input class="form-check-input" type="radio" name="radios-inline-browser_cleanup" (click)="quickTicket.browser_cleanup = 'Nein'">
                                          <span class="form-check-label">Nein</span>
                                        </label>
                                      </div>
                                </div>                       

                                <div class="form-group mb-4" *ngIf="(!shouldReadFAQFirst || isQuickTicket) || this.userData.role.includes('efsticket_view')">
                                    <div class="row row-eq-height">
                                        <div class="col-lg-8 mb-4">
                                            <label class="form-label"><strong>Nachricht</strong> <span
                                                class="text-red ms-1">*</span></label>
                                            <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="quickTicket.text"></ckeditor>
                                        </div>

                                        <div class="col-lg-4 text-center center-container mb-2">
                                            <div class="justify-center">

                                                <div class="mb-3">
                                                    <label *ngIf="!isProcessing" class="form-label"><strong>Nimm eine Sprachmitteilung auf</strong></label>
                                                    <small *ngIf="!isProcessing">(maximal 5 Minuten)</small><br>

                                                    <div *ngIf="isRecording && !blobUrl"><h3 class="mb-1">{{recordedTime}}</h3></div>

                                                    <div *ngIf="!isRecording && blobUrl && !isProcessing">
                                                        <audio class="mt-3 mb-2"  controls>
                                                            <source [src]="blobUrl" type="audio/mpeg">
                                                        </audio>
                                                    </div>

                                                    <div class="btn btn-efs btn-sm mt-2" *ngIf="!isRecording && !blobUrl && !isProcessing" (click)="startRecording()">Aufnahme starten</div>
                                                    <div class="btn btn-efs btn-sm mt-2" *ngIf="!isProcessing && isRecording && !blobUrl" (click)="stopRecording()">Aufnahme stoppen</div>
                                                    <div class="btn btn-efs btn-sm mt-2" *ngIf="!isProcessing && !isRecording && blobUrl" (click)="clearRecordedData()">Aufnahme löschen</div><br>
                                                    <div class="btn btn-success btn-sm mt-2" *ngIf="!isProcessing && !isRecording && blobUrl" (click)="processRecording()">Aufnahme verwenden</div>

                                                    <div class="ms-3 me-3 alert alert-warning mt-3" *ngIf="noTranscriptionFound">
                                                        Der Text der Sprachmitteilung konnte nicht automatisch erkannt werden. Die Aufnahme wird dennoch an den EFS-IT-Support übermittelt.
                                                    </div>

                                                    <div class="ps-5 pe-5 mt-3" *ngIf="isProcessing">
                                                        <h3 class="mb-1">Die Aufnahme wird verarbeitet.</h3>
                                                        <small class="mb-3">Einen Moment bitte ...</small><br>
                                                        <div class="progress ps-2 pe-2 mt-2">
                                                            <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div>
                                                    <label class="form-label"><strong>Nimm ein Desktop-Video auf</strong></label>
                                                    <small>(maximal 5 Minuten)</small><br>
                                                    <div class="ms-2 me-2 pt-2" *ngIf="!this.userData.role.includes('efsticket_view')"><strong>TIPP:</strong> Wähle nach dem Starten den gesamten Bildschirm für die Aufnahme aus.<br>Damit erhält der EFS-IT-Support die beste Übersicht um dir bei deinem Anliegen zu helfen!</div>
                                                    <div *ngIf="isVideoRecording"><h3 class="mb-1">{{recordedTimeVideo}}</h3></div>
                                                    <div class="btn btn-efs btn-sm mt-2" *ngIf="!isVideoRecording" (click)="startVideoRecording()">Aufnahme starten</div>
                                                    <div class="btn btn-efs btn-sm mt-2" *ngIf="isVideoRecording" (click)="stopVideoRecording()">Aufnahme stoppen</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group mb-4 mt-4" *ngIf="!shouldReadFAQFirst || isQuickTicket">
                                    <label class="form-label">Anhang</label>

                                    <p>
                                        Hier kannst du Bilder, Videos, PDFs und Textdateien hochladen. Die maximale
                                        Größe pro Datei beträgt 100 MB.
                                    </p>

                                    <input type="file" class="form-control" multiple
                                    accept="video/mp4, video/quicktime, message/rfc822, application/pdf, image/*, text/csv, text/xml, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/webm"
                                    (change)="fileUploadEvent($event)">

                                    <ul class="mt-3">


                                        <div *ngFor="let attachment of answerFiles">

                                            <div class="mb-0 mt-1 pb-1" *ngIf="attachment.type !== 'audio/webm'"> {{ attachment.name }} <a [ngbTooltip]="'Anhang entfernen'" class="link ms-2" (click)="removeAttachment(attachment)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l16 0" />
                                                <path d="M10 11l0 6" />
                                                <path d="M14 11l0 6" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                              </svg></a>
                                            </div>

                                            <div class="mb-0 mt-1 pb-1" *ngIf="attachment.type === 'audio/webm'" style="line-height: 0px;">
                                                <audio  controls>
                                                    <source [src]="getBlobUrl(attachment)" type="audio/mpeg">
                                                </audio>

                                                <a [ngbTooltip]="'Anhang entfernen'" class="link ms-2" style="vertical-align: super;" (click)="removeAttachment(attachment)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 7l16 0" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 11l0 6" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                  </svg></a>
                                            </div>

                                        </div>                             
                                    </ul>
                                </div>

                                <div class="form-group mt-5 mb-2" *ngIf="(!shouldReadFAQFirst || isQuickTicket) && (quickTicket.type === 'bug_report' || quickTicket.type === 'support_request') && !this.userData.role.includes('efsticket_view')">
                                    <blockquote>
                                        <h2>Wichtig:</h2>
                                        <p class="mb-0">
                                            Um dir bei deinem Problem helfen zu können, brauchen wir so viele Informationen wie möglich.<br>
                                            Besonders bei technischen Problemen ist es wichtig, dass du uns die Abfolge deiner Handlungen und die daraus entstehende Fehlermeldung so genau wie möglich beschreibst.<br><br>
                                            Bitte versuche immer einen Screenshot oder ein Video von dem Fehler zu machen. Das hilft uns sehr bei der Analyse.
                                            
                                        </p>
                                    </blockquote>
                                </div>

                            </div>
                            <div class="card-footer" *ngIf="this.userData.role.includes('efsticket_view')">
                                <button class="btn btn-efs me-2" [disabled]="loading" (click)="submitQuickTicket()">Anfrage
                                    abschicken</button>
                            </div>

                            <div class="card-footer" *ngIf="!this.userData.role.includes('efsticket_view') && !shouldReadFAQFirst">
                                <button class="btn btn-efs me-2" [disabled]="loading" (click)="submitQuickTicket()">Anfrage
                                    abschicken</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Set 6 -->

            </div>

            <div class="col-12 col-md-4 d-none">

                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">In wenigen Schritten zu deiner Support-Anfrage</h3>
                        <ul class="steps steps-vertical" *ngIf="step !== 6">
                            <li class="step-item {{ step === 1 ? 'active' : '' }}">
                                <div class="h4 m-0">App oder Themengebiet auswählen</div>
                                <div class="text-muted">
                                    Wähle einen Bereich aus, der am besten zu deinem Problem passt.
                                </div>
                            </li>
                            <li class="step-item {{ step === 2 ? 'active' : '' }}">
                                <div class="h4 m-0">FAQs prüfen</div>
                                <div class="text-muted">
                                    Schaue ob du einen passenden Lösungsvorschlag findest
                                </div>
                            </li>
                            <li class="step-item {{ step === 5 ? 'active' : '' }}">
                                <div class="h4 m-0">Abschicken</div>
                                <div class="text-muted">
                                    Prüfe deine Eingaben und schicke sie an den EFS-IT-Support.
                                </div>
                            </li>
                        </ul>

                        <ul class="steps steps-vertical" *ngIf="step === 6">
                            <li class="step-item active">
                                <div class="h4 m-0">Anliegen erstellen</div>
                                <div class="text-muted">
                                    Bitte fülle alle geforderten Informationen so genau wie möglich aus.
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="card-footer" *ngIf="step !== 6 && userData && userData.role.includes('efsticket_view')">
                        <a class="link" (click)="startQuickTicket()">Schnelles Anliegen</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>