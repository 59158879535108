import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertseconds'
})
export class ConvertsecondsPipe implements PipeTransform {

  transform(seconds: number): string {
    
    if (seconds < 60) {
      return seconds.toFixed(1) + ' Sekunden';
    }

    if (seconds < 3600) {
      return (seconds / 60).toFixed(1) + ' Minuten';
    }

    if (seconds < 86400) {
      return (seconds / 3600).toFixed(1) + ' Stunden';
    }

    if (seconds < 604800) {
      return (seconds / 86400).toFixed(1) + ' Tage';
    }
    
    return 'n/a';
  }

}
