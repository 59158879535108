import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PreloaderService {
    public dataLoaded : ReplaySubject<any> = new ReplaySubject(); 
    public refreshSidebar : ReplaySubject<any> = new ReplaySubject();  
    public hideNavElements : ReplaySubject<any> = new ReplaySubject();  
    
    finishedPreloading() {
        this.dataLoaded.next(true);
    }

    refreshSidebarData() {
        this.refreshSidebar.next(true);
    }

    hideNavElement() {
        this.hideNavElements.next(true);
    }
}