import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { ThemeModule } from './theme/theme.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PreloaderService } from './services/preloader.service';
import { CheckAuthService } from './services/check-auth.service';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng-select2-component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { AudioRecordingService } from './services/audio-recording.service';
import { VideoRecordingService } from './services/video-recording.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      config: environment.openIdConfig
    }),
    AppRoutingModule,
    ThemeModule,
    AdminModule,
    PagesModule,
    RouterModule,
    CKEditorModule,
    Select2Module,
    NgbModule,
    NgbTooltipModule,
    PickerComponent,
  ],
  providers: [
    PreloaderService,
    CheckAuthService,
    AudioRecordingService,
    VideoRecordingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
