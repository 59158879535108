<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">

                        <div *ngIf="showMyTickets">
                            <h2 class="card-title">
                                Meine {{ pageTitle }}
                            </h2>
                            <small class="text-muted">(Durch mich erstellt, oder mir zugeordnet)</small>
                        </div>


                        <div *ngIf="!showMyTickets">
                            <h2 class="card-title">
                                <span *ngIf="!openOnly">Alle {{ pageTitle }}</span><span *ngIf="openOnly">Offene {{
                                    pageTitle }}</span>
                            </h2>
                            <small class="text-muted">(Durch mich erstellt, mir zugeordnet, oder meinen Gruppen
                                zugeordnet)</small>
                        </div>

                        <div class="card-actions">
                            <a [routerLink]="'/ticket/new'" [queryParams]="{quickticket: true, mode: type}"
                                class="btn btn-efs me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus me-0"
                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 5l0 14"></path>
                                    <path d="M5 12l14 0"></path>
                                </svg>
                                <span class="d-none d-md-block ms-2">Neues Anliegen erstellen</span>
                            </a>
                        </div>
                    </div>
                    <div class="card-body border-bottom">
                        <div class="">

                            <div class="row row-deck row-cards" [formGroup]="filterForm">

                                <div class="col-lg-3 col-sm-6 col-6">
                                    <div class="form-group w-100">
                                        <div class="form-label">Status</div>
                                        <select2 formControlName="statusFilters" [styleMode]="'default'" class="d-block w-100 with-badges"
                                            [data]="filtersSelect2" [templates]="{template: template}"
                                            placeholder="Status als Filter auswählen" multiple>
                                            <ng-template #template let-label="label" let-data="data">
                                                <span class="link badge" [style.background-color]="data?.color"
                                                    [style.border-color]="data?.color">{{label }}</span>
                                            </ng-template>
                                        </select2>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-sm-6 col-6">
                                    <div class="form-group w-100">
                                        <div class="form-label">Gruppe</div>
                                        <select2 class="d-block w-100" [data]="groupsSelect2" formControlName="groupFilters"
                                            placeholder="Gruppen als Filter auswählen" multiple>
                                        </select2>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-sm-6 col-6">
                                    <div class="form-group w-100">
                                        <div class="form-label">Label</div>
                                        <select2 class="d-block w-100 with-badges" [data]="labelsSelect2" formControlName="labelFilters"
                                            [templates]="{template: template}" placeholder="Label als Filter auswählen"
                                            multiple>
                                            <ng-template #template let-label="label" let-data="data">
                                                <span class="link badge" [style.background-color]="data?.color"
                                                    [style.border-color]="data?.color">{{label }}</span>
                                            </ng-template>
                                        </select2>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-8 col-md-12 col-sm-12">
                                    <div class="flex-center">
                                        <div class="badges-list">
                                            <span><strong>Filter </strong></span>

                                            <span *ngIf="filterValue === 'open' && !openOnly"
                                                (click)="setFilter('status', '')"
                                                class="link badge badge-outline bg-primary">Offen</span>
                                            <span *ngIf="filterValue !== 'open' && !openOnly"
                                                (click)="setFilter('status', 'open')"
                                                class="link badge badge-outline bg-primary-lt">Offen</span>

                                            <span *ngIf="filterValue === 'closed' && !openOnly"
                                                (click)="setFilter('status', '')"
                                                class="link badge badge-outline bg-secondary">Geschlossen</span>
                                            <span *ngIf="filterValue !== 'closed' && !openOnly"
                                                (click)="setFilter('status', 'closed')"
                                                class="link badge badge-outline bg-secondary-lt">Geschlossen</span>

                                            <span *ngIf="filterValue === 'answered'" (click)="setFilter('status', '')"
                                                class="link badge badge-outline bg-success">Beantwortet</span>
                                            <span *ngIf="filterValue !== 'answered'"
                                                (click)="setFilter('status', 'answered')"
                                                class="link badge badge-outline bg-success-lt">Beantwortet</span>

                                            <span *ngIf="filterValue === 'in_progress'"
                                                (click)="setFilter('status', '')"
                                                class="link badge badge-outline bg-success">In Bearbeitung</span>
                                            <span *ngIf="filterValue !== 'in_progress'"
                                                (click)="setFilter('status', 'in_progress')"
                                                class="link badge badge-outline bg-success-lt">In Bearbeitung</span>

                                            <span *ngIf="filterValue === 'more_information_needed'"
                                                (click)="setFilter('status', '')"
                                                class="link badge badge-outline bg-warning">Weitere Info.
                                                benötigt</span>
                                            <span *ngIf="filterValue !== 'more_information_needed'"
                                                (click)="setFilter('status', 'more_information_needed')"
                                                class="link badge badge-outline bg-warning-lt">Weitere Info.
                                                benötigt</span>


                                            <span *ngIf="filterValue === 'escalated'"
                                                (click)="setFilter('internal_status', '')"
                                                class="link badge badge-outline bg-red">Eskaliert</span>
                                            <span *ngIf="filterValue !== 'escalated'"
                                                (click)="setFilter('internal_status', 'escalated')"
                                                class="link badge badge-outline bg-red-lt">Eskaliert</span>

                                            <span *ngIf="filterValue === 'merged'"
                                                (click)="setFilter('internal_status', '')"
                                                class="link badge badge-outline bg-cyan">Zusammengeführt</span>
                                            <span *ngIf="filterValue !== 'merged'"
                                                (click)="setFilter('internal_status', 'merged')"
                                                class="link badge badge-outline bg-cyan-lt">Zusammengeführt</span>

                                            <span *ngIf="filterValue === 'auto_closed_time'"
                                                (click)="setFilter('internal_status', '')"
                                                class="link badge badge-outline bg-azure">Automatisch Geschlossen</span>
                                            <span *ngIf="filterValue !== 'auto_closed_time'"
                                                (click)="setFilter('internal_status', 'auto_closed_time')"
                                                class="link badge badge-outline bg-azure-lt">Automatisch
                                                Geschlossen</span>

                                            <span *ngIf="filterValue === 'mail'" (click)="setFilter('source', '')"
                                                class="link badge badge-outline bg-secondary">Quelle: Mail</span>
                                            <span *ngIf="filterValue !== 'mail'" (click)="setFilter('source', 'mail')"
                                                class="link badge badge-outline bg-secondary-lt">Quelle: Mail</span>

                                            <span *ngIf="filterValue === 'landingpage'"
                                                (click)="setFilter('source', '')"
                                                class="link badge badge-outline bg-secondary">Quelle: Anliegen</span>
                                            <span *ngIf="filterValue !== 'landingpage'"
                                                (click)="setFilter('source', 'landingpage')"
                                                class="link badge badge-outline bg-secondary-lt">Quelle: Anliegen</span>

                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-lg-3 col-sm-6 col-6 mb-3">

                                    <div class="form-group w-100">
                                        <div class="form-label">Suche</div>
                                        <div class="input-group input-group-flat">
                                            <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-filter m-0" width="24"
                                                    height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z">
                                                    </path>
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item"
                                                    (click)="setSearchFilter('subject')">Betreff</a>
                                                <a class="dropdown-item" (click)="setSearchFilter('author')">Autor</a>
                                                <a class="dropdown-item"
                                                    (click)="setSearchFilter('ticket_number')">Anliegen
                                                    Nr.</a>
                                            </div>

                                            <span class="input-group-text" *ngIf="searchFilter !== ''">
                                                <kbd *ngIf="searchFilter === 'subject'">Betreff</kbd>
                                                <kbd *ngIf="searchFilter === 'author'">Autor</kbd>
                                                <kbd *ngIf="searchFilter === 'ticket_number'">Anliegen Nr.</kbd>
                                            </span>

                                            <input type="text" class="form-control " placeholder="Suche"
                                                type="search"
                                                name="search" aria-label="Anliegen durchsuchen"
                                                formControlName="search">
                                            <input nbInput formControlName="page" type="hidden">

                                            <span class="input-group-text">
                                                <a class="input-group-link link" (click)="resetFilter()"
                                                    [ngbTooltip]="'Filter zurücksetzen'">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-trash-x ms-2" width="24"
                                                        height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 7h16"></path>
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                        <path d="M10 12l4 4m0 -4l-4 4"></path>
                                                    </svg>
                                                </a>
                                            </span>
                                        </div>
                                    </div>



                                </div>
                            </div>

                            <!-- <div class="row mb-3" *ngIf="visibleLabels.length > 0">
                                <div class="badges-list">
                                    <span><strong>Label</strong></span>

                                    <div *ngFor="let label of visibleLabels">
                                        <span (click)="setLabel('')" *ngIf="selectedLabel === label._id"
                                            class="link badge" [style.background-color]="label.color"
                                            [style.border-color]="label.color">{{
                                            label.name }}</span>
                                        <span (click)="setLabel(label._id!)" *ngIf="selectedLabel !== label._id"
                                            class="link badge badge-text-inactive" [style.color]="label.color"
                                            [style.border-color]="label.color"
                                            [style.background-color]="label.color + '1A'">{{ label.name }}</span>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="row" *ngIf="groups && groups.length > 0">
                                <div class="badges-list" *ngIf="groups.length > 0">
                                    <span><strong>Gruppe</strong></span>

                                    <div *ngFor="let group of groups">
                                        <span *ngIf="groupFilter === group._id!" (click)="setGroup('')"
                                            class="link badge badge-outline bg-secondary">{{ group.name }}</span>
                                        <span *ngIf="groupFilter !== group._id!" (click)="setGroup(group._id!)"
                                            class="link badge badge-outline bg-secondary-lt">{{ group.name }}</span>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">

                        <div class="alert alert-warning m-3"
                            *ngIf="pageData.data && pageData.data.length === 0 && !loading">
                            Keine {{ pageTitle }} gefunden! <span
                                *ngIf="filter !== '' && filterValue !== '' && !(filter === 'status' && filterValue === 'open')">
                                - <strong class="filter-warning">Filter ist aktiv!</strong></span>
                        </div>

                        <div class="mt-1"
                            *ngIf="pageData.data && pageData.data.length > 0 && !loading && innerWidth <= 767">

                            <div [className]="ticket.read_by && !ticket.read_by.includes(userData.nickname) ? 'unread card card-body no-radius mb-1 link mobile-tickets-card ms-1 me-1' : 'card card-body no-radius mb-1 link mobile-tickets-card  ms-1 me-1'"
                                *ngFor="let ticket of pageData.data" [routerLink]="['/admin/tickets/', ticket._id]">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <span [ngbTooltip]="'Ungelesen, oder neue Antwort vorhanden'"
                                            class="badge bg-efs me-2"
                                            *ngIf="ticket.read_by && !ticket.read_by.includes(userData.nickname)"></span>
                                        <strong class="f-16">{{ ticket.subject }}</strong><small> (#{{
                                            ticket.ticket_number }})</small>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <strong>Autor</strong><br>
                                        {{ ticket.author_name }}
                                        <span class="text-muted" *ngIf="ticket.author_user_sso_id">{{
                                            ticket.author_user_sso_id }}</span>
                                    </div>

                                    <div class="col-6">
                                        <strong>E-Mail</strong><br>
                                        {{ ticket.author_email }}
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <strong>Status</strong><br>
                                        <div class="btn-list flex-nowrap">
                                            <span *ngIf="ticket.status === 'open'"
                                                class="status status-primary nowrap">Offen</span>
                                            <span *ngIf="ticket.status === 'closed'"
                                                class="status status-secondary nowrap">Geschlossen</span>
                                            <span *ngIf="ticket.status === 'answered'"
                                                class="status status-success nowrap">Beantwortet</span>
                                            <span *ngIf="ticket.status === 'in_progress'"
                                                class="status status-success nowrap">In Bearbeitung</span>
                                            <span *ngIf="ticket.status === 'more_information_needed'"
                                                class="status status-warning nowrap">Weitere Info. benötigt</span>

                                            <span *ngIf="ticket.internal_status === 'merged'"
                                                [ngbTooltip]="'Das Anliegen wurde mit einem anderen Anliegen zusammengeführt.'"
                                                class="status status-cyan nowrap">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-arrow-merge" width="24"
                                                    height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M8 7l4 -4l4 4"></path>
                                                    <path
                                                        d="M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394">
                                                    </path>
                                                    <path
                                                        d="M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394">
                                                    </path>
                                                </svg></span>

                                            <span
                                                [ngbTooltip]="'Das Anliegen wurde vom Benutzer, oder automatisch wegen Inaktivität eskaliert.'"
                                                *ngIf="ticket.internal_status === 'escalated'"
                                                class="status status-danger nowrap">Eskaliert</span>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <strong>Priorität</strong><br>
                                        <span *ngIf="ticket.priority === 'none'" class="status">Keine Eskalation</span>
                                        <span *ngIf="ticket.priority === 'low'" class="status">Niedrig</span>
                                        <span *ngIf="ticket.priority === 'medium'"
                                            class="status status-secondary">Mittel</span>
                                        <span *ngIf="ticket.priority === 'high'"
                                            class="status status-warning">Hoch</span>
                                        <span *ngIf="ticket.priority === 'asap'"
                                            class="status status-danger">ASAP</span>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <strong>Zuordnung</strong><br>
                                        {{ getGroupName(ticket.group_id) }}
                                    </div>

                                    <div class="col-6">
                                        <strong>Zuständigkeit</strong><br>
                                        <span *ngIf="ticket.assigned_to_user_sso_id">{{
                                            getUserName(ticket.assigned_to_user_sso_id) }}</span>
                                        <span *ngIf="!ticket.assigned_to_user_sso_id">Nicht zugeordnet</span>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <strong>Erstellt am</strong><br>
                                        {{ ticket.created_at | date: 'dd.MM.yyyy HH:mm' }}
                                    </div>

                                    <div class="col-6">
                                        <strong>Letzte Antwort am</strong><br>
                                        <span>{{ ticket.last_answer_at | date: 'dd.MM.yyyy HH:mm' }}</span><br
                                            *ngIf="ticket.last_answer_author_name">
                                        <span class="text-muted me-1" *ngIf="ticket.last_answer_author_name">{{
                                            ticket.last_answer_author_name }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div *ngIf="ticket.labels && ticket.labels.length > 0">
                                            <span *ngFor="let label of ticket.labels" class="badge me-1 mb-1"
                                                [style.background-color]="getLabel(label).color">{{
                                                getLabel(label).name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive"
                            *ngIf="pageData.data && pageData.data.length > 0 && !loading && innerWidth > 767">

                            <div class="m-3">
                                Zeige <strong>{{ pageData.data.length }}</strong> aus <strong>{{ pageData.total
                                    }}</strong> Anliegen <span
                                    *ngIf="filter !== '' && filterValue !== '' && !(filter === 'status' && filterValue === 'open')">
                                    - <strong class="filter-warning">Filter ist aktiv!</strong></span> <span
                                    *ngIf="labelFilterActive"> - <strong class="filter-warning">Label Filter ist
                                        aktiv!</strong> <a class="ms-1"
                                        (click)="disableLabelFilter()">Deaktivieren</a></span>
                            </div>

                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th class="w-1">ID
                                            <!-- Down active -->
                                            <svg (click)="order('ticket_number', 'asc')"
                                                *ngIf="sort.key === 'ticket_number' && sort.order === 'desc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line-filled"
                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M9 5l-.117 .007a1 1 0 0 0 -.883 .993v4.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-4.999a1 1 0 0 0 -1 -1h-6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>

                                            <!-- Down inactive -->
                                            <svg (click)="order('ticket_number', 'desc')"
                                                *ngIf="sort.key !== 'ticket_number'" xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-6h6v6z">
                                                </path>
                                                <path d="M15 3h-6"></path>
                                            </svg>

                                            <!-- Up active -->
                                            <svg (click)="order('ticket_number', 'desc')"
                                                *ngIf="sort.key === 'ticket_number' && sort.order === 'asc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-up-line-filled" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v5a1 1 0 0 0 1 1h6l.117 -.007a1 1 0 0 0 .883 -.993l-.001 -5h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 20a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>
                                        </th>
                                        <th>Betreff</th>
                                        <th>Autor</th>
                                        <th>Zuständigkeit/Zuordnung</th>
                                        <th>Labels</th>
                                        <th>Status
                                            <!-- Down active -->
                                            <svg (click)="order('status', 'asc')"
                                                *ngIf="sort.key === 'status' && sort.order === 'desc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line-filled"
                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M9 5l-.117 .007a1 1 0 0 0 -.883 .993v4.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-4.999a1 1 0 0 0 -1 -1h-6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>

                                            <!-- Down inactive -->
                                            <svg (click)="order('status', 'desc')" *ngIf="sort.key !== 'status'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-6h6v6z">
                                                </path>
                                                <path d="M15 3h-6"></path>
                                            </svg>

                                            <!-- Up active -->
                                            <svg (click)="order('status', 'desc')"
                                                *ngIf="sort.key === 'status' && sort.order === 'asc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-up-line-filled" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v5a1 1 0 0 0 1 1h6l.117 -.007a1 1 0 0 0 .883 -.993l-.001 -5h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 20a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>
                                        </th>
                                        <th>Priorität
                                            <!-- Down active -->
                                            <svg (click)="order('priority', 'asc')"
                                                *ngIf="sort.key === 'priority' && sort.order === 'desc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line-filled"
                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M9 5l-.117 .007a1 1 0 0 0 -.883 .993v4.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-4.999a1 1 0 0 0 -1 -1h-6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>

                                            <!-- Down inactive -->
                                            <svg (click)="order('priority', 'desc')" *ngIf="sort.key !== 'priority'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-6h6v6z">
                                                </path>
                                                <path d="M15 3h-6"></path>
                                            </svg>

                                            <!-- Up active -->
                                            <svg (click)="order('priority', 'desc')"
                                                *ngIf="sort.key === 'priority' && sort.order === 'asc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-up-line-filled" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v5a1 1 0 0 0 1 1h6l.117 -.007a1 1 0 0 0 .883 -.993l-.001 -5h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 20a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>
                                        </th>
                                        <th>Erstellt am
                                            <!-- Down active -->
                                            <svg (click)="order('created_at', 'asc')"
                                                *ngIf="sort.key === 'created_at' && sort.order === 'desc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line-filled"
                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M9 5l-.117 .007a1 1 0 0 0 -.883 .993v4.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-4.999a1 1 0 0 0 -1 -1h-6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>

                                            <!-- Down inactive -->
                                            <svg (click)="order('created_at', 'desc')" *ngIf="sort.key !== 'created_at'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-6h6v6z">
                                                </path>
                                                <path d="M15 3h-6"></path>
                                            </svg>

                                            <!-- Up active -->
                                            <svg (click)="order('created_at', 'desc')"
                                                *ngIf="sort.key === 'created_at' && sort.order === 'asc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-up-line-filled" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v5a1 1 0 0 0 1 1h6l.117 -.007a1 1 0 0 0 .883 -.993l-.001 -5h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 20a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>
                                        </th>
                                        <th>Letzte Antwort
                                            <!-- Down active -->
                                            <svg (click)="order('last_answer_at', 'asc')"
                                                *ngIf="sort.key === 'last_answer_at' && sort.order === 'desc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line-filled"
                                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                stroke="currentColor" fill="none" stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M9 5l-.117 .007a1 1 0 0 0 -.883 .993v4.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-4.999a1 1 0 0 0 -1 -1h-6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 2a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>

                                            <!-- Down inactive -->
                                            <svg (click)="order('last_answer_at', 'desc')"
                                                *ngIf="sort.key !== 'last_answer_at'" xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-down-line" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-6h6v6z">
                                                </path>
                                                <path d="M15 3h-6"></path>
                                            </svg>

                                            <!-- Up active -->
                                            <svg (click)="order('last_answer_at', 'desc')"
                                                *ngIf="sort.key === 'last_answer_at' && sort.order === 'asc'"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-big-up-line-filled" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v5a1 1 0 0 0 1 1h6l.117 -.007a1 1 0 0 0 .883 -.993l-.001 -5h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                                    stroke-width="0" fill="currentColor"></path>
                                                <path
                                                    d="M15 20a1 1 0 0 1 .117 1.993l-.117 .007h-6a1 1 0 0 1 -.117 -1.993l.117 -.007h6z"
                                                    stroke-width="0" fill="currentColor"></path>
                                            </svg>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of pageData.data"
                                        [className]="ticket.read_by && !ticket.read_by.includes(userData.nickname) ? 'unread link' : 'link'">
                                        <td data-label="Anliegen-ID" class="text-nowrap">
                                            <span class="text-muted">
                                                <span [ngbTooltip]="'Ungelesen, oder neue Antwort vorhanden'"
                                                    class="badge bg-efs me-2"
                                                    *ngIf="ticket.read_by && !ticket.read_by.includes(userData.nickname)"></span>
                                                <a [ngbTooltip]="'Klick auf die ID öffnet das Anliegen im neuen Tab. Klick auf alles andere öffnet das Anliegen im aktuellen Tab'" [routerLink]="['/admin/tickets/', ticket._id]" target="_blank">#{{ticket.ticket_number}}</a>
                                            </span>
                                        </td>
                                        <td data-label="Betreff" [routerLink]="['/admin/tickets/', ticket._id]">{{ ticket.subject }}</td>

                                        <td data-label="Autor" [routerLink]="['/admin/tickets/', ticket._id]">
                                            <span
                                                title="{{ ticket.author_user_sso_id ? ticket.author_user_sso_id : '' }}">{{
                                                ticket.author_name ? ticket.author_name : 'Name unbekannt' }}</span><br>
                                            <span class="text-muted me-1" *ngIf="ticket.author_email">{{
                                                ticket.author_email }}</span>
                                        </td>

                                        <td data-label="Zuständigkeit/Zuordnung" [routerLink]="['/admin/tickets/', ticket._id]">
                                            {{ getGroupName(ticket.group_id) }}<br>
                                            <span *ngIf="ticket.assigned_to_user_sso_id">{{
                                                getUserName(ticket.assigned_to_user_sso_id) }}</span>
                                            <span *ngIf="!ticket.assigned_to_user_sso_id">Nicht zugeordnet</span>
                                        </td>

                                        <td data-label="Labels" [routerLink]="['/admin/tickets/', ticket._id]">
                                            <div *ngIf="!ticket.labels || ticket.labels.length === 0"></div>

                                            <div *ngIf="ticket.labels && ticket.labels.length > 0">
                                                <span *ngFor="let label of ticket.labels"
                                                    class="badge badge-td me-1 mb-1"
                                                    [style.background-color]="getLabel(label).color">{{
                                                    getLabel(label).name }}</span>
                                            </div>
                                        </td>

                                        <td data-label="Status" [routerLink]="['/admin/tickets/', ticket._id]">
                                            <div class="btn-list flex-nowrap">
                                                <span *ngIf="ticket.status === 'open'"
                                                    class="status status-primary nowrap">Offen</span>
                                                <span *ngIf="ticket.status === 'closed'"
                                                    class="status status-secondary nowrap">Geschlossen</span>
                                                <span *ngIf="ticket.status === 'answered'"
                                                    class="status status-success nowrap">Beantwortet</span>
                                                <span *ngIf="ticket.status === 'in_progress'"
                                                    class="status status-success nowrap">In Bearbeitung</span>
                                                <span *ngIf="ticket.status === 'more_information_needed'"
                                                    class="status status-warning nowrap">Weitere Info. benötigt</span>

                                                <span *ngIf="ticket.internal_status === 'merged'"
                                                    [ngbTooltip]="'Das Anliegen wurde mit einem anderen Anliegen zusammengeführt.'"
                                                    class="status status-cyan nowrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-arrow-merge" width="24"
                                                        height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 7l4 -4l4 4"></path>
                                                        <path
                                                            d="M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394">
                                                        </path>
                                                        <path
                                                            d="M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394">
                                                        </path>
                                                    </svg></span>

                                                <span
                                                    [ngbTooltip]="'Das Anliegen wurde vom Benutzer, oder automatisch wegen Inaktivität eskaliert.'"
                                                    *ngIf="ticket.internal_status === 'escalated'"
                                                    class="status status-danger nowrap">Eskaliert</span>
                                            </div>
                                        </td>

                                        <td data-label="Priorität" [routerLink]="['/admin/tickets/', ticket._id]">
                                            <span *ngIf="ticket.priority === 'none'" class="status">Keine
                                                Eskalation</span>
                                            <span *ngIf="ticket.priority === 'low'" class="status">Niedrig</span>
                                            <span *ngIf="ticket.priority === 'medium'"
                                                class="status status-secondary">Mittel</span>
                                            <span *ngIf="ticket.priority === 'high'"
                                                class="status status-warning">Hoch</span>
                                            <span *ngIf="ticket.priority === 'asap'"
                                                class="status status-danger">ASAP</span>
                                        </td>

                                        <td data-label="Erstellt am" [routerLink]="['/admin/tickets/', ticket._id]">{{ ticket.created_at | date: 'dd.MM.yyyy HH:mm' }}
                                        </td>

                                        <td data-label="Letzte Antwort" [routerLink]="['/admin/tickets/', ticket._id]">
                                            <span>{{ ticket.last_answer_at | date: 'dd.MM.yyyy HH:mm' }}</span><br
                                                *ngIf="ticket.last_answer_author_name">
                                            <span class="text-muted me-1" *ngIf="ticket.last_answer_author_name">{{
                                                ticket.last_answer_author_name }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button class="page-link {{ (pageData.currentPage <= 1 ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{
                                        pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>