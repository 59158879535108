<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" *ngIf="mode === 'new'">Ausfüllvorgabe erstellen</h3>
                        <h3 class="card-title" *ngIf="mode === 'edit'">Ausfüllvorgabe bearbeiten</h3>
                    </div>

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Ein aussagekräftiger Name für die Ausfüllvorgabe die dem Vertriebspartner beim Erstellen des Anliegen angezeigt wird">Name</span></label>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="template.name">
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Eine kurze Beschreibung die die Vorlage passend beschreibt">Beschreibung</span></label>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="template.description">
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Die Sprache für die die Vorlage gültig ist">Sprache</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="template.language">
                                        <option [ngValue]="'hu'">Ungarisch</option>
                                        <option [ngValue]="'de'">Deutsch</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="row mb-3">

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Gibt an ob die Vorlage allgemein ist. Allgemeine Vorlagen müssen eine Kategorie haben">Allgemeine Vorlage</span></label>
                                    <select class="form-control" required name="" id=""
                                        [(ngModel)]="template.isGeneral">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="template.isGeneral">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Die Kategorie zu der die Vorlage gehört">Kategorie</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="template.category">
                                        <option [ngValue]="'Abrechnung'">Abrechnung</option>
                                        <option [ngValue]="'Personalwesen'">Personalwesen</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6" *ngIf="!template.isGeneral">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Die App zu der die Vorlage gehört">App</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="template.app_id">
                                        <option *ngFor="let app of apps" [value]="app._id">{{ app.name }} [{{ app.countries.join(', ').toUpperCase() }}]</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <button class="btn btn-efs btn-xs mt-3" (click)="addField()">Neues ausfüllbares Felder
                            hinzufügen</button>

                    </div>

                    <div class="card-body border-bottom" *ngFor="let field of template.fields">
                        <div class="row mb-3">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Der Anzeigename für das Feld">Name</span></label>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="field.name">
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Eine kurze Beschreibung was der Vertriebspartner in das Feld eingeben soll">Kurzbeschreibung</span></label>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="field.display_name">
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Gibt an von welchem Typ das Feld sein soll">Typ</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="field.type">
                                        <option [ngValue]="'string'">Zeichenfolge</option>
                                        <option [ngValue]="'text'">Textblock</option>
                                        <option [ngValue]="'number'">Nummer</option>
                                        <option [ngValue]="'select'">Auswahl</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Gibt an ob der Vertriebspartner das Feld zwingend ausfüllen muss">Eingabe erzwingen</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="field.required">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-4" *ngIf="field.type === 'string' || field.type === 'number'">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="left" popper="Eingaben können durch die Angabe einer 'Regex' Regel validiert werden. Bitte prüfe vorher, ob die Regel auch den gewünschten Effekt hat. Hilfreich ist hier regex101.com">Regex (Validierung der Eingabe)</span></label>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="field.regex">
                                </div>
                            </div>

                            <div class="col-4" *ngIf="field.type === 'select'">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="left" popper="Wenn das Feld vom Typ 'Auswahl' ist, können hier kommasepariert die möglichen Auswahloptionen angegeben werden">Auswahloptionen für Typ "Auswahl" (kommasepariert)</span></label>
                                    <input type="text" class="form-control"
                                        placeholder="Auswahloptionen, (kommasepariert)" name="" id=""
                                        [(ngModel)]="field.options">
                                </div>
                            </div>
                        </div>

                        <a class="link" (click)="removeField(field)">Feld löschen</a>


                    </div>

                    <div class="card-footer">
                        <button class="btn btn-efs" *ngIf="mode === 'edit'" (click)="save()">Speichern</button>
                        <button class="btn btn-efs" *ngIf="mode === 'new'" (click)="create()">Anlegen</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Vorschau</h3>
                        <div class="card-actions">
                            <small class="me-2">Die Vorschau zeigt dir wie die Vorlage im System für den Vertriebspartner aussieht.</small>
                        </div>
                    </div>

                    <div class="card-body">

                        <div class="form-selectgroup form-selectgroup-boxes d-flex flex-column">
                            <label class="form-selectgroup-item flex-fill">
                                <div class="form-selectgroup-label d-flex align-items-center p-3">

                                    <div class="ms-2">
                                        <div class="font-weight-medium">{{ template.name }}</div>
                                        <div class="text-muted">{{ template.description }}</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="card-body">

                        <div class="form-group mb-3" *ngFor="let templateItem of template!.fields">
                            <label><strong>{{ templateItem.name }}</strong> <span *ngIf="templateItem.required"
                                    class="text-red ms-1">*</span></label>
                            <p>
                                <small>{{ templateItem.display_name }}</small>
                            </p>

                            <editor [disabled]="true" [(ngModel)]="templateItem.content" *ngIf="templateItem.type === 'text'" [init]="{ language: 'de', branding: false, skin: isDark, content_css: isDarkContent }"
                            [apiKey]="'u6v9pmalq7oxl6e4zdd1iw40zjbozz4kkf4fi0fxjbys5u8z'"></editor>

                            <input [disabled]="true" [(ngModel)]="templateItem.content" class="form-control" type="text"
                                *ngIf="templateItem.type === 'string'">
                            <input [disabled]="true" [(ngModel)]="templateItem.content" class="form-control" type="number"
                                *ngIf="templateItem.type === 'number'">

                            <select [disabled]="true" class="form-control" [(ngModel)]="templateItem.content"
                                *ngIf="templateItem.type === 'select'">
                                <option [value]="option" *ngFor="let option of templateItem!.options">{{ option
                                    }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>