export class LabelDto extends Object {
    
    public _id?: string = "";  
    public name: string = "";
    public color: string = "#0054a6";
    public hidden: boolean = false;
  
    public static fromObject(src: any) {
      return Object.assign(new LabelDto(), src);
    }
  }