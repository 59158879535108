<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Störungen</h3>
                        <div class="card-actions">
                            <a [routerLink]="'/admin/incidents/new'" class="btn btn-efs">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus"
                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 5l0 14"></path>
                                    <path d="M5 12l14 0"></path>
                                </svg>
                                Neue Störung erstellen
                            </a>
                        </div>
                    </div>
                    <div class="card-body border-bottom">
                        <div class="d-flex" [formGroup]="filterForm">
                            <div class="ms-auto text-muted">
                                <div class="ms-2 d-inline-block">
                                    <input type="text" class="form-control form-control" placeholder="Suche" size="20" (change)="firstPage()"
                                        type="search" name="search" aria-label="Störungen durchsuchen" formControlName="search">
                                    <input nbInput formControlName="page" type="hidden">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">

                        <div class="alert alert-warning m-3" *ngIf="pageData.data && pageData.data.length === 0">
                            Keine Störungen gefunden!
                        </div>

                        <div class="table-responsive" *ngIf="pageData.data && pageData.data.length > 0">
                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Auswirkung</th>
                                        <th>Beschreibung</th>
                                        <th>Betroffene Apps</th>
                                        <th>Öffentlich</th>
                                        <th>Auf Startseite</th>
                                        <th>Sprache</th>
                                        <th class="w-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let incident of pageData.data">
                                        <td data-label="Status">{{ renderState(incident.state) }}</td>
                                        <td data-label="Auswirkung">{{ renderSeverity(incident.severity) }}</td>
                                        <td data-label="Beschreibung">{{ incident.description }}</td>
                                        <td data-label="Betroffene Apps">{{ renderApps(incident.apps) }}</td>
                                        <td data-label="Öffentlich">{{ incident.public ? 'Ja' : 'Nein' }}</td>
                                        <td data-label="Auf Startseite">{{ incident.publishOnLandingpage ? 'Ja' : 'Nein' }}</td>
                                        
                                        <td data-label="Sprache" *ngIf="incident.language === 'de'">Deutsch</td>
                                        <td data-label="Sprache" *ngIf="incident.language === 'hu'">Ungarisch</td>
                                        <td data-label="Sprache" *ngIf="incident.language === ''">Keine Angabe</td>
                                        
                                        <td data-label="Aktionen">

                                            <div class="btn-list flex-nowrap">
                                                <button class="btn" [routerLink]="['/admin/incidents', incident._id]"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit me-0 pr-0" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                    <path d="M16 5l3 3"></path>
                                                 </svg></button>
                                                <button class="btn" (click)="deleteIncident(incident)"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash-x me-0 pr-0" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 7h16"></path>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                    <path d="M10 12l4 4m0 -4l-4 4"></path>
                                                 </svg></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}" [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{ pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}" [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter 
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>