import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  isAdmin = false;

  constructor(
    protected router: Router,
    protected oidcSecurityService: OidcSecurityService
  ) { }

  ngOnInit(): void {

    this.oidcSecurityService.userData$.subscribe(userData => {
      if (userData.userData.role.includes('efsticket_view')) {
        this.isAdmin = true;

        if (this.router.url === '/' || this.router.url.startsWith('/?code=')) {
          this.router.navigate(['/admin/dashboard']);
        }
      }
    });
  }

  requestChange() {
    this.router.navigate(['/ticket/new'], {queryParams: { mode: 'feature_request' }});
  }

  requestHelp() {
    this.router.navigate(['/ticket/new'], {queryParams: { mode: 'support_request' }});
  }

  reportIssue() {
    this.router.navigate(['/ticket/new'], {queryParams: { mode: 'bug_report' }});
  }
}
