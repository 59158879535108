import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import Swal from 'sweetalert2';
import * as Sentry from "@sentry/angular";
import { TicketOverviewDto } from './dtos/ticket-overview.dto';
import { PreloaderService } from './services/preloader.service';
import { CheckAuthService } from './services/check-auth.service';
import { Subject, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';
import { Select2SearchEvent, Select2UpdateEvent, Select2UpdateValue } from 'ng-select2-component';
import { NotificationDto } from './dtos/notification.dto';
import { asyncForEach, showError } from './helper/utils.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'efs-ticket-frontend';
  userData: any;
  isAdmin: boolean = false;
  authenticated: boolean = false;
  isLoading = true;
  ticketOverviewData: TicketOverviewDto | undefined;

  showNavbar: boolean = true;
  showSearchbar: boolean = true;

  notifications: NotificationDto[] = [];
  modalNotifications: NotificationDto[] = [];

  tickets: any = [];
  mergableTicketSearch: string = "";
  mergeTicketId: string = "";
  searchEvent = new Subject<string | undefined>();
  search = "";
  searchMode = 'all';
  searchType = 'none';
  token = "";

  userCheckSuccess: boolean = false;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public checkAuthService: CheckAuthService,
    public preloader: PreloaderService,
  ) { }

  ngOnInit(): void {

    this.router.events.subscribe((val: any) => {
      if (val.id === 1 && val.type === 1 && val.url.includes('/admin/attachment/')) {
        this.showSearchbar = false;
      }
    });

    this.oidcSecurityService.checkAuth().subscribe({
      next: (loginResponse) => {
        console.log('EFS Login');
        console.log('Config-ID: ', loginResponse.configId);
        console.log('Error Message: ', loginResponse.errorMessage);
        console.log('IsAuthenticated: ', loginResponse.isAuthenticated);

        if (!loginResponse.isAuthenticated) {
          sessionStorage.clear();
          this.oidcSecurityService.logoffAndRevokeTokens();
          this.oidcSecurityService.authorize();
        }

        this.authenticated = loginResponse.isAuthenticated;

        if (loginResponse.userData !== null) {
          this.userData = loginResponse.userData;

          // Check if the OIDC User is known to the ticket api service
          this.checkAuthService.check(loginResponse.accessToken).subscribe({
            next: (check) => {
              this.userCheckSuccess = true;
              this.isLoading = false;
              this.token = loginResponse.accessToken;
              // Notify the parent component that the authentication is finished
              // The preloader prevents the ticket api service from getting multiple requests before the user is kown
              this.preloader.finishedPreloading();

              if (this.userData.role.includes('efsticket_view')) {
                this.isAdmin = true;
                this.loadAdminData(loginResponse.accessToken);

                // Reload data every 2 minutes
                setInterval(() => {
                  this.loadAdminData(loginResponse.accessToken);
                }, 1000 * 60 * 2);

                this.preloader.refreshSidebar.subscribe({
                  next: () => {
                    this.loadAdminData(loginResponse.accessToken);
                  }
                });

                this.preloader.hideNavElements.subscribe({
                  next: () => {
                    this.showNavbar = false;
                  }
                });

                this.searchEvent
                  .pipe(
                    debounceTime(500),
                    distinctUntilChanged(),
                    tap(() => {
                      this.tickets = [{ value: "", label: "Lade..." }];
                    }),
                    switchMap((searchQuery) => this.checkAuthService.getMyTicketsUnpagedAllTypes(loginResponse.accessToken, searchQuery!))
                  ).subscribe((results) => {
                    this.tickets = results.data.map(el => {
                      return {
                        value: el._id,
                        label: `${el.ticket_number} - ${el.subject}`,
                        data: el
                      }
                    })

                    if (results.data.length === 0) {
                      this.tickets = [{ value: "", label: "Kein passendes Anliegen gefunden!", data: null }];
                    }
                  });

              }
            },
            error: (error) => {
              this.isLoading = false;
              showError(error);
            }
          });

          Sentry.setUser({
            username: this.userData.preferred_username,
          })
        }
      },
      error: () => {
        sessionStorage.clear();
        this.oidcSecurityService.logoffAndRevokeTokens();
        this.oidcSecurityService.authorize();
      },
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['search']) {
        this.search = params['search'];
      }

      if (params['type']) {
        this.searchType = params['type'];
      }

      if (params['filter']) {
        this.searchMode = params['filter'];
      }
    });
  }

  logout() {
    Swal.fire({
      title: 'Wirklich ausloggen?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('EFS Logout');
        this.oidcSecurityService.logoff().subscribe({
          next: () => {
            console.log('Logged out');
          },
          error: (error) => {
            console.log(error);
          }
        });
      }
    })
  }

  async markAllNotificationAsRead() {
    await asyncForEach(this.notifications.filter((el) => !el.read), async (notification: NotificationDto) => {
      this.markNotificationAsRead(notification);
    });
  }

  markNotificationAsRead(notification: NotificationDto) {
    this.checkAuthService.markNotificationAsRead(notification._id, this.token).subscribe({
      next: () => {
        this.notifications = this.notifications.filter((el) => el._id !== notification._id);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openNotification(notification: NotificationDto) {
    this.checkAuthService.markNotificationAsRead(notification._id, this.token).subscribe({
      next: () => {
        this.notifications = this.notifications.filter((el) => el._id !== notification._id);

        if (notification.target_type === 'ticket' && !notification.target_sub_id) {
          this.router.navigate(['/admin/tickets', notification.target_id]);
        }

        if (notification.target_type === 'ticket' && notification.target_sub_id) {
          this.router.navigate(['/admin/tickets', notification.target_id], { fragment: notification.target_sub_id });
        }
      },
      error: (error) => {
        console.log(error);
        if (notification.target_type === 'ticket' && !notification.target_sub_id) {
          this.router.navigate(['/admin/tickets', notification.target_id]);
        }

        if (notification.target_type === 'ticket' && notification.target_sub_id) {
          this.router.navigate(['/admin/tickets', notification.target_id], { fragment: notification.target_sub_id });
        }
      }
    });
  }

  startSearch(event: any) {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && this.search !== '') {
      this.router.navigate(['/admin/search'], { queryParams: { search: this.search, type: this.searchType, filter: this.searchMode } });
    }
  }

  selectTicket(event: Select2UpdateEvent<Select2UpdateValue>) {
    if (event.value) {
      this.mergeTicketId = event.value as string;
      this.router.navigate(['/admin/tickets', this.mergeTicketId]);
    }
  }

  updateSearchTickets(event: Select2SearchEvent<Select2UpdateValue>) {
    if (event.search && event.search !== '') {
      this.searchEvent.next(event.search);
    }
  }

  loadAdminData(token: string) {
    this.checkAuthService.getTicketOverview(token).subscribe({
      next: (data) => {
        this.ticketOverviewData = data;
      },
      error: (error) => {
        console.log(error);
      }
    });

    this.checkAuthService.getMyNotifications(token).subscribe({
      next: (data) => {
        this.notifications = data.data.filter((el) => el.type === undefined || el.type === 'systemNotification');
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (!document.hidden) {
      this.checkAuthService.getMyNotifications(this.token).subscribe({
        next: (data) => {
          this.notifications = data.data.filter((el) => el.type === undefined || el.type === 'systemNotification');
          this.fireModalNotifications(data.data.filter((el) => el.type === undefined || el.type === 'modalNotification'));
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }

  async fireModalNotifications(notifications: NotificationDto[]) {
    await asyncForEach(this.modalNotifications, async (notification: NotificationDto) => {
      await Swal.fire({
        title: 'Neue Benachrichtigung!',
        html: notification.text,
        icon: 'info',
        confirmButtonText: 'Okay, gelesen',
        cancelButtonText: 'Beim nächsten Mal erneut anzeigen',
        showCancelButton: true,
        width: '80%',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.checkAuthService.markNotificationAsRead(notification._id, this.token).subscribe({
            next: () => {
              this.modalNotifications = this.modalNotifications.filter((el) => el._id !== notification._id);
            },
            error: (error) => {
              console.log(error);
            }
          })
        }
      });
    });
  }
}
