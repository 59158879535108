export class IncidentHistoryDto extends Object {  
    public _id?: string = "";
    public state: string = "";
    public severity: string = "";
    public publishOnLandingpage: boolean = false;
    public announceToTickets: boolean = false;
    public public: boolean = false;
    public created_at?: Date = new Date();
    public updated_at?: Date = new Date();
    public description: string = "";
    public text: string = "";
    public author_user_sso_id?: string = "";
    public author_name?: string = "";
    public author_email?: string = "";
  
    public static fromObject(src: any) {
      return Object.assign(new IncidentHistoryDto(), src);
    }
  }