import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({providedIn: 'root'})
export class TicketBaseService {

  constructor(
    public http: HttpClient,
    public oidcSecurityService: OidcSecurityService,
  ) {
    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token()}`,
      }),
    };
  
    this.httpOptionsDownload = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token()}`,
      }),
      responseType: 'arraybuffer'
    };
    
  }

  token(): string {
    if (this.oidcSecurityService) {
      let accessToken = '';

    this.oidcSecurityService.getAccessToken().pipe(take(1)).subscribe(token => {
      accessToken = token;
    })

    return accessToken;
    } else {
      return 'empty';
    }
  }

  checkTokenExpiry(): boolean {
    const token = this.token();
    const parsedToken = jwtDecode(token);

    const expiry = new Date((parsedToken as any).exp * 1000);
    const expiryToCheck = new Date(expiry.getTime() - 5 * 60000);
    const now = new Date();

    if (expiryToCheck < now) {
      return false;
    }

    return true;
  }

  apiUrl(endpoint: string): string {
    const host: string = environment.apiEndpoint;
    const url = `${host}/${endpoint}`;
    return url;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `Bearer null`,
    }),
  };

  httpOptionsDownload = {
    headers: new HttpHeaders({
      'Authorization': `Bearer null`,
    }),
    responseType: 'arraybuffer'
  };
}
