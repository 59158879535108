import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, debounceTime, delay, distinctUntilChanged, Observable, of, share, startWith, Subject, switchMap, take, tap } from 'rxjs';
import { GroupDto } from '../../dtos/group.dto';
import { TicketDto } from '../../dtos/ticket.dto';
import { showError } from '../../helper/utils.helper';
import { Page } from '../../models/page.model';
import { TicketApiService } from '../../services/ticket-api.service';
import { UserDto } from '../../dtos/user.dto';
import { LabelDto } from '../../dtos/label.dto';
import { PreloaderService } from '../../services/preloader.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Title } from '@angular/platform-browser';
import { Select2Data, Select2SearchEvent, Select2UpdateEvent, Select2UpdateValue } from 'ng-select2-component';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketsComponent implements OnInit {

  pageTitle = 'Anliegen';
  loading = true;
  filterForm: FormGroup;
  pageDataObserver: (Observable<Page<TicketDto>> | Observable<any>) | undefined;
  pageDataError$ = new Subject<any>();
  showMyTickets: boolean = false;
  groups: GroupDto[] = [];
  groupsSelect2: Select2Data = [];
  groupsSelect2Data: string[] = [];
  users: UserDto[] = [];

  labels: LabelDto[] = [];
  labelsSelect2: Select2Data = [];
  labelSelect2Data: string[] = [];

  visibleLabels: LabelDto[] = [];

  selectedLabel = localStorage.getItem('efsticket_selected_label') ? localStorage.getItem('efsticket_selected_label') : '';

  innerWidth: number = window.innerWidth;

  filter = '';
  filterValue = '';
  searchTerm = '';
  searchFilter = '';
  groupFilter = '';
  type = 'support_request';
  labelFilterActive = false;

  sort = {
    key: 'ticket_number',
    order: 'desc',
  }

  page = 1;

  openOnly = false;
  userData: any;

  interval: NodeJS.Timeout | undefined;

  filtersSelect2Data: string[] = [];
  filtersSelect2: Select2Data = [
    {
      value: 'open',
      label: 'Offen',
      templateId: 'template',
      data: { color: '#0054a6', filterKey: 'status' },
    },
    {
      value: 'closed',
      label: 'Geschlossen',
      templateId: 'template',
      data: { color: '#667382', filterKey: 'status' },
    },
    {
      value: 'answered',
      label: 'Beantwortet',
      templateId: 'template',
      data: { color: '#2fb344', filterKey: 'status' },
    },
    {
      value: 'in_progress',
      label: 'In Bearbeitung',
      templateId: 'template',
      data: { color: '#2fb344', filterKey: 'status' },
    },
    {
      value: 'more_information_needed',
      label: 'Weitere Info. benötigt',
      templateId: 'template',
      data: { color: '#f76707', filterKey: 'status' },
    },
    {
      value: 'escalated',
      label: 'Eskaliert',
      templateId: 'template',
      data: { color: '#d63939', filterKey: 'internal_status' },
    },
    {
      value: 'merged',
      label: 'Zusammengeführt',
      templateId: 'template',
      data: { color: '#17a2b8', filterKey: 'internal_status' },
    },
    {
      value: 'auto_closed_time',
      label: 'Automatisch geschlossen',
      templateId: 'template',
      data: { color: '#4299e1', filterKey: 'internal_status' },
    },
    {
      value: 'mail',
      label: 'Quelle: Mail',
      templateId: 'template',
      data: { color: '#667382', filterKey: 'source' },
    },
    {
      value: 'landingpage',
      label: 'Quelle: Support-System',
      templateId: 'template',
      data: { color: '#667382', filterKey: 'source' },
    },
  ];

  constructor(
    private ticketApi: TicketApiService,
    private router: Router,
    private preloaderService: PreloaderService,
    public oidcSecurityService: OidcSecurityService,
    protected titleService: Title
  ) {
    this.filterForm = new FormGroup({
      search: new FormControl(),
      page: new FormControl(1),
      statusFilters: new FormControl(
        this.router.url.endsWith('/tickets') ||
        this.router.url.endsWith('/bugreports') ||
        this.router.url.endsWith('/featurerequests') ? ['open'] : []
      ),
      groupFilters: new FormControl([]),
      labelFilters: new FormControl([]),
    });

    // this.loadFilter();

    this.titleService.setTitle(`EFSsupport System`);
    // Reset the filter for pages which show all tickets
    if (
      (this.router.url.endsWith('/tickets/my')) &&
      this.filter === 'status' && this.filterValue === 'open'
    ) {
      this.filter = '';
      this.filterValue = '';
    }

    if (
      this.router.url.endsWith('/tickets') ||
      this.router.url.endsWith('/bugreports') ||
      this.router.url.endsWith('/featurerequests')
    ) {
      this.filter = 'status';
      this.filterValue = 'open';
    }


    if (this.router.url.includes('bugreports')) {
      this.type = 'bug_report';
      this.pageTitle = 'Bug-Reports';
    }

    if (this.router.url.includes('featurerequests')) {
      this.type = 'feature_request';
      this.pageTitle = 'Feature-Wünsche';
    }

    const params = this.router.parseUrl(this.router.url).queryParams;

    if (params['label']) {
      this.type = 'none';
      this.resetFilter();
      this.setLabel(params['label'], false);
      this.labelFilterActive = true;
    }

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.preloaderService.dataLoaded.subscribe({
      next: () => {
        this.pageDataObserver = this.filterForm.valueChanges.pipe(
          share(),
          debounceTime(1000),
          startWith(this.filterForm.value),
          distinctUntilChanged((a, b) => {
            const aObj = {
              search: a.search,
              page: a.page,
              statusFilters: a.statusFilters,
              groupFilters: a.groupFilters,
              labelFilters: a.labelFilters,
            };

            const bObj = {
              search: b.search,
              page: b.page,
              statusFilters: b.statusFilters.map((f: string) => { return this.filtersSelect2.find((el: any) => el.value === f)}),
              groupFilters: b.groupFilters.map((f: string) => { return this.groupsSelect2.find((el: any) => el.value === f)}),
              labelFilters: b.labelFilters.map((f: string) => { return this.labelsSelect2.find((el: any) => el.value === f)}),
            };

            return JSON.stringify(aObj) === JSON.stringify(bObj);
          }),
          tap(() => this.loading = true),
          switchMap(urlOrFilter => {
            urlOrFilter.sort = this.sort.key;
            urlOrFilter.sortOrder = this.sort.order;

            if (this.filter !== '') {
              urlOrFilter.filter = this.filter;
              urlOrFilter.filterValue = this.filterValue;
            }

            if (this.selectedLabel !== '') {
              urlOrFilter.label = this.selectedLabel;
            }

            if (this.searchFilter !== '') {
              urlOrFilter.searchFilter = this.searchFilter;
            }

            if (this.groupFilter !== '') {
              urlOrFilter.groupFilter = this.groupFilter;
            }

            if (urlOrFilter.statusFilters && urlOrFilter.statusFilters.length > 0) {
              urlOrFilter.statusFilters = urlOrFilter.statusFilters.map((f: string) => { return this.filtersSelect2.find((el: any) => el.value === f)});
            }

            if (urlOrFilter.labelFilters && urlOrFilter.labelFilters.length > 0) {
              urlOrFilter.labelFilters = urlOrFilter.labelFilters.map((f: string) => { return this.labelsSelect2.find((el: any) => el.value === f)});
            }

            if (urlOrFilter.groupFilters && urlOrFilter.groupFilters.length > 0) {
              urlOrFilter.groupFilters = urlOrFilter.groupFilters.map((f: string) => { return this.groupsSelect2.find((el: any) => el.value === f)});
            }

            urlOrFilter.type = this.type;

            // this.saveFilter();

            if (this.router.url.endsWith('/my')) {
              return this.ticketApi.getMyAdminTickets(urlOrFilter)
            } else {
              return this.ticketApi.getAllTickets(urlOrFilter)
            }
          }),
          catchError((error) => {
            showError(error);
            this.loading = false;
            return of();
          }),
          tap(() => this.loading = false),
        );

        this.interval = setInterval(() => {
          this.reloadCurrentPage();
        }, 120 * 1000);

        this.filterForm.get('search')?.valueChanges.subscribe(val => {
          // Reset the time when searching to prevent reload while typing
          window.clearInterval(this.interval);
          this.interval = setInterval(() => {
            this.reloadCurrentPage();
          }, 120 * 1000);

          this.firstPage();
        });

        this.ticketApi.getLabels().subscribe({
          next: (labels) => {
            this.labels = labels;
            this.visibleLabels = labels.filter(l => !l.hidden);

            this.labelsSelect2 = labels.map(g => {
              return {
                value: g._id!,
                label: g.name!,
                templateId: 'template',
                data: { color: g.color, filterKey: 'labels' },
              }
            });
          },
          error: (error) => {
            showError(error);
          }
        });

        this.ticketApi.getGroups().subscribe(groups => {
          this.groups = groups;

          this.groupsSelect2 = groups.map(g => {
            return {
              value: g._id!,
              label: g.name!,
              data: { filterKey: 'group_id' },
            }
          });
        });

        this.ticketApi.getSupportUsers().subscribe(users => {
          this.users = users;
        });

      },
      error: (err) => {
        this.loading = false;
        showError(err);
      },
    });
  }

  setSearchFilter(filter: string) {
    this.searchFilter = filter;
    this.saveFilter();
    this.firstPage();
  }

  setFilter(key: string, filter: string) {

    if (filter === '' && this.openOnly) {
      filter = 'open';
      key = 'status';
    }

    this.filter = key;
    this.filterValue = filter;
    this.saveFilter();
    this.firstPage();
  }

  setLabel(label: string, save = true) {
    this.selectedLabel = label;
    if (save) {
      this.saveFilter();
    }
    this.firstPage();
  }

  setGroup(groupId: string) {
    this.groupFilter = groupId;
    this.saveFilter();
    this.firstPage();
  }

  order(key: string, order: string) {
    this.sort.key = key;
    this.sort.order = order;
    this.saveFilter();
    this.firstPage();
  }

  nextPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current + 1 });
  }

  previousPage() {
    const current = this.filterForm.controls['page'].value;
    if (current > 1) {
      this.filterForm.patchValue({ page: current - 1 });
    }
  }

  reloadCurrentPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current });
  }

  firstPage() {
    this.filterForm.patchValue({ page: 1 });
  }

  ngOnInit(): void {
    if (this.router.url === '/admin/tickets/my') {
      this.showMyTickets = true;
    }

    /* if (this.router.url === '/admin/tickets/open') {
      this.setFilter('status', 'open');
    } */

    if (this.router.url === '/admin/tickets/inprogress') {
      this.setFilter('status', 'in_progress');
    }

    if (this.router.url === '/admin/tickets/waiting') {
      this.setFilter('status', 'more_information_needed');
    }

    if (this.router.url === '/admin/tickets/escalated') {
      this.setFilter('internal_status', 'escalated');
    }

    if (this.router.url === '/admin/tickets/mail') {
      this.setFilter('source', 'mail');
    }

    if (this.router.url === '/admin/tickets/landingpage') {
      this.setFilter('source', 'landingpage');
    }
  }

  getGroupName(id: string): string {
    const group = this.groups.find(g => g._id === id);
    if (group) {
      return group.name;
    }
    return '';
  }

  getUserName(id: string): string {
    const group = this.users.find(g => g.user_sso_id === id);
    if (group) {
      return group.name;
    }
    return '';
  }

  getLabel(id: string): LabelDto {
    const label = this.labels.find(g => g._id === id);
    if (label) {
      return label;
    }
    return {
      _id: '',
      name: '',
      color: '',
      hidden: false,
    };
  }

  resetFilter() {
    this.filter = '';
    this.filterValue = '';
    this.selectedLabel = '';
    this.searchFilter = '';
    this.searchTerm = '';
    this.groupFilter = '';
    this.sort.key = 'ticket_number';
    this.sort.order = 'desc';

    if (this.openOnly) {
      this.filterValue = 'open';
      this.filter = 'status';
    }

    this.saveFilter();
    this.firstPage();
  }

  loadFilter() {
    if (this.router.url !== '/admin/tickets/open') {
      this.filter = localStorage.getItem('efsticket_filter') !== null ? localStorage.getItem('efsticket_filter')! : '';
      this.filterValue = localStorage.getItem('efsticket_filterValue') !== null ? localStorage.getItem('efsticket_filterValue')! : '';
    } else {
      this.filter = 'status';
      this.filterValue = 'open';
    }

    this.groupFilter = localStorage.getItem('efsticket_groupFilter') !== null ? localStorage.getItem('efsticket_groupFilter')! : '';

    this.sort = {
      key: localStorage.getItem('efsticket_sort_key') !== null ? localStorage.getItem('efsticket_sort_key')! : 'ticket_number',
      order: localStorage.getItem('efsticket_sort_order') !== null ? localStorage.getItem('efsticket_sort_order')! : 'desc',
    }
  }

  saveFilter() {
    if (this.router.url !== '/admin/tickets/open') {
      localStorage.setItem('efsticket_filter', this.filter!);
      localStorage.setItem('efsticket_filterValue', this.filterValue!);
    }

    localStorage.setItem('efsticket_sort_key', this.sort.key!);
    localStorage.setItem('efsticket_sort_order', this.sort.order!);
    localStorage.setItem('efsticket_selected_label', this.selectedLabel!);
    localStorage.setItem('efsticket_groupFilter', this.groupFilter!);
  }

  // Disable the label filter and load the data matching the current page
  disableLabelFilter() {
    this.router.navigate([], {
      queryParams: {
        'label': null,
      },
    });
    this.labelFilterActive = false;
    this.resetFilter();

    this.type = 'support_request';
    if (this.router.url.includes('bugreports')) {
      this.type = 'bug_report';
      this.pageTitle = 'Bug-Reports';
    }

    if (this.router.url.includes('featurerequests')) {
      this.type = 'feature_request';
      this.pageTitle = 'Feature-Wünsche';
    }

    this.firstPage();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
  }

/*   updateFilterSelect2(event: Select2UpdateEvent<Select2UpdateValue>) {
    console.log('1');
    // this.reloadCurrentPage();
  }

  updateLabelSelect2(event: Select2UpdateEvent<Select2UpdateValue>) {
    console.log('2');
    this.reloadCurrentPage();
  }

  updateGroupSelect2(event: Select2UpdateEvent<Select2UpdateValue>) {
    console.log('3');
    this.reloadCurrentPage();
  } */
}

