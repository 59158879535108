import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiBaseService {

  constructor(
    public http: HttpClient,
    public oidcSecurityService: OidcSecurityService,
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token()}`,
      }),
    };
  }

  token(): string {
    let accessToken = '';
    this.oidcSecurityService.getAccessToken().pipe(take(1)).subscribe(token => {
      accessToken = token;
    })
    return accessToken;
  }

  apiUrl(endpoint: string): string {
    const host: string = environment.usermgmtEndpoint;
    const url = `${host}/${endpoint}`;
    return url;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': `Bearer null`,
    }),
  };
}
