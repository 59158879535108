import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function asyncForEach(array: any, callback: any): Promise<void> {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function showError(error: any) {
    let title = '';
    let text = '';
    let footer = '';

    if (error && error.message && error.message === `Token expired` || error.message === `Invalid token specified`) {
        sessionStorage.clear();
        window.location.reload();
        return;
    }

    if (error.error && error.error.error) {
        // API Error

        if (error.error.error === 'Forbidden' && error.error.message === 'Forbidden resource') {
            // Forbidden
            title = 'Zugriff verweigert';
            text = 'Sie haben keine Berechtigung auf diese Ressource zuzugreifen!';
            footer = 'Bitte wenden Sie sich an den Administrator.';
        } else if (error.error.error === 'Not found' && error.error.message === 'User not found') {
            // Forbidden
            title = 'Benutzer nicht gefunden';
            text = 'Der gewünschte Benutzer konnte nicht gefunden werden.';
            footer = '';
        } else if (error.error.error === 'Error' && error.error.message === 'Ticket type already set') {
            // Forbidden
            title = 'Anfrage-Typ bereits gesetzt';
            text = 'Die Anfrage ist bereits vom gewünschten Typ!';
            footer = '';
        } else if (error.error.error === 'Login failed' && error.error.message === 'Provided credentials are invalid!') {
            title = 'Login fehlgeschlagen';
            text = 'Die angegebenen Login-Daten sind ungültig!';
            footer = '';
        } else if (error.error.error === 'Forbidden' && error.error.message === 'The user matching the access token is banned!') {
            title = 'Account gesperrt';
            text = 'Ihr Benutzer wurde von einem Administrator gesperrt!';
            footer = '';
        } else if (error.error.error === 'Unauthorized' && error.error.message === 'Unauthorized. Could not verify roles.') {
            title = 'Fehlende Berechtigung';
            text = 'Ihr Benutzeraccount verfügt nicht über die nötigen Berechtigungen um diese Aktion auszuführen!';
            footer = '';
        } else if (error.error.error === 'Forbidden') {
            title = 'Fehlende Berechtigung';
            text = 'Ihr Benutzeraccount verfügt nicht über die nötigen Berechtigungen um diese Aktion auszuführen!';
            footer = error.error.message;
        } else if (error.error.error === 'Error' && error.error.message === 'File type not allowed') {
            title = 'Unzulässiger Dateityp';
            text = 'Der gewählte Dateityp ist nicht zulässig!';
            footer = '';
        } else if (error.error.error === 'Error' && error.error.message === 'File size too big') {
            title = 'Datei zu groß!';
            text = 'Die Datei übersteigt die maximale Größe von 100MB!';
            footer = '';
        } else if (error.error.error === 'Bad request' && error.error.message === 'The user does not have an email address. An email address is mandatory for the use of this system.') {
            title = 'Ungültige Account-Daten!';
            text = 'Ihr Benutzerkonto hat keine E-Mail Adresse. Eine E-Mail Adresse ist notwenidg für die Nutzung dieses Systems. Bitte wenden Sie sich an support@efs-ag.com.';
            footer = '';
        } else if (error.error.error === 'User validation failed: email: Path `email` is required.') {
            title = 'Ungültige Account-Daten!';
            text = 'Ihr Benutzerkonto hat keine E-Mail Adresse. Eine E-Mail Adresse ist notwenidg für die Nutzung dieses Systems. Bitte wenden Sie sich an support@efs-ag.com.';
            footer = '';
        } else if (error.error.error === 'Unauthorized' && error.error.message === 'The provided access token is invalid!') {
            // window.location.reload();
        } else {

            console.log(error.error.error);
            Sentry.captureException(new Error(JSON.stringify(error.error.error)));

            title = 'Unbekannter Fehler';
            text = 'Bei der Ausführung ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es in einigen Minuten erneut.';
            footer = `Fehler: ${error.error.error ? error.error.error : 'Unbekannter Fehler'} - Meldung: ${error.error.message ? error.error.message : 'Unbekannte Meldung'}`;
        }

    } else {

        console.log(error);
        Sentry.captureException(new Error(JSON.stringify(error)));

        // Generic Error
        if (error.status === 0 && error.statusText === 'Unknown Error') {
            // Forbidden
            title = 'Fehler beim Laden';
            text = 'Die Daten konnten nicht geladen werden. Bitte versuchen Sie es in einigen Minuten erneut.';
            footer = 'Keine Verbindung zur Schnittstelle';
        } else {
            title = 'Unbekannter Fehler';
            text = 'Bei der Ausführung ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es in einigen Minuten erneut.';
            footer = `Fehler: ${error.status ? error.status : 'Unbekannter Fehler'} - Meldung: ${error.statusText ? error.statusText : 'Unbekannte Meldung'}`;
        }
    }

    Swal.fire({
        icon: 'error',
        title,
        text,
        footer
    })
}

export function waitForElm(selector: any) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}