import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { showError } from '../../helper/utils.helper';
import { TicketApiService } from '../../services/ticket-api.service';
import { PreloaderService } from '../../services/preloader.service';
import { TicketAnswerDto } from '../../dtos/ticket-answer.dto';
import { ScrollModeType } from 'ngx-extended-pdf-viewer';
import { TicketAnswerAttachmentDto } from '../../dtos/ticket-answer-attachment.dto';
import { Meta, Title } from '@angular/platform-browser';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-attachment-show',
  templateUrl: './attachment-show.component.html',
  styleUrls: ['./attachment-show.component.scss']
})
export class AttachmentShowComponent implements OnDestroy {

  loading = true;
  browserLocale = window.navigator.language;

  ticketAnswer_id: string = '';
  attachment_id: string = '';
  ticketAnswer: TicketAnswerDto = new TicketAnswerDto();

  attachment: TicketAnswerAttachmentDto | undefined;

  blobUrl: any;
  pdfBlob: any;
  pdfFilename: string = "";
  showPdf = false;
  showImage = false;
  showVideo = false;
  scrollmode: ScrollModeType = ScrollModeType.vertical;
  
  constructor(
    private ticketApi: TicketApiService,
    protected route: ActivatedRoute,
    private preloaderService: PreloaderService,
    protected metaService: Meta,
    protected titleService: Title
  ) {

    this.preloaderService.hideNavElement();

    this.preloaderService.dataLoaded.subscribe({
      next: () => {

        this.route.params.subscribe(params => {
          if (params['id'] && params['attachment_id']) {
            this.ticketAnswer_id = params['id'];
            this.attachment_id = params['attachment_id'];
            this.loadData();
          }
        });

      },
      error: (err) => {
        this.loading = false;
        showError(err);
      },
    });
  }

  ngOnDestroy(): void {
    if (this.blobUrl) {
      URL.revokeObjectURL(this.blobUrl);
    }
  }

  loadData(): void {
    this.ticketApi.getTicketAnswer(this.ticketAnswer_id).subscribe({
      next: (ticketAnswer) => {
        this.ticketAnswer = ticketAnswer;
        this.attachment = ticketAnswer.attachments.find((attachment: TicketAnswerAttachmentDto) => attachment.slug === this.attachment_id);

        if (this.attachment !== undefined) {

          this.metaService.updateTag({ name: 'title', content: `📁 ${this.attachment.filename} - EFSsupport System` });
          this.titleService.setTitle(`📁 ${this.attachment.filename} - EFSsupport System`);

          this.ticketApi.downloadFile(this.ticketAnswer_id, this.attachment.slug).subscribe({
            next: (data) => {
              if (["image/png", "image/gif", "image/jpeg", "image/*"].includes(this.attachment!.mimetype)) {
                const blob = new Blob([data], { type: this.attachment!.mimetype });
                this.blobUrl = URL.createObjectURL(blob);
                this.showImage = true;
              } else if (this.attachment!.mimetype === "application/pdf") {
                this.pdfBlob = data;
                this.pdfFilename = this.attachment!.filename;
                this.showPdf = true;
              } else if (this.attachment!.mimetype === "video/mp4") {
                const blob = new Blob([data], { type: this.attachment!.mimetype });
                this.blobUrl = URL.createObjectURL(blob);
                this.showVideo = true;
              }
              this.loading = false;
            },
            error: (err) => {
              this.loading = false;
              showError(err);
            },
          });

          /* if (this.attachment.filetype === 'application/pdf') {
            this.showPdf = true;
            this.pdfFilename = this.attachment.filename;
            this.pdfBlob = this.attachment.file;
            this.blobUrl = URL.createObjectURL(this.pdfBlob);
          } */
        }
      },
      error: (err) => {
        showError(err);
      },
    });
  }

  saveAttachment() {
    this.ticketApi.downloadFile(this.ticketAnswer_id, this.attachment!.slug).subscribe({
      next: (data) => {
        saveAs(new Blob([data], { type: this.attachment!.mimetype }), this.attachment!.filename);
      },
      error: (err) => {
        showError(err);
      },
    });
  }
}
