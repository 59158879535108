<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div *ngIf="mode === 'new'">
                            <h2 class="card-title" >Priorität und SLA erstellen</h2>
                            <small class="text-muted">Eskalationszeiten gelten nur während der Support-Öffnungszeiten | Ein Werktag entspricht 8 Arbeitsstunden</small>
                        </div>
                        <div *ngIf="mode === 'edit'">
                            <h2 class="card-title" >Priorität und SLA bearbeiten</h2>
                            <small class="text-muted">Eskalationszeiten gelten nur während der Support-Öffnungszeiten | Ein Werktag entspricht 8 Arbeitsstunden</small>
                        </div>
                    </div>

                    <div class="card-body">

                        <div class="row mb-3">

                            <div class="row ms-2 me-2 mt-2 mb-2" *ngIf="isLoading">
                                <div class="col-12">
                                    <div class="progress mt-2 mb-2">
                                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 mb-3" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Anzeigename</label>
                                    <p class="text-muted">
                                        Aussagekräftiger Name für die Priorität, welcher in der Übersicht angezeigt wird.
                                    </p>
                                    <input type="text" class="form-control" required name="" id="" [disabled]="true"
                                        [(ngModel)]="priority.display_name">
                                </div>
                            </div>

                            <div class="col-6 mb-3" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Eskalationszeit in Stunden</label>
                                    <p class="text-muted">
                                        Anzahl an Stunden nach dem die Eskalation erfolgen soll. Stunden = Arbeitszeit
                                    </p>

                                    <input type="number" class="form-control" required name="" id="" [(ngModel)]="priority.escalation_time">
                                    <p class="text-muted text-small" *ngIf="priority.escalation_time !== 0">
                                        Entspricht: {{ priority.escalation_time | workdays }} Werktage
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card-footer">
                        <button class="btn btn-efs" *ngIf="mode === 'edit'" (click)="save()"
                            [disabled]="isLoading">Speichern</button>
                        <button class="btn btn-efs" *ngIf="mode === 'new'" (click)="create()"
                            [disabled]="isLoading">Anlegen</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>