<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h2 class="card-title">
                                Prioritäten und SLAs
                            </h2>
                            <small class="text-muted">Eskalationszeiten gelten nur während der Support-Öffnungszeiten | Ein Werktag entspricht 8 Arbeitsstunden</small>
                        </div>
                        <div class="card-actions">
                        </div>
                    </div>
                    <div class="card-body border-bottom">
                        <div class="d-flex" [formGroup]="filterForm">
                            <div class="ms-auto text-muted">
                                <div class="ms-2 d-inline-block">
                                    <input type="text" class="form-control form-control" placeholder="Suche" size="20" (change)="firstPage()"
                                        type="search" name="search" aria-label="Label durchsuchen" formControlName="search">
                                    <input nbInput formControlName="page" type="hidden">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">

                        <div class="alert alert-warning m-3" *ngIf="pageData.data && pageData.data.length === 0">
                            Keine Prioritäten und SLAs gefunden!
                        </div>

                        <div class="table-responsive" *ngIf="pageData.data && pageData.data.length > 0">
                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th>Anzeigename</th>
                                        <th>Typ</th>
                                        <th>Zeit in Stunden</th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let priority of pageData.data">
                                        <td data-label="Anzeigename">{{ priority.display_name }}</td>
                                        
                                        <td data-label="Typ" *ngIf="priority.type === 'ticket_priority'">Anliegen-Priorität</td>
                                        <td data-label="Typ" *ngIf="priority.type !== 'ticket_priority'">Sonstige</td>

                                        <td data-label="Zeit in Stunden">{{ priority.escalation_time }} h ({{ priority.escalation_time | workdays }})</td>
                                        
                                        <td data-label="Aktionen">
                                            <button class="btn me-2" [routerLink]="['/admin/priority', priority._id]">Bearbeiten</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}" [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{ pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}" [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter 
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>