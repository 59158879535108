import { Component, OnInit } from '@angular/core';
import { AppDto } from '../../dtos/app.dto';
import { Router } from '@angular/router';
import { Observable, Subject, debounceTime, startWith, delay, tap, switchMap, share, catchError, of } from 'rxjs';
import { TicketDto } from '../../dtos/ticket.dto';
import { showError } from '../../helper/utils.helper';
import { Page } from '../../models/page.model';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { TicketApiService } from '../../services/ticket-api.service';
import { LabelDto } from '../../dtos/label.dto';
import { UserDto } from '../../dtos/user.dto';
import Swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivityDto } from '../../dtos/activity.dto';
import saveAs from 'file-saver';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  loading = true;
  filterForm: FormGroup;
  pageDataObserver: (Observable<Page<ActivityDto>> | Observable<any>) | undefined;
  pageDataError$ = new Subject<any>();
  page = 1;
  users: UserDto[] = [];
  browserLocale = window.navigator.language;

  filter = "";
  filterValue = "";
  search = "";

  sort = {
    key: 'date',
    order: 'desc',
  }

  constructor(
    private ticketApi: TicketApiService,
    private landingpageApi: LandingpageApiService,
    private router: Router,
    private preloaderService: PreloaderService,
  ) {
    this.filterForm = new FormGroup({
      search: new FormControl(),
      page: new FormControl(1),
    });

    this.preloaderService.dataLoaded.subscribe({
      next: () => {
        this.pageDataObserver = this.filterForm.valueChanges.pipe(
          debounceTime(200),
          startWith(this.filterForm.value),
          delay(0),
          tap(() => this.loading = true),
          switchMap(urlOrFilter => {

            urlOrFilter.sort = this.sort.key;
            urlOrFilter.sortOrder = this.sort.order;

            if (this.filter !== '' && this.filterValue !== '') {
              urlOrFilter.filter = this.filter;
              urlOrFilter.filterValue = this.filterValue;
            }

            return this.ticketApi.getActivities(urlOrFilter)
          }),
          share(),
          catchError((error) => {
            showError(error);
            this.loading = false;
            return of();
          }),
          tap(() => this.loading = false),
        );

        this.filterForm.get('search')?.valueChanges.subscribe(val => {
          this.firstPage();
        });
      },
      error: (err) => {
        this.loading = false;
        showError(err);
      },
    });
  }
  ngOnInit(): void {
    this.ticketApi.getSupportUsers().subscribe(users => {
      this.users = users;
    });
  }

  setFilter(filter: string, filterValue: string) {
    this.filter = filter;
    this.filterValue = filterValue;
    this.firstPage();
  }

  nextPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current + 1 });
  }

  previousPage() {
    const current = this.filterForm.controls['page'].value;
    if (current > 1) {
      this.filterForm.patchValue({ page: current - 1 });
    }
  }

  firstPage() {
    this.filterForm.patchValue({ page: 1 });
  }

  openLink(id: string) {
    this.router.navigate(['/admin/tickets', id]);
  }

  export() {
    this.ticketApi.exportActivities(this.search, this.filter, this.filterValue).subscribe({
      next: (data) => {
        const blob = new Blob([data], {type: 'text/csv'});
        saveAs(blob, `activities_export_${new Date().toISOString()}.csv`);
      },
      error: (err) => {
        showError(err);
      },
    });
  }

}
