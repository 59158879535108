<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h2 class="card-title">
                                Suche
                            </h2>
                        </div>
                        <div class="card-actions">
                            <div [formGroup]="filterForm">
                                <input nbInput formControlName="page" type="hidden">
                            </div>
                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">

                        <div class="alert alert-warning m-3" *ngIf="pageData.data && pageData.data.length === 0">
                            Kein Treffer zu der Suche!
                        </div>

                        <div class="table-responsive" *ngIf="pageData.data && pageData.data.length > 0">
                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th class="w-1">ID</th>
                                        <th>Betreff</th>
                                        <th>Autor</th>
                                        <th>Zuständigkeit/Zuordnung</th>
                                        <th>Status / Priorität</th>
                                        <th>Gefunden durch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ticket of pageData.data"
                                        [className]="ticket.data.read_by && !ticket.data.read_by.includes(userData.nickname) ? 'unread link' : 'link'"
                                        [routerLink]="['/admin/tickets/', ticket.data._id]">
                                        <td data-label="Anliegen-ID" class="text-nowrap">
                                            <span class="text-muted">
                                                <span [ngbTooltip]="'Ungelesen, oder neue Antwort vorhanden'"
                                                    class="badge bg-efs me-2"
                                                    *ngIf="ticket.data.read_by && !ticket.data.read_by.includes(userData.nickname)"></span>
                                                <a [routerLink]="['/admin/tickets/', ticket.data._id]">#{{ticket.data.ticket_number
                                                    }}</a>
                                            </span>
                                        </td>
                                        <td data-label="Betreff">{{ ticket.data.subject }}</td>

                                        <td data-label="Autor">
                                            <span
                                                title="{{ ticket.data.author_user_sso_id ? ticket.data.author_user_sso_id : '' }}">{{
                                                ticket.data.author_name ? ticket.data.author_name : 'Name unbekannt'
                                                }}</span>
                                        </td>

                                        <td data-label="Zuständigkeit/Zuordnung">
                                            {{ getGroupName(ticket.data.group_id) }}
                                            <span *ngIf="ticket.data.assigned_to_user_sso_id">({{
                                                getUserName(ticket.data.assigned_to_user_sso_id) }})</span>
                                        </td>

                                        <td data-label="Status / Priorität">
                                            <div class="btn-list flex-nowrap">
                                                <span *ngIf="ticket.data.status === 'open'"
                                                    class="status status-primary nowrap">Offen</span>
                                                <span *ngIf="ticket.data.status === 'closed'"
                                                    class="status status-secondary nowrap">Geschlossen</span>
                                                <span *ngIf="ticket.data.status === 'answered'"
                                                    class="status status-success nowrap">Beantwortet</span>
                                                <span *ngIf="ticket.data.status === 'in_progress'"
                                                    class="status status-success nowrap">In Bearbeitung</span>
                                                <span *ngIf="ticket.data.status === 'more_information_needed'"
                                                    class="status status-warning nowrap">Weitere Info.
                                                    benötigt</span>

                                                <span *ngIf="ticket.data.internal_status === 'merged'"
                                                    [ngbTooltip]="'Das Anliegen wurde mit einem anderen Anliegen zusammengeführt.'"
                                                    class="status status-cyan nowrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-arrow-merge" width="24"
                                                        height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 7l4 -4l4 4"></path>
                                                        <path
                                                            d="M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394">
                                                        </path>
                                                        <path
                                                            d="M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394">
                                                        </path>
                                                    </svg></span>

                                                <span
                                                    [ngbTooltip]="'Das Anliegen wurde vom Benutzer, oder automatisch wegen Inaktivität eskaliert.'"
                                                    *ngIf="ticket.data.internal_status === 'escalated'"
                                                    class="status status-danger nowrap">Eskaliert</span>

                                                <span *ngIf="ticket.data.priority === 'none'" class="status">Keine
                                                    Eskalation</span>
                                                <span *ngIf="ticket.data.priority === 'low'"
                                                    class="status">Niedrig</span>
                                                <span *ngIf="ticket.data.priority === 'medium'"
                                                    class="status status-secondary">Mittel</span>
                                                <span *ngIf="ticket.data.priority === 'high'"
                                                    class="status status-warning">Hoch</span>
                                                <span *ngIf="ticket.data.priority === 'asap'"
                                                    class="status status-danger">ASAP</span>
                                            </div>
                                        </td>

                                        <td data-label="Gefunden durch">
                                            <ul class="mb-0 p-0">
                                                <li style="list-style: none;" *ngFor="let hit of ticket.hits">
                                                    <strong class="me-2">{{ hit.key }}:</strong>
                                                    <code [innerHTML]="hit.value"></code>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage < pageData.maxPages() || pageData.currentPage === 1 ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage < 1 || pageData.currentPage === 1"
                                        (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{
                                        pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>