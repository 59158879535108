import { Injectable } from "@angular/core";
import * as RecordRTC from "recordrtc";
import moment from "moment";
import { Observable, Subject } from "rxjs";

interface RecordedVideoOutput {
  blob: Blob;
  title: string;
  duration: number;
}

@Injectable()
export class VideoRecordingService {
  private stream!: MediaStream | null;
  private recorder!: RecordRTC | null;
  private interval!: NodeJS.Timeout;
  private startTime!: moment.Moment | null;
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next("Warte auf Berechtigung ...");
    navigator.mediaDevices.getDisplayMedia({
      video: true
    }).then(s => {

      // Combine audio with video
      navigator.mediaDevices.getUserMedia({ audio: true }).then(audioStream => {
        s.addTrack(audioStream.getTracks()[0]);

        this._recordingTime.next("00:00");
        this.stream = s;

        this.stream.getVideoTracks()[0].onended = () => {
          if (this.recorder?.getState() === "recording") {
            this.stopRecording();
          }
        };

        this.record();

      }).catch(error => {
        this._recordingTime.next("Fehler beim Aufnehmen!");
        this._recordingFailed.next(error);
      });
    }).catch(error => {
      this._recordingTime.next("Fehler beim Aufnehmen!");
      this._recordingFailed.next(error);
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {

    this.recorder = new RecordRTC(this.stream!, {
      type: "video",
      mimeType: "video/mp4"
    });

    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ":" + this.toString(diffTime.seconds());
      this._recordingTime.next(time);
    }, 1000);
  }

  private toString(value: any) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        if (this.startTime) {
          const videoName = encodeURIComponent(
            "video_" + new Date().getTime() + ".mp4"
          );

          const currentTime = moment();
          const diffTime = moment.duration(currentTime.diff(this.startTime));
          this._recorded.next({ blob: this.recorder?.getBlob()!, title: videoName, duration: diffTime.asSeconds() });
          this.stopMedia();
        }
      }
      );
    }
  }

  private stopMedia() {
    this.stream?.getTracks().forEach(track => track.stop());

    if (this.recorder) {
      this.recorder.destroy();
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
    }
  }
}
