import { Component, OnInit } from '@angular/core';
import { IncidentDto } from '../../dtos/incident.dto.ts';
import { AppDto } from '../../dtos/app.dto';
import { IncidentHistoryDto } from '../../dtos/incident-history.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import Swal from 'sweetalert2';
import { TicketApiService } from '../../services/ticket-api.service';
import { showError } from '../../helper/utils.helper';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { PreloaderService } from '../../services/preloader.service';

import Litepicker from 'litepicker';
import CustomBuildEditor from 'src/ckeditor5/build/ckeditor';

@Component({
  selector: 'app-incidents-edit',
  templateUrl: './incidents-edit.component.html',
  styleUrls: ['./incidents-edit.component.scss']
})
export class IncidentEditComponent implements OnInit {

  incidentId: string | undefined;
  mode = 'new';

  incident: IncidentDto = new IncidentDto();

  newUpdate: IncidentHistoryDto = new IncidentHistoryDto();

  apps: AppDto[] = [];

  startPicker: Litepicker | undefined;
  endPicker: Litepicker | undefined;

  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  public editor = CustomBuildEditor;
  editorConfig: any = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'fontSize',
        'fontColor',
        '|',
        'outdent',
        'indent',
        'alignment',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'codeBlock',
        'code'
      ]
    },
    link: {
      decorators: {
        isExternal: {
          mode: 'automatic',
          callback: (url: any) => url.startsWith('http://') || url.startsWith('https://') || url.startsWith('www'),
          attributes: {
            target: '_blank'
          }
        }
      }
    },
    language: 'de',
  };

  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    protected route: ActivatedRoute,
    protected landingpageService: LandingpageApiService,
    protected router: Router,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        if (params['id'] === 'new') {
          this.mode = 'new';
        } else {
          this.mode = 'edit';
          this.incidentId = params['id'];

          this.preloaderService.dataLoaded.subscribe({
            next: () => {
              this.loadData();
            },
            error: (err) => {
              showError(err);
            },
          });

        }
      }
    })

    this.landingpageService.getMyAppsAll().subscribe({
      next: (data) => {
        this.apps = data;
      },
      error: (error) => {
        showError(error);
      }
    })

    this.startPicker = new Litepicker({
      element: document.getElementById('start')!,
      format: 'DD.MM.YYYY',
      lang: 'de-DE'
    });

    this.startPicker.on('selected', (date1: any) => {
      this.incident.startReadable = date1.dateInstance.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
      this.incident.start = date1.dateInstance.toISOString();
    });

    this.endPicker = new Litepicker({
      element: document.getElementById('end')!,
      format: 'DD.MM.YYYY',
      lang: 'de-DE'
    });

    this.endPicker.on('selected', (date1: any) => {
      this.incident.endReadable = date1.dateInstance.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
      this.incident.end = date1.dateInstance.toISOString();
    });
  }

  loadData() {
    this.ticketApi.getIncident(this.incidentId!).subscribe({
      next: (data) => {
        this.incident = data;

        this.newUpdate.state = this.incident.state;
        this.newUpdate.severity = this.incident.severity;

        // Sort history
        if (this.incident.history && this.incident.history.length > 0) {
          this.incident.history.sort((a, b) => {
            if (a.created_at && b.created_at) {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            } else {
              return 0;
            }
          });
        }

        if (this.incident.start) {
          const startDate = new Date(this.incident.start);
          this.incident.start = startDate.toISOString();
          this.startPicker!.setDate(startDate);
        }

        if (this.incident.end) {
          const endDate = new Date(this.incident.end);
          this.incident.end = endDate.toISOString();
          this.endPicker!.setDate(endDate);
        }

      },
      error: (error) => {
        showError(error);
      }
    });
  }

  addUpdate() {
    this.newUpdate.state = this.incident.state;
    this.newUpdate.severity = this.incident.severity;
    this.newUpdate.publishOnLandingpage = this.incident.publishOnLandingpage;
    this.newUpdate.announceToTickets = this.incident.announceToTickets!;
    this.newUpdate.public = this.incident.public;
    this.newUpdate.description = this.incident.description;
    this.newUpdate.text = this.incident.text;


    if (this.newUpdate.description === '' || this.newUpdate.description === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Beschreibung ein',
      })

      return;
    }

    if (this.newUpdate.text === '' || this.newUpdate.text === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie einen Text ein',
      })

      return;
    }

    this.ticketApi.addIncidentUpdate(this.incidentId!, this.newUpdate).subscribe({
      next: (data) => {
        Swal.fire({
          icon: 'success',
          title: 'Update hinzugefügt',
        }).then(() => {
          this.newUpdate = new IncidentHistoryDto();
          this.loadData();
        })
      },
      error: (error) => {
        showError(error);
      }
    })
  }

  save() {
    if (this.checkInput()) {
      this.ticketApi.updateIncident(this.incidentId!, {
        apps: this.incident.apps,
        language: this.incident.language,
        public: this.incident.public,
        start: this.incident.start,
        end: this.incident.end,
        text: this.incident.text,
        description: this.incident.description,
        deleteAfterExpiry: this.incident.deleteAfterExpiry,
        autoResolveAfterEndDate: this.incident.autoResolveAfterEndDate,
        publishOnLandingpage: this.incident.publishOnLandingpage,
        state: this.incident.state,
        severity: this.incident.severity,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Störung gespeichert',
          }).then(() => {
            this.loadData();
          })
        },
        error: (error) => {
          showError(error);
        }
      });
    }
  }

  create() {
    if (this.checkInput()) {
      this.ticketApi.addIncident({
        apps: this.incident.apps,
        language: this.incident.language,
        public: this.incident.public,
        start: this.incident.start,
        end: this.incident.end,
        text: this.incident.text,
        description: this.incident.description,
        deleteAfterExpiry: this.incident.deleteAfterExpiry,
        autoResolveAfterEndDate: this.incident.autoResolveAfterEndDate,
        publishOnLandingpage: this.incident.publishOnLandingpage,
        state: this.incident.state,
        severity: this.incident.severity,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Störung erstellt',
          }).then(() => {
            this.router.navigate(['/admin/incidents'])
          })
        },
        error: (error) => {
          showError(error);
        }
      });
    }
  }

  checkInput() {
    if (this.incident.description === '' || this.incident.description === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Beschreibung ein',
      })

      return false;
    }

    if (this.incident.text === '' || this.incident.text === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie einen Text ein',
      })

      return false;
    }

    return true;
  }

  deleteHistoryEntry(entry: IncidentHistoryDto) {
    Swal.fire({
      title: 'Löschen',
      text: 'Möchten Sie den Eintrag wirklich löschen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, löschen',
      cancelButtonText: 'Nein'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ticketApi.deleteIncidentHistory(entry._id!).subscribe({
          next: () => {
            this.loadData();
          },
          error: (error) => {
            showError(error);
          }
        });
      }
    });
  }

  deleteStartDate() {
    this.incident.start = undefined;
    this.incident.startReadable = undefined;
    this.startPicker!.clearSelection();
  }

  deleteEndDate() {
    this.incident.end = undefined;
    this.incident.endReadable = undefined;
    this.endPicker!.clearSelection();
  }

  renderState(state: string) {
    if (state === 'NEW') {
      return 'Störung aktiv';
    } else if (state === 'UNDER_INVESTIGATION') {
      return 'Störung in Prüfung';
    } else if (state === 'IN_PROGRESS') {
      return 'Störung in Bearbeitung';
    } else if (state === 'RESOLVED') {
      return 'Störung behoben';
    } else if (state === 'CLOSED') {
      return 'Störung geschlossen';
    } else {
      return 'Unbekannt';
    }
  }

  renderSeverity(severity: string) {
    if (severity === 'PARTIAL_OUTAGE') {
      return 'Teilausfall';
    } else if (severity === 'OUTAGE') {
      return 'severity';
    } else if (severity === 'DEGRADED_PERFORMANCE') {
      return 'System verlangsamt';
    } else if (severity === 'UNDER_MAINTENANCE') {
      return 'Wartung';
    } else if (severity === 'OPERATIONAL') {
      return 'Alle Systeme normal';
    } else {
      return 'Unbekannt';
    }
  }

}
