import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';
import Swal from 'sweetalert2';
import { showError } from '../../helper/utils.helper';
import { TicketApiService } from '../../services/ticket-api.service';
import CustomBuildEditor from 'src/ckeditor5/build/ckeditor';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  userData: any;
  signature: string = "";
  signature_public: string = "";
  signature_company: string = "";
  isLoading: boolean = false;

  public editor = CustomBuildEditor;

  editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'fontSize',
        'fontColor',
        '|',
        'outdent',
        'indent',
        'alignment',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'codeBlock',
        'code'
      ]
    },
    language: 'de',
  };

  constructor(
    public oidcSecurityService: OidcSecurityService,
    public ticketApi: TicketApiService,
    private preloadService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      this.userData = userData;
    });

    this.preloadService.dataLoaded.subscribe({
      next: () => {
        this.loadData();
      },
      error: (err) => {
        this.isLoading = false;
        showError(err);
      },
    });

    
  }

  loadData() {
    this.ticketApi.getMySignature().subscribe({
      next: (data) => {
        this.signature = data.signature;
        this.signature_public = data.signature_public;
        this.signature_company = data.signature_company;
        this.isLoading = false;
      },
      error: (err) => {
        showError(err);
        this.isLoading = false;
      }
    });
  }

  updateSignature() {
    this.isLoading = true;
    this.ticketApi.updateMySignature({
      signature: this.signature,
      signature_company: this.signature_company,
      signature_public: this.signature_public,
    }).subscribe({
      next: (data) => {
        this.signature = data.signature;
        this.isLoading = false;
        Swal.fire({
          icon: 'success',
          title: 'Deine Signatur wurde aktualisiert.',
        });
      },
      error: (err) => {
        showError(err);
        this.isLoading = false;
      }
    });
  }

}
