import { Component, OnInit } from '@angular/core';
import { TicketTemplateDto } from '../../dtos/ticket-template.dto';
import { AppDto } from '../../dtos/app.dto';
import { TicketApiService } from '../../services/ticket-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { showError } from '../../helper/utils.helper';
import Swal from 'sweetalert2';
import { TicketTemplateFieldDto } from '../../dtos/ticket-template-field.dto';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent implements OnInit {

  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  templateId: string | undefined;
  mode = 'new';

  template: TicketTemplateDto = new TicketTemplateDto();

  apps: AppDto[] = [];

  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    protected route: ActivatedRoute,
    protected landingpageService: LandingpageApiService,
    protected router: Router,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        if (params['id'] === 'new') {
          this.mode = 'new';
        } else {
          this.mode = 'edit';
          this.templateId = params['id'];
          
          this.preloaderService.dataLoaded.subscribe({
            next: () => {
              this.loadData();
            },
            error: (err) => {
              showError(err);
            },
          });
        }
      }
    })

    this.landingpageService.getMyAppsAll().subscribe({
      next: (data) => {
        this.apps = data;
      },
      error: (error) => {
        showError(error);
      }
    })
  }

  loadData() {
    this.ticketApi.getTicketTemplate(this.templateId!).subscribe({
      next: (data) => {
        this.template = data;
      },
      error: (error) => {
        showError(error);
      }
    });
  }

  save() {
    if (this.checkInput()) {
      this.ticketApi.updateTicketTemplate(this.templateId!, {
        app_id: this.template.app_id,
        description: this.template.description,
        name: this.template.name,
        isGeneral: this.template.isGeneral,
        category: this.template.category,
        fields: this.template.fields,
        language: this.template.language,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Vordefinierte Antwort gespeichert',
          }).then(() => {
            this.loadData();
          })
        },
        error: (error) => {
          showError(error);
        }
      });
    }
  }

  create() {
    if (this.checkInput()) {
      this.ticketApi.addTicketTemplate({
        app_id: this.template.app_id,
        description: this.template.description,
        name: this.template.name,
        isGeneral: this.template.isGeneral,
        category: this.template.category,
        fields: this.template.fields,
        language: this.template.language,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            icon: 'success',
            title: 'Vordefinierte Antwort erstellt',
          }).then(() => {
            this.router.navigate(['/admin/templates'])
          })
        },
        error: (error) => {
          showError(error);
        }
      });
    }
  }

  checkInput() {
    if (this.template.description === '' || this.template.description === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Beschreibung ein',
      })

      return false;
    }

    if (this.template.name === '' || this.template.name === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie einen Namen ein',
      })

      return false;
    }

    return true;
  }

  addField() {
    this.template.fields.push({
      name: 'Neues Feld',
      display_name: 'Neues Feld',
      type: 'text',
      required: true,
      options: [],
      regex: '',
    });
  }

  removeField(field: TicketTemplateFieldDto) {
    this.template.fields = this.template.fields.filter((f) => f !== field);
  }
}
