export class PriorityDto extends Object {  
    public _id?: string = "";
    public key: string = "";
    public display_name: string = "";
    public escalation_time: number = 0;
    public type: string = "";

    public static fromObject(src: any) {
      return Object.assign(new PriorityDto(), src);
    }
  }