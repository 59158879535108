import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import Swal from 'sweetalert2';

if (environment.production) {
  Sentry.init({
    dsn: "https://6961c49c3b0a4b3699be99a94ea32736@sentry.qloc.de/11",
    environment: environment.production ? 'production' : 'development',
    integrations: [
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  enableProdMode();
}

const time = new Date().getTime();
const response = fetch(environment.apiEndpoint).catch(err => {
  Swal.fire({
    title: 'Verbindungsfehler',
    text: 'Die Verbindung zum Server konnte nicht hergestellt werden. Bitte überprüfen Sie Ihre Internetverbindung oder versuchen Sie es später erneut.',
    icon: 'error',
    confirmButtonText: 'OK'
  }).then(() => {
    window.location.reload();
  });
});

response.then(data => {
  if (data) {
    data.json().then(servertime => {
      const serverTime = new Date(servertime.timestamp).getTime();
      const diff = serverTime - time;

      if (diff > 1000 * 60 * 5) {

        Swal.fire({
          title: 'Syncronisationsfehler',
          text: 'System-Uhrzeit stimmt nicht mit Serverzeit überein! - Die Systemzeit weicht mehr als 5 Minuten von der Serverzeit ab. Bitte überprüfen Sie die Systemzeit.',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then(() => {
          window.location.reload();
        });

      } else {
        platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
      }
    });
  }
});

