import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, debounceTime, startWith, delay, tap, switchMap, share, catchError, of, take } from 'rxjs';
import { showError } from '../../helper/utils.helper';
import { Page } from '../../models/page.model';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { TicketApiService } from '../../services/ticket-api.service';
import { LabelDto } from '../../dtos/label.dto';
import { FormControl, FormGroup } from '@angular/forms';
import { PreloaderService } from '../../services/preloader.service';
import { PriorityDto } from '../../dtos/priority.dto';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  loading = true;
  filterForm: FormGroup;
  pageDataObserver: (Observable<Page<PriorityDto>> | Observable<any>) | undefined;
  pageDataError$ = new Subject<any>();
  page = 1;
  userData: any;

  groups: GroupDto[] = [];
  users: UserDto[] = [];
  labels: LabelDto[] = [];

  filter = "";

  sort = {
    key: '_id',
    order: 'desc',
  }

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private ticketApi: TicketApiService,
    private preloaderService: PreloaderService,
    private activeRoute: ActivatedRoute,
  ) {

    this.filterForm = new FormGroup({
      search: new FormControl(),
      type: new FormControl(),
      filter: new FormControl(),
      page: new FormControl(1),
    });

    this.activeRoute.queryParams.subscribe(params => {
      if (params['search']) {
        this.filterForm.patchValue({ search: params['search'] });
      }

      if (params['type']) {
        this.filterForm.patchValue({ type: params['type'] });
      }

      if (params['filter']) {
        this.filterForm.patchValue({ filter: params['filter'] });
      }
    });

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.preloaderService.dataLoaded.subscribe({
      next: () => {
        this.pageDataObserver = this.filterForm.valueChanges.pipe(
          debounceTime(500),
          startWith(this.filterForm.value),
          tap(() => this.loading = true),
          switchMap(urlOrFilter => {

            console.log(urlOrFilter);

            urlOrFilter.search = encodeURIComponent(urlOrFilter.search);
            urlOrFilter.sort = 'ticket_number';
            urlOrFilter.sortOrder = 'desc';

            // urlOrFilter.type = 'none';

            return this.ticketApi.searchTickets(urlOrFilter)
          }),
          share(),
          catchError((error) => {
            showError(error);
            this.loading = false;
            return of();
          }),
          tap(() => this.loading = false),
        );

        this.filterForm.get('search')?.valueChanges.subscribe(val => {
          this.firstPage();
        });

        this.ticketApi.getGroups().subscribe(groups => {
          this.groups = groups;
        });

        this.ticketApi.getSupportUsers().subscribe(users => {
          this.users = users;
        });
      },
      error: (err) => {
        this.loading = false;
        showError(err);
      },
    });
  }
  ngOnInit(): void {
  }

  setFilter(filter: string) {
    this.filter = filter;
    this.firstPage();
  }

  nextPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current + 1 });
  }

  previousPage() {
    const current = this.filterForm.controls['page'].value;
    if (current > 1) {
      this.filterForm.patchValue({ page: current - 1 });
    }
  }

  getGroupName(id: string): string {
    const group = this.groups.find(g => g._id === id);
    if (group) {
      return group.name;
    }
    return '';
  }

  getUserName(id: string): string {
    const group = this.users.find(g => g.user_sso_id === id);
    if (group) {
      return group.name;
    }
    return '';
  }

  getLabel(id: string): LabelDto {
    const label = this.labels.find(g => g._id === id);
    if (label) {
      return label;
    }
    return {
      _id: '',
      name: '',
      color: '',
      hidden: false,
    };
  }

  firstPage() {
    this.filterForm.patchValue({ page: 1 });
  }
}
