import { MailDataAddress } from "./mail-data-address.dto";

export class MailDataDto extends Object {
    
    public to: MailDataAddress[] = [];  
    public cc: MailDataAddress[] = [];  
    public raw_mail?: string = "";
    public mail_id?: string = "";
  
    public static fromObject(src: any) {
      return Object.assign(new MailDataDto(), src);
    }
  }