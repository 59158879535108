<div class="page-body">
    <div class="container-fluid">
        <div class="row row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-body border-bottom py-3">

                        <div class="text-center">
                            <h1>Das neue EFSsupport System ist da!</h1>
                        </div>

                        <div>
                            <p>
                                Herzlich Willkommen in Deinem neuen EFSsupport System!
                            </p>

                            <p>
                                Hier kannst du als Vertriebspartner deine IT-bezogenen Fragen, Anliegen und Probleme
                                einreichen und unser EFS IT Support-Team wird diese schnell und präzise bearbeiten, um
                                sicherzustellen, dass deine Anforderungen so schnell wie möglich erfüllt werden.
                            </p>

                            <p>
                                Unser Support-System ist einfach zu bedienen und ermöglicht es dir, den Status deiner
                                Anfrage jederzeit zu verfolgen. Unser Ziel ist es, dir einen reibungslosen
                                IT-Support-Service zu bieten, damit dein Geschäft problemlos läuft. Wir freuen uns
                                darauf, dir zu helfen!
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-6 text-center">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center">
                            <h3>Du brauchst Hilfe?</h3>
                            <p>
                                Erstelle schnell ein neues Anliegen und wir kümmern uns darum.
                            </p>
                        </div>
                    </div>
                    <div class="card-footer justify-center">
                        <div class="divide-y pe-3 justify-center">


                            <div class="link" (click)="reportIssue()">
                                <div class="row">
                                    <div class="col-auto">
                                        <span class="avatar"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-octagon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12.802 2.165l5.575 2.389c.48 .206 .863 .589 1.07 1.07l2.388 5.574c.22 .512 .22 1.092 0 1.604l-2.389 5.575c-.206 .48 -.589 .863 -1.07 1.07l-5.574 2.388c-.512 .22 -1.092 .22 -1.604 0l-5.575 -2.389a2.036 2.036 0 0 1 -1.07 -1.07l-2.388 -5.574a2.036 2.036 0 0 1 0 -1.604l2.389 -5.575c.206 -.48 .589 -.863 1.07 -1.07l5.574 -2.388a2.036 2.036 0 0 1 1.604 0z"></path>
                                            <path d="M12 8v4"></path>
                                            <path d="M12 16h.01"></path>
                                         </svg></span>
                                    </div>
                                    <div class="col">
                                        <div class="text-truncate">
                                            <a [routerLink]="['/ticket/new']" [queryParams]="{mode: 'bug_report'}"><strong>Einen Fehler oder ein Problem
                                                    melden</strong></a>
                                        </div>
                                        <div class="text-secondary">Du hast ein Problem mit einem EFS System?</div>
                                    </div>
                                </div>
                            </div>

                            <div class="link" (click)="requestHelp()">
                                <div class="row">
                                    <div class="col-auto">
                                        <span class="avatar"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-call" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                                            <path d="M15 7a2 2 0 0 1 2 2"></path>
                                            <path d="M15 3a6 6 0 0 1 6 6"></path>
                                         </svg></span>
                                    </div>
                                    <div class="col">
                                        <div class="text-truncate">
                                            <a [routerLink]="['/ticket/new']" [queryParams]="{mode: 'support_request'}"><strong>Hilfe erhalten</strong></a>
                                        </div>
                                        <div class="text-secondary">Erhalte Hilfe bei der Einrichtung oder Konfiguration der EFS Systeme.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="link" (click)="requestChange()">
                                <div class="row">
                                    <div class="col-auto">
                                        <span class="avatar"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-browser-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z"></path>
                                            <path d="M4 8h16"></path>
                                            <path d="M8 4v4"></path>
                                            <path d="M10 14h4"></path>
                                            <path d="M12 12v4"></path>
                                         </svg></span>
                                    </div>
                                    <div class="col">
                                        <div class="text-truncate">
                                            <a [routerLink]="['/ticket/new']" [queryParams]="{mode: 'feature_request'}"><strong>Neuanforderung oder Änderungswünsche</strong></a>
                                        </div>
                                        <div class="text-secondary">Du hast einen Änderungswunsch oder möchtest ein neues Feature haben?</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-md-6 text-center">

                <div class="card">

                    <div class="card-body">
                        <div class="text-center">
                            <h3>Immer auf dem Laufenden</h3>
                            <p>
                                Prüfe den aktuellen Stand deiner Anliegen und ergänze weitere Informationen.
                            </p>
                        </div>
                    </div>
                    <div class="card-footer justify-center">
                        <a [routerLink]="['/tickets']" class="btn btn-efs">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checklist"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8"></path>
                                <path d="M14 19l2 2l4 -4"></path>
                                <path d="M9 8h4"></path>
                                <path d="M9 12h2"></path>
                            </svg>
                            Meine Anliegen anzeigen
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>