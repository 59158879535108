<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" *ngIf="mode === 'new'">Gruppe erstellen</h3>
                        <h3 class="card-title" *ngIf="mode === 'edit'">Gruppe bearbeiten</h3>
                    </div>

                    <div class="card-body">

                        <div class="row mb-3">

                            <div class="row ms-2 me-2 mt-2 mb-2" *ngIf="isLoading">
                                <div class="col-12">
                                    <div class="progress mt-2 mb-2">
                                        <div class="progress-bar progress-bar-indeterminate bg-green"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mb-3" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Name</label>
                                    <p class="text-muted">
                                        Aussagekräftiger Name für die Gruppe der im EFSsupport-System angezeigt wird
                                    </p>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="group.name">
                                </div>
                            </div>

                            <div class="col-12" *ngIf="!isLoading">
                                <div class="form-group">
                                    <label class="form-label">Import-Mailboxen (Kommasepariert)</label>
                                    <p class="text-muted">
                                        Liste der EFS Mailboxen, von denen automatisiert neue Anliegen importiert werden.
                                        Das Postfach darf nur vom EFSsupport System genutzt werden!
                                    </p>
                                    <input type="text" class="form-control" required name="" id=""
                                        [(ngModel)]="group.ingest_mailboxes_string">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="card-footer">
                        <button class="btn btn-efs" *ngIf="mode === 'edit'" (click)="save()"
                            [disabled]="isLoading">Speichern</button>
                        <button class="btn btn-efs" *ngIf="mode === 'new'" (click)="create()"
                            [disabled]="isLoading">Anlegen</button>
                    </div>
                </div>
            </div>

            <div class="col-8" *ngIf="mode === 'edit' && !isLoading">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Mitglieder</h2>
                    </div>

                    <div class="card-body" *ngIf="getUsersInGroup(this.group).length === 0">
                        <div class="alert alert-warning">
                            Keine Mitglieder vorhanden!
                        </div>
                    </div>

                    <table class="table table-vcenter table-mobile-md card-table"
                        *ngIf="getUsersInGroup(this.group).length > 0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Benutzername</th>
                                <th>Berechtigungen</th>
                                <th>Aktionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of getUsersInGroup(this.group)">
                                <td data-label="Name">{{ user.name }}</td>
                                <td data-label="Benutzername">{{ user.user_sso_id }}</td>
                                <td data-label="Berechtigungen">{{
                                    translatePermissions(getUserPermissions(user)!) }}</td>


                                <td data-label="Aktionen">
                                    <button class="btn"
                                        (click)="removeUserFromGroup(user.user_sso_id, this.group._id!)">Löschen</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-4" *ngIf="mode === 'edit' && !isLoading">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Mitglieder hinzufügen/bearbeiten</h2>
                    </div>
                    <div class="card-body">
                        <label class="form-label">Person auswählen</label>
                        <select class="form-control" name="" id="" [(ngModel)]="addUserToGroup.user_sso_id">
                            <option [ngValue]="user.user_sso_id" *ngFor="let user of users">{{ user.name }}</option>
                        </select>

                        <label class="form-label mt-3">Berechtigung festlegen</label>
                        <select class="form-control" name="" id="" multiple [(ngModel)]="addUserToGroup.permissions">
                            <option [ngValue]="'read'">Lesen</option>
                            <option [ngValue]="'write'">Schreiben</option>
                            <option [ngValue]="'supervise'">Verwalten</option>
                        </select>

                        <button class="btn btn-efs mt-3" (click)="addUserToGroupSubmit()"
                            [disabled]="isLoading">Hinzufügen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>