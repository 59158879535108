import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import Swal from 'sweetalert2';
import { TicketApiService } from '../../services/ticket-api.service';
import { showError } from '../../helper/utils.helper';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { AddUserGroupDto } from '../../dtos/add-user-group.dto';
import { PriorityDto } from '../../dtos/priority.dto';
import { PreloaderService } from '../../services/preloader.service';


@Component({
  selector: 'app-priority-edit',
  templateUrl: './priority-edit.component.html',
  styleUrls: ['./priority-edit.component.scss']
})
export class PriorityEditComponent implements OnInit {

  priorityId: string | undefined;
  mode = 'new';
  isLoading = false;

  priority: PriorityDto = new PriorityDto();
  users: UserDto[] = [];


  constructor(
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    protected route: ActivatedRoute,
    protected router: Router,
    private preloaderService: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id']) {

        if (params['id'] === 'new') {
          this.mode = 'new';
        } else {
          this.mode = 'edit';
          this.priorityId = params['id'];
          
          this.preloaderService.dataLoaded.subscribe({
            next: () => {
              this.loadData();
            },
            error: (err) => {
              this.isLoading = false;
              showError(err);
            },
          });
        }
      }
    })
  }

  loadData() {
    this.isLoading = true;
    this.ticketApi.getPriority(this.priorityId!).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.priority = data;
      },
      error: (error) => {
        this.isLoading = false;
        showError(error);
      }
    });
  }

  save() {
    if (this.checkInput()) {
      this.isLoading = true;
      this.ticketApi.updatePriorityEntry(this.priorityId!, {
        escalation_time: this.priority.escalation_time,
        key: this.priority.key,
        type: this.priority.type,
        display_name: this.priority.display_name,
      }).subscribe({
        next: (data) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Priorität und SLA gespeichert',
          }).then(() => {
            this.loadData();
          })
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        }
      });
    }
  }

  create() {
    if (this.checkInput()) {
      this.isLoading = true;
      this.ticketApi.addPriority({
        escalation_time: this.priority.escalation_time,
        key: this.priority.key,
        type: this.priority.type,
        display_name: this.priority.display_name,
      }).subscribe({
        next: (data) => {
          this.isLoading = false;
          Swal.fire({
            icon: 'success',
            title: 'Priorität und SLA erstellt',
          }).then(() => {
            this.router.navigate(['/admin/prioritys'])
          })
        },
        error: (error) => {
          this.isLoading = false;
          showError(error);
        }
      });
    }
  }



  checkInput() {
    if (this.priority.escalation_time === 0 || this.priority.escalation_time === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Eskalationszeit ein',
      })

      return false;
    }

    if (this.priority.key === '' || this.priority.key === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Schlüssel ein',
      })

      return false;
    }

    if (this.priority.type === '' || this.priority.type === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie eine Typ ein',
      })

      return false;
    }

    if (this.priority.display_name === '' || this.priority.display_name === undefined) {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte geben Sie einen Anzeigenamen ein',
      })

      return false;
    }

    return true;
  }
}
