import { Component, OnInit } from '@angular/core';
import { AppDto } from '../../dtos/app.dto';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, debounceTime, startWith, delay, tap, switchMap, share, catchError, of } from 'rxjs';
import { TicketDto } from '../../dtos/ticket.dto';
import { showError } from '../../helper/utils.helper';
import { Page } from '../../models/page.model';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { TicketApiService } from '../../services/ticket-api.service';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { PreloaderService } from '../../services/preloader.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  loading = true;
  filterForm: FormGroup;
  pageDataObserver: (Observable<Page<UserDto>> | Observable<any>) | undefined;;
  pageDataError$ = new Subject<any>();
  page = 1;

  filter = "";

  sort = {
    key: 'created_at',
    order: 'desc',
  }

  constructor(
    private ticketApi: TicketApiService,
    private landingpageApi: LandingpageApiService,
    private router: Router,
    private preloaderService: PreloaderService,
  ) {
    this.filterForm = new FormGroup({
      search: new FormControl(),
      page: new FormControl(1),
    });

    this.preloaderService.dataLoaded.subscribe({
      next: () => {
    
        this.pageDataObserver = this.filterForm.valueChanges.pipe(
          debounceTime(200),
          startWith(this.filterForm.value),
          delay(0),
          tap(() => this.loading = true),
          switchMap(urlOrFilter => {
    
            urlOrFilter.sort = this.sort.key;
            urlOrFilter.sortOrder = this.sort.order;
    
            if (this.filter !== '') {
              urlOrFilter.filter = 'role';
              urlOrFilter.filterValue = this.filter;
            }
    
            return this.ticketApi.getAllUsers(urlOrFilter)
          }),
          share(),
          catchError((error) => {
            showError(error);
            this.loading = false;
            return of();
          }),
          tap(() => this.loading = false),
        );
    
        this.filterForm.get('search')?.valueChanges.subscribe(val => {
          this.firstPage();
        });
      },
    })
  }
  ngOnInit(): void {
   
  }

  setFilter(filter: string) {
    this.filter = filter;
    this.firstPage();
  }

  nextPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current + 1 });
  }

  previousPage() {
    const current = this.filterForm.controls['page'].value;
    if (current > 1) {
      this.filterForm.patchValue({ page: current - 1 });
    }
  }

  firstPage() {
    this.filterForm.patchValue({ page: 1 });
  }

  openAdminpanel(user: UserDto) {
    if (user.user_sso_id.startsWith('A') || user.user_sso_id.startsWith('U')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Austria/${user.user_sso_id}`, '_blank');
    } else if (user.user_sso_id.startsWith('D')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Germany/${user.user_sso_id}`, '_blank');
    } else if (user.user_sso_id.startsWith('H')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Hungary/${user.user_sso_id}`, '_blank');
    } else if (user.user_sso_id.startsWith('E')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Extern/${user.user_sso_id}`, '_blank');
    } else if (user.user_sso_id.startsWith('I')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Innendienst/${user.user_sso_id}`, '_blank');
    } else if (user.user_sso_id.startsWith('X')) {
      window.open(`https://manage.efs-ag.services/pages/efs-users/Assistenten/${user.user_sso_id}`, '_blank');
    }
  }
}
