<div class="page-body mt-2">

    <div class="container mt-6 mb-6" *ngIf="isLoading">
        <div class="text-center">
            <div class="mb-3">
            </div>
            <div class="text-muted mb-3">Lade Daten</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="!isLoading">
        <div class="row row-deck row-cards">
            <div class="col-12">

                <div class="row row-deck row-cards mt-0" *ngIf="ticket !== undefined">
                    <div class="col-xl-8 col-lg-12">
                        <div class="card">
                            <div class="card-header" *ngIf="nextTickets !== undefined">
                                <button (click)="prevTicket()" [disabled]="nextTickets.previousTicketId === null"
                                    class="btn me-2"><svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-arrow-big-left-line me-1" width="24"
                                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M12 15v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h6v6h-6z">
                                        </path>
                                        <path d="M21 15v-6"></path>
                                    </svg>Zurück</button>
                                <button (click)="nextTicket()" [disabled]="nextTickets.nextTicketId === null"
                                    class="btn">Weiter<svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-arrow-big-right-line ms-1 me-0" width="24"
                                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M12 9v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-6v-6h6z">
                                        </path>
                                        <path d="M3 9v6"></path>
                                    </svg></button>
                            </div>

                            <div class="card-header">
                                <div class="row w-100" *ngIf="ticket !== undefined">
                                    <div class="col-lg-12 mt-0">
                                        <h2 class="page-title" *ngIf="ticket !== undefined">
                                            <span *ngIf="!editSubjectMode">{{ ticket.subject }}</span>

                                            <a class="ms-2 link" *ngIf="!editSubjectMode" (click)="editSubject()"
                                                ngbTooltip="Titel des Anliegens bearbeiten" placement="end">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-edit" width="24" height="24"
                                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1">
                                                    </path>
                                                    <path
                                                        d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z">
                                                    </path>
                                                    <path d="M16 5l3 3"></path>
                                                </svg>
                                            </a>

                                        </h2>

                                        <div class="row mt-2" *ngIf="editSubjectMode && ticket !== undefined">
                                            <div class="col">
                                                <input type="text" class="form-control" [(ngModel)]="ticket.subject">
                                            </div>
                                            <div class="col-auto">
                                                <a (click)="updateSubject()" class="btn btn-icon" aria-label="Button"
                                                    [ngbTooltip]="'Änderung speichern'">
                                                    <!-- Download SVG icon from http://tabler-icons.io/i/search -->
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-device-floppy" width="24"
                                                        height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path
                                                            d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2">
                                                        </path>
                                                        <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                        <path d="M14 4l0 4l-6 0l0 -4"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="datagrid">
                                    <div class="datagrid-item">
                                        <div class="datagrid-title">Anliegen Nummer</div>
                                        <div class="datagrid-content">#{{ ticket.ticket_number }}</div>
                                    </div>
                                    <div class="datagrid-item">
                                        <div class="datagrid-title">Anliegen Typ</div>
                                        <div class="datagrid-content" *ngIf="ticket.type === 'support_request'">
                                            Support-Anfrage</div>
                                        <div class="datagrid-content" *ngIf="ticket.type === 'bug_report'">Bug-Report
                                        </div>
                                        <div class="datagrid-content" *ngIf="ticket.type === 'feature_request'">
                                            Feature-Wunsch</div>
                                    </div>
                                    <div class="datagrid-item">
                                        <div class="datagrid-title">Betreff</div>
                                        <div class="datagrid-content">{{ ticket.subject }}</div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.app_id">
                                        <div class="datagrid-title">Zugehörige App</div>
                                        <div class="datagrid-content">{{ getAppName(ticket.app_id) }}</div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.assigned_to_user_sso_id">
                                        <div class="datagrid-title">Zugeordneter Benutzer</div>
                                        <div class="datagrid-content">{{ userById(ticket.assigned_to_user_sso_id) }}
                                        </div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.group_id">
                                        <div class="datagrid-title">Zugeordnete Gruppe</div>
                                        <div class="datagrid-content">{{ groupById(ticket.group_id) }}</div>
                                    </div>
                                    <div class="datagrid-item"
                                        *ngIf="ticket.sub_groups && ticket.sub_groups.length > 0">
                                        <div class="datagrid-title">Beteiligte Gruppe</div>
                                        <div class="datagrid-content">

                                            <span class="tag me-2 mb-2"
                                                *ngFor="let group of groupsByIds(ticket.sub_groups)">
                                                {{ group!.name }}
                                                <a class="btn-close label link" (click)="removeSubGroup(group!)"></a>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="ticket.labels && ticket.labels.length > 0">
                                        <div class="datagrid-title">Labels</div>
                                        <div class="datagrid-content">


                                            <span class="tag me-2 mb-2" *ngFor="let label of ticket.labels">
                                                <span class="legend"
                                                    [style.background-color]="getLabel(label).color"></span>
                                                {{ getLabel(label).name }}
                                                <a class="btn-close link" [style.color]="'white'"
                                                    (click)="removeLabel(label)"></a>
                                            </span>


                                        </div>
                                    </div>

                                    <div class="datagrid-item">
                                        <div class="datagrid-title">Status</div>
                                        <div class="datagrid-content">

                                            <span *ngIf="ticket.status === 'open'"
                                                class="status status-green">Offen</span>
                                            <span *ngIf="ticket.status === 'closed'"
                                                class="status status-secondary">Geschlossen</span>
                                            <span *ngIf="ticket.status === 'answered'"
                                                class="status status-success">Beantwortet</span>
                                            <span *ngIf="ticket.status === 'in_progress'"
                                                class="status status-success">In
                                                Bearbeitung</span>
                                            <span *ngIf="ticket.status === 'more_information_needed'"
                                                class="status status-warning">Weitere Informationen benötigt</span>

                                        </div>
                                    </div>

                                    <div class="datagrid-item">
                                        <div class="datagrid-title">Priorität</div>
                                        <div class="datagrid-content">
                                            <span *ngIf="ticket.priority === 'none'" class="status">Keine
                                                Eskalation</span>
                                            <span *ngIf="ticket.priority === 'low'" class="status">Niedrig</span>
                                            <span *ngIf="ticket.priority === 'medium'"
                                                class="status status-secondary">Mittel</span>
                                            <span *ngIf="ticket.priority === 'high'"
                                                class="status status-warning">Hoch</span>
                                            <span *ngIf="ticket.priority === 'asap'"
                                                class="status status-danger">ASAP</span>

                                        </div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="!ticket.created_for_user">
                                        <div class="datagrid-title">Erstellt von</div>

                                        <div class="datagrid-content " *ngIf="ticket.author_user_sso_id">
                                            <span ngbTooltip="Zum Adminpanel" class="link"
                                                (click)="openAdminpanel(ticket.author_user_sso_id)">{{
                                                ticket.author_name }} <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-external-link icon-sm"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                    </path>
                                                    <path d="M11 13l9 -9"></path>
                                                    <path d="M15 4h5v5"></path>
                                                </svg></span>
                                            <br>
                                            <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                                }}<br></span>
                                            <span class="text-muted"
                                                *ngIf="ticket.author_user_sso_id">{{ticket.author_user_sso_id }}</span>
                                        </div>

                                        <div class="datagrid-content" *ngIf="!ticket.author_user_sso_id">
                                            {{ ticket.author_name }}<br>
                                            <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                                }}<br></span>
                                        </div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="ticket.created_for_user">
                                        <div class="datagrid-title">Erstellt von</div>
                                        <div class="datagrid-content">

                                            <div class="datagrid-content" *ngIf="ticket.created_for_user_cn">
                                                <span ngbTooltip="Zum Adminpanel" class="link"
                                                    (click)="openAdminpanel(ticket.created_for_user_cn)">{{
                                                    ticket.created_for_user_name }} <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-external-link icon-sm"
                                                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path
                                                            d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                        </path>
                                                        <path d="M11 13l9 -9"></path>
                                                        <path d="M15 4h5v5"></path>
                                                    </svg></span>
                                                <br>
                                                <span class="text-muted" *ngIf="ticket.created_for_user_mail">{{
                                                    ticket.created_for_user_mail
                                                    }}<br></span>
                                                <span class="text-muted"
                                                    *ngIf="ticket.created_for_user_cn">{{ticket.created_for_user_cn
                                                    }}</span>
                                            </div>

                                            <div class="datagrid-content" *ngIf="!ticket.created_for_user_cn">
                                                {{ ticket.created_for_user_name }}<br>
                                                <span class="text-muted" *ngIf="ticket.created_for_user_mail">{{
                                                    ticket.created_for_user_mail
                                                    }}<br></span>
                                            </div>



                                        </div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="ticket.created_for_user">
                                        <div class="datagrid-title">Erstellt für</div>

                                        <div class="datagrid-content" *ngIf="ticket.author_user_sso_id">
                                            <span ngbTooltip="Zum Adminpanel" class="link"
                                                (click)="openAdminpanel(ticket.author_user_sso_id)">{{
                                                ticket.author_name }} <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-external-link icon-sm"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                    </path>
                                                    <path d="M11 13l9 -9"></path>
                                                    <path d="M15 4h5v5"></path>
                                                </svg></span>
                                            <br>
                                            <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                                }}<br></span>
                                            <span class="text-muted"
                                                *ngIf="ticket.author_user_sso_id">{{ticket.author_user_sso_id }}</span>
                                        </div>

                                        <div class="datagrid-content" *ngIf="!ticket.author_user_sso_id">
                                            {{ ticket.author_name }}<br>
                                            <span class="text-muted" *ngIf="ticket.author_email">{{ ticket.author_email
                                                }}<br></span>
                                        </div>
                                    </div>

                                    <div class="datagrid-item">
                                        <div class="datagrid-title">Quelle</div>
                                        <div class="datagrid-content">
                                            <span *ngIf="ticket.source === 'mail'">E-Mail</span>
                                            <span *ngIf="ticket.source === 'landingpage'">EFSsupport System</span>
                                        </div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.created_at">
                                        <div class="datagrid-title">Eröffnet am</div>
                                        <div class="datagrid-content">{{ ticket.created_at | date: 'dd.MM.yyyy HH:mm' }}
                                        </div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.first_answer_at">
                                        <div class="datagrid-title">Erste Antwort</div>
                                        <div class="datagrid-content">
                                            {{ ticket.first_answer_at | date: 'dd.MM.yyyy HH:mm' }}
                                        </div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.last_answer_at">
                                        <div class="datagrid-title">Neueste Antwort</div>
                                        <div class="datagrid-content">
                                            {{ ticket.last_answer_at | date: 'dd.MM.yyyy HH:mm' }}
                                        </div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.last_answer_at">
                                        <div class="datagrid-title">Keine Antwort seit</div>
                                        <div class="datagrid-content">
                                            {{ ticket.last_answer_at | amLocale: browserLocale | amTimeAgo:true }}<br>
                                        </div>
                                    </div>
                                    <div class="datagrid-item" *ngIf="ticket.resolved_at">
                                        <div class="datagrid-title">Anliegen erledigt am</div>
                                        <div class="datagrid-content">{{ ticket.resolved_at | date: 'dd.MM.yyyy HH:mm'
                                            }}</div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="ticket.escalated_at">
                                        <div class="datagrid-title">Anliegen eskaliert am</div>
                                        <div class="datagrid-content"><span class="status status-danger">{{
                                                ticket.escalated_at
                                                | date: 'dd.MM.yyyy HH:mm' }}</span></div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="ticket.escalation_auto_at">
                                        <div class="datagrid-title">Anliegen eskaliert am</div>
                                        <div class="datagrid-content"><span class="status status-danger">{{
                                                ticket.escalation_auto_at | date: 'dd.MM.yyyy HH:mm' }}</span></div>
                                    </div>

                                    <div class="datagrid-item" *ngIf="ticket.internal_status === 'merged'">
                                        <div class="datagrid-title">Anliegen ist zusammengeführt</div>
                                        <div class="datagrid-content"><a (click)="revertMerge()"
                                                class="btn btn-xs link">Rückgängig machen</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Schnellaktionen</h3>
                                <div class="card-actions">
                                    <button class="btn btn-xs btn-efs me-2" (click)="quickAssignMe()"
                                        [disabled]="isLoading">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-hand-stop" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M8 13v-7.5a1.5 1.5 0 0 1 3 0v6.5"></path>
                                            <path d="M11 5.5v-2a1.5 1.5 0 1 1 3 0v8.5"></path>
                                            <path d="M14 5.5a1.5 1.5 0 0 1 3 0v6.5"></path>
                                            <path
                                                d="M17 7.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47">
                                            </path>
                                        </svg>
                                        Anliegen mir zuordnen
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-6 col-md-12 mb-2">
                                        <div class="input-group">
                                            <select class="form-control form-control-sm"
                                                [(ngModel)]="ticketStatusUpdate.group_id">
                                                <option [value]="group._id" *ngFor="let group of groups">{{ group.name
                                                    }}
                                                </option>
                                            </select>
                                            <button class="btn btn-xs btn-efs" aria-label="Button"
                                                (click)="assignGroup()" [ngbTooltip]="'Gruppe zuweisen'"
                                                [disabled]="isLoading">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-users-group me-0 ms-0"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                    <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1"></path>
                                                    <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                    <path d="M17 10h2a2 2 0 0 1 2 2v1"></path>
                                                    <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                    <path d="M3 13v-1a2 2 0 0 1 2 -2h2"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 mb-2">
                                        <div class="input-group">
                                            <select class="form-control form-control-sm"
                                                [(ngModel)]="ticketStatusUpdate.assigned_to_user_sso_id">
                                                <option [value]="undefined">Keine Zuweisung</option>
                                                <option [value]="user.user_sso_id" *ngFor="let user of users">{{
                                                    user.name }}
                                                </option>
                                            </select>
                                            <button class="btn btn-xs btn-efs" aria-label="Button"
                                                (click)="assignUser()" ngbTooltip="Person zuweisen"
                                                [disabled]="isLoading">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-user-plus me-0 ms-0" width="24"
                                                    height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                                    <path d="M16 19h6"></path>
                                                    <path d="M19 16v6"></path>
                                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 mb-2">
                                        <div class="input-group">
                                            <select class="form-control form-control-sm"
                                                [(ngModel)]="ticketStatusUpdate.priority">
                                                <option [value]="'none'">Keine Eskalation</option>
                                                <option [value]="'low'">Niedrig</option>
                                                <option [value]="'medium'">Mittel</option>
                                                <option [value]="'high'">Hoch</option>
                                                <option [value]="'asap'">ASAP</option>
                                            </select>
                                            <button class="btn btn-xs btn-efs" aria-label="Button"
                                                (click)="updatePriority()" ngbTooltip="Priorität ändern"
                                                [disabled]="isLoading">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-bell-bolt me-0 ms-0" width="24"
                                                    height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M13.5 17h-9.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v1">
                                                    </path>
                                                    <path d="M9 17v1a3 3 0 0 0 4.368 2.67"></path>
                                                    <path d="M19 16l-2 3h4l-2 3"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 mb-2">
                                        <div class="input-group">
                                            <select2 [customSearchEnabled]=true placeholder="Anliegen suchen"
                                                [data]="mergeableTickets" (search)="updateSearchMergableTickets($event)"
                                                (update)="updateMergableTicket($event)" [templates]="template">
                                                <ng-template #template let-data="data">

                                                    <div *ngIf="data === undefined" class="font-12">
                                                        Lade ...
                                                    </div>

                                                    <div *ngIf="data === null" class="font-12">
                                                        Kein Treffer!
                                                    </div>

                                                    <div *ngIf="data" class="font-12">
                                                        <strong>#{{ data?.ticket_number }} - {{ data?.subject
                                                            }}</strong><br>
                                                        {{ data?.author_name }} <span
                                                            *ngIf="data?.author_user_sso_id">({{
                                                            data?.author_user_sso_id }}) </span> <span
                                                            *ngIf="data?.author_email">({{ data?.author_email }})
                                                            <strong *ngIf="data?.merged_with_ticket_id"
                                                                style="color: red;"><br>Bereits
                                                                zusammengeführt!</strong>
                                                        </span>
                                                    </div>
                                                </ng-template>
                                            </select2>
                                            <button class="btn btn-xs btn-efs" aria-label="Button"
                                                (click)="mergeTicket()" ngbTooltip="Anliegen zusammenführen"
                                                [disabled]="isLoading">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-arrow-merge me-0 ms-0"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M8 7l4 -4l4 4"></path>
                                                    <path
                                                        d="M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394">
                                                    </path>
                                                    <path
                                                        d="M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 mb-2" *ngIf="incidents.length > 0">
                                        <div class="input-group">
                                            <select2 [customSearchEnabled]=true [data]="incidents"
                                                placeholder="Störung suchen" (search)="updateSearchIncident($event)"
                                                (update)="updateIncident($event)">
                                            </select2>
                                            <button class="btn btn-xs btn-efs" aria-label="Button"
                                                (click)="assignIncident()" ngbTooltip="Anliegen einer Störung zuweisen"
                                                [disabled]="isLoading">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-alert-triangle me-0 ms-0"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M12 9v4"></path>
                                                    <path
                                                        d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z">
                                                    </path>
                                                    <path d="M12 16h.01"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 mb-2">
                                        <div class="input-group">
                                            <select class="form-control form-control-sm" [(ngModel)]="ticket.type">
                                                <option [value]="'support_request'">Support-Anfrage</option>
                                                <option [value]="'bug_report'">Bug-Report</option>
                                                <option [value]="'feature_request'">Feature-Wunsch</option>
                                            </select>
                                            <button class="btn btn-xs btn-efs" aria-label="Button"
                                                (click)="updateTicketType()" ngbTooltip="Typ des Anliegen ändern"
                                                [disabled]="isLoading">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-file-orientation me-0 ms-0"
                                                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                    stroke="currentColor" fill="none" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                                                    <path d="M10 21h-3a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v2">
                                                    </path>
                                                    <path d="M13 20h5a2 2 0 0 0 2 -2v-5"></path>
                                                    <path d="M15 22l-2 -2l2 -2"></path>
                                                    <path d="M18 15l2 -2l2 2"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <label class="form-label text-center mt-2">Anliegen-Status ändern</label>

                                <button class="btn btn-xs btn-primary mb-2 me-1" (click)="quickUpdateStatus('open')"
                                    [disabled]="isLoading">
                                    Offen
                                </button>

                                <button class="btn btn-xs btn-secondary mb-2 me-1" (click)="quickUpdateStatus('closed')"
                                    [disabled]="isLoading">
                                    Geschlossen
                                </button>

                                <button class="btn btn-xs btn-success mb-2 me-1" (click)="quickUpdateStatus('answered')"
                                    [disabled]="isLoading">
                                    Beantwortet
                                </button>

                                <button class="btn btn-xs btn-success mb-2 me-1"
                                    (click)="quickUpdateStatus('in_progress')" [disabled]="isLoading">
                                    In Bearbeitung
                                </button>

                                <button class="btn btn-xs btn-warning mb-2 me-1"
                                    (click)="quickUpdateStatus('more_information_needed')" [disabled]="isLoading">
                                    Weitere Informationen benötigt
                                </button>

                                <label class="form-label text-center mt-2"></label>

                                <div class="accordion" id="accordion-label">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading-1">
                                            <button class="accordion-button collapsed p-2 ps-3 pe-3" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapse-1-label"
                                                aria-expanded="true">
                                                <strong>Label zuweisen</strong>
                                            </button>
                                        </h2>

                                        <div *ngIf="labels.length === 0">
                                            Keine Labels vorhanden
                                        </div>

                                        <div id="collapse-1-label" class="accordion-collapse collapse"
                                            data-bs-parent="#accordion-label">
                                            <div class="accordion-body">
                                                <div *ngIf="labels.length > 0">
                                                    <span *ngFor="let label of visibleLabels" (click)="addLabel(label)"
                                                        class="badge link mb-2 me-1"
                                                        [style.background-color]="getLabel(label._id!).color">{{
                                                        getLabel(label._id!).name
                                                        }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="text-center conversation-opener w-100 mt-2 mb-2">- Konversationsverlauf -</div>
                    </div>

                    <div class="col-lg-12" *ngFor="let answer of ticketAnswers">

                        <!-- Status change -->
                        <div class="card-body ms-4"
                            *ngIf="answer.type === 'auditAnswer' &&  answer.audit_answer_type === 'ticketStatusUpdate' && answer.audit_visible_in_ticket">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-info-circle text-muted" width="24" height="24"
                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                <path d="M12 9h.01"></path>
                                <path d="M11 12h1v4h1"></path>
                            </svg> <span [innerHTML]="answer.text" class="ms-1"></span> <small
                                class="text-muted ms-1">{{
                                answer.created_at | date: 'dd.MM.yyyy HH:mm' }}</small>
                        </div>


                        <!-- Customer Answers -->
                        <div [id]="'ID' + answer._id" class="card"
                            [classList]="selectedAnswer === answer._id ? 'card answer-selected' : 'card'"
                            *ngIf="answer.type === 'customerAnswer' || answer.type === 'supportAnswer' || answer.type === 'internalAnswer'">
                            <div class="card-header card-small">

                                <span *ngIf="answer.created_for_user" class="me-2 badge bg-efs"
                                    ngbTooltip="Diese Antwort wurde vom EFS-IT-Support im Namen des Vertriebspartners erstellt">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-phone-incoming" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path
                                            d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                        </path>
                                        <path d="M15 9l5 -5"></path>
                                        <path d="M15 5l0 4l4 0"></path>
                                    </svg>
                                </span>

                                <span ngbTooltip="Interne Antwort" class="badge badge-outline bg-yellow me-1"
                                    *ngIf="answer.type === 'internalAnswer'"><svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-user-shield" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h2"></path>
                                        <path
                                            d="M22 16c0 4 -2.5 6 -3.5 6s-3.5 -2 -3.5 -6c1 0 2.5 -.5 3.5 -1.5c1 1 2.5 1.5 3.5 1.5z">
                                        </path>
                                        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                    </svg></span>

                                <strong *ngIf="!answer.author_user_sso_id" class="me-1"
                                    [ngbTooltip]="(answer.author_email ? answer.author_email : '') + ' ' + (answer.author_user_sso_id ? answer.author_user_sso_id : '')">{{
                                    answer.author_name }}</strong>

                                <strong *ngIf="answer.author_user_sso_id"
                                    (click)="openAdminpanel(answer.author_user_sso_id)" class="me-1 link"
                                    [ngbTooltip]="'Zum Adminpanel\n\n' + (answer.author_email ? answer.author_email : '') + ' ' + (answer.author_user_sso_id ? answer.author_user_sso_id : '')">
                                    {{ answer.author_name }}

                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-external-link icon-sm" width="24"
                                        height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
                                        <path d="M11 13l9 -9"></path>
                                        <path d="M15 4h5v5"></path>
                                    </svg>
                                </strong>

                                <span
                                    [ngbTooltip]="'Der Absender ist nicht Teil der EFS-AG bzw. konnte nicht als solcher identifiziert werden. Bitte prüfe die Anfrage sorgfältig!'"
                                    class="badge badge-outline bg-danger me-1"
                                    *ngIf="isExternalSender(answer) && answer.type === 'customerAnswer'">
                                    Externer Absender
                                </span>

                                <span *ngIf="answer.type === 'supportAnswer'"
                                    class="badge badge-outline bg-primary me-1">Support</span>

                                - <strong class="ms-1 me-1">{{
                                    answer.created_at | date: 'dd.MM.yyyy HH:mm' }}</strong>

                                <span *ngIf="answer.merge_source" class="ms-1 link badge badge-outline bg-cyan"
                                    [routerLink]="['/admin/tickets', answer.merge_source]"
                                    [ngbTooltip]="'Diese Antwort wurde aus der zusammengeführten Anfrage übernommen.'">Zusammengeführt</span>

                                <span *ngIf="answer.edited" class="ms-1 text-muted">(bearbeitet)</span>
                                <span *ngIf="answer.hidden" class="ms-1 text-muted link" (click)="toggleHidden(answer)"
                                    [ngbTooltip]="'Diese Antwort wurde ausgeblendet und ist für Vertriebspartner nicht sichtbar.\n\nZum Anzeigen klicken.'">(ausgeblendet)</span>

                                <span class="ms-auto">
                                    <a class="link badge badge-outline text-efs badge-pill" data-bs-toggle="dropdown"
                                        aria-label="Open user menu" href="#"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-dots">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                            <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                            <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                        </svg></a>

                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <a class="dropdown-item" *ngIf="answer.mail_data && answer.mail_data.raw_mail"
                                            (click)="downloadRawMail(answer);">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-download me-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                                <path d="M7 11l5 5l5 -5" />
                                                <path d="M12 4l0 12" />
                                            </svg>
                                            Roh-Email herunterladen
                                        </a>
                                        <a class="dropdown-item" *ngIf="answer.mail_data && answer.mail_data.mail_id"
                                            (click)="saveSendReport(answer.mail_data.mail_id);">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-report me-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                                <path d="M18 14v4h4" />
                                                <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
                                                <path
                                                    d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                                <path d="M8 11h4" />
                                                <path d="M8 15h3" />
                                            </svg>
                                            E-Mail Sendebericht speichern
                                        </a>

                                        <a class="dropdown-item" *ngIf="!answer.hidden" (click)="hideAnswer(answer)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-eye-off me-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                                                <path
                                                    d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                                                <path d="M3 3l18 18" />
                                            </svg>
                                            Ausblenden
                                        </a>
                                        <a class="dropdown-item" *ngIf="answer.hidden" (click)="showAnswer(answer)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-eye me-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                                <path
                                                    d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                            </svg>
                                            Einblenden
                                        </a>

                                        <a class="dropdown-item" *ngIf="answer.edited" (click)="showHistory(answer)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-history me-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 8l0 4l2 2" />
                                                <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                                            </svg>
                                            Historie anzeigen
                                        </a>
                                        <a class="dropdown-item" (click)="deleteAnswer(answer)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-trash me-2">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l16 0" />
                                                <path d="M10 11l0 6" />
                                                <path d="M14 11l0 6" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                            Löschen
                                        </a>
                                    </div>
                                </span>
                            </div>

                            <div class="card-status-start bg-yellow" *ngIf="answer.type === 'internalAnswer'"></div>


                            <div class="card-body card-small accordion accordion-mini"
                                [id]="'accordion-metadata-' + answer._id"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && answer.template_data.length > 0">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading-1">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#collapse-1-data-' + answer._id"
                                            aria-expanded="true">
                                            <strong>Ermittelte Daten</strong>
                                        </button>
                                    </h2>
                                    <div [id]="'collapse-1-data-' + answer._id" class="accordion-collapse collapse"
                                        [attr.data-bs-parent]="'accordion-metadata-' + answer._id">
                                        <div class="accordion-body pt-0">

                                            <div class="mb-2" *ngFor="let templateDataItem of answer.template_data">
                                                <strong>{{ templateDataItem.name }}</strong> <small class="ms-1">({{
                                                    templateDataItem.display_name }})</small>
                                                <p class="metadata-info" [innerHTML]="templateDataItem.content"></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="card-body card-small"
                                *ngIf="!answer.hidden && (answer.type !== 'internalAnswer' && answer.mail_data && (answer.mail_data.cc || answer.mail_data.to))">
                                <div *ngIf="answer.mail_data.to && answer.mail_data.to.length > 0">
                                    <strong>AN: </strong> <span class="me-2"
                                        *ngFor="let recipient of answer.mail_data.to">{{
                                        recipient.name === '' ? 'Name unbekannt' : recipient.name }} <span
                                            class="text-muted">({{ recipient.address }})</span></span>
                                </div>
                                <div *ngIf="answer.mail_data.cc && answer.mail_data.cc.length > 0">
                                    <strong>CC: </strong> <span class="me-2"
                                        *ngFor="let recipient of answer.mail_data.cc">{{
                                        recipient.name === '' ? 'Name unbekannt' : recipient.name }} <span
                                            class="text-muted">({{ recipient.address }})</span></span>
                                </div>
                            </div>

                            <div class="card-body card-small"
                                *ngIf="!answer.hidden && answer.internal_mentions && answer.internal_mentions.length > 0">
                                <strong
                                    [ngbTooltip]="'Supporter die per E-Mail über diese Antwort informiert wurden.'">Erwähnungen:
                                </strong> <span class="me-2" *ngFor="let mentioned of answer.internal_mentions">{{
                                    mentioned
                                    }}</span>
                            </div>

                            <div [className]="answer.type === 'customerAnswer' ? 'card-body folded-message' : 'card-body folded-message notpublic'"
                                [innerHTML]="htmlContent(answer.text)"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && (answer.folded || answer.folded === undefined && (!editInternalAnswerMode || editInternalAnswerId !== answer._id!))">
                            </div>
                            <div [className]="answer.type === 'customerAnswer' ? 'card-body unfolded-message' : 'card-body unfolded-message notpublic'"
                                [innerHTML]="htmlContent(answer.text)"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && (answer.folded === false && (!editInternalAnswerMode || editInternalAnswerId !== answer._id!))">
                            </div>

                            <div class="card-body"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && editInternalAnswerMode && editInternalAnswerId === answer._id!">
                                <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="answer.text">
                                </ckeditor>

                                <button class="btn btn-efs mt-2"
                                    (click)="updateInternalAnswer(answer)">Speichern</button>
                            </div>

                            <div class="card-footer card-small"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false))">
                                <a [ngbTooltip]="'Direkt-Link kopieren'" (click)="copyDirectLink(answer)"
                                    class="link me-2"><svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-share" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                        <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                        <path d="M8.7 10.7l6.6 -3.4"></path>
                                        <path d="M8.7 13.3l6.6 3.4"></path>
                                    </svg></a>

                                <a *ngIf="answer.folded || answer.folded === undefined" (click)="toggleFold(answer);"
                                    class="link">Alles anzeigen</a>
                                <a *ngIf="answer.folded === false" (click)="toggleFold(answer);" class="link">Weniger
                                    anzeigen</a>

                                <a class="link ms-2" (click)="editAnswer(answer._id!)"
                                    *ngIf="answer.type === 'internalAnswer' && answer.author_user_sso_id === userData.nickname && (!editInternalAnswerMode || editInternalAnswerId !== answer._id!)">Bearbeiten</a>

                                <a class="link ms-2 hidden"
                                    (click)="answer.showReactionPicker = !answer.showReactionPicker">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-mood-plus" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M20.985 12.528a9 9 0 1 0 -8.45 8.456" />
                                        <path d="M16 19h6" />
                                        <path d="M19 16v6" />
                                        <path d="M9 10h.01" />
                                        <path d="M15 10h.01" />
                                        <path d="M9.5 15c.658 .64 1.56 1 2.5 1s1.842 -.36 2.5 -1" />
                                    </svg>
                                </a>

                                <a class="link ms-2 hidden" *ngIf="answer.unsupportedAttachments"
                                    (click)="showInvalidAttachments(answer.unsupportedAttachments)">
                                    Beinhaltet nicht importiere Anhänge
                                </a>
                            </div>


                            <div class="card-footer card-small"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && filterAttachments(answer.attachments) && filterAttachments(answer.attachments).length > 0 && !isLoadingAttachment">

                                <a class="link badge badge-gray me-1 mb-1 attachment-link"
                                    (click)="downloadAttachment(answer._id!, attachment)"
                                    *ngFor="let attachment of filterAttachments(answer.attachments)">
                                    <span class="ms-1 v-middle">{{ attachment.filename }} {{ attachment.size |
                                        attachmentSize
                                        }}</span>
                                </a>
                            </div>

                            <div class="card-footer card-small"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && getPreloadedAudioAttachment(answer._id!).length > 0">
                                <div class="audio-boxes" *ngFor="let audio of getPreloadedAudioAttachment(answer._id!)">
                                    <p class="text-muted text-center w-100 mb-0">{{ audio.fileName }}</p>
                                    <audio class="mt-2" controls>
                                        <source [src]="audio.data" type="audio/mpeg">
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </div>

                            <div class="card-footer card-small placeholder-glow"
                                *ngIf="(!(answer.hidden && answer.showHidden !== false)) && answer.attachments && answer.attachments.length > 0 && isLoadingAttachment">
                                <a class="link badge badge-gray me-1 mb-1 attachment-link"
                                    *ngFor="let attachment of answer.attachments">
                                    <span class="ms-1 v-middle">Lade ...</span>
                                </a>
                            </div>

                            <div class="mt-2 ms-3 mb-2" *ngIf="answer.showReactionPicker">
                                <emoji-mart (emojiSelect)="addReaction($event, answer)" [skin]="5"
                                    [i18n]="{ categories: { recent: 'Reaktionen' } }" [include]="['activity', 'recent']"
                                    [recent]="['1F44D', '1F44E', '2705', '231B', '274C']" [enableSearch]="false"
                                    [showPreview]="false" set="apple"></emoji-mart>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-12"
                        *ngIf="ticket.assigned_to_user_sso_id && ticket.assigned_to_user_sso_id !== userData.nickname">
                        <div class="alert alert-warning w-100 mb-1">Das Anliegen ist einer anderen Person zugeordnet!
                        </div>
                    </div>

                    <div class="col-lg-12" *ngIf="ticket.internal_status === 'merged'">
                        <div class="alert alert-warning w-100 mb-1">Diese Anfrage wurde mit <a
                                [routerLink]="['/admin/tickets', ticket.merged_with_ticket_id]">einer
                                anderen Anfrage</a> zusammengeführt. <a class="link" *ngIf="!allowAnswerInMergedTicket"
                                (click)="allowAnswerInMergedTicket=true">Dennoch hier antworten.</a></div>
                    </div>

                    <div class="col-lg-12 mt-4"
                        *ngIf="ticket.internal_status !== 'merged' || (ticket.internal_status === 'merged' && allowAnswerInMergedTicket)">
                        <div class="card">
                            <div [className]="answerMode === 'internal' ? 'card-header internal' : 'card-header'">
                                <div class="card-title" *ngIf="answerMode === 'internal'">Neue interne Antwort</div>
                                <div class="card-title" *ngIf="answerMode === 'public'">Neue öffentliche Antwort</div>
                            </div>

                            <div class="card-status-start bg-yellow card-status-start-big"
                                *ngIf="answerMode === 'internal'">
                            </div>
                            <div class="card-status-start bg-efs card-status-start-big" *ngIf="answerMode === 'public'">
                            </div>

                            <div [className]="answerMode === 'internal' ? 'card-body internal-light' : 'card-body'">

                                <!-- <button type="button" class="btn btn-yellow mb-2 me-2" (click)="changeAnswerMode('internal')"
                                    [disabled]="answerMode === 'internal'">Interne Antwort</button>
                                <button type="button" class="btn btn-efs mb-2" (click)="changeAnswerMode('public')"
                                    [disabled]="answerMode === 'public'">Öffentliche Antwort</button> -->

                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-8 col-sm-12 mb-2">

                                            <div class="form-selectgroup form-selectgroup mb-2">

                                                <label class="form-selectgroup-item">
                                                    <input type="radio" name="name" value="Interne Antwort"
                                                        class="form-selectgroup-input" *ngIf="answerMode === 'internal'"
                                                        checked>

                                                    <input type="radio" name="name" value="Interne Antwort"
                                                        class="form-selectgroup-input" *ngIf="answerMode === 'public'"
                                                        (click)="changeAnswerMode('internal')">

                                                    <span class="form-selectgroup-label">Interne Antwort</span>
                                                </label>

                                                <label class="form-selectgroup-item">
                                                    <input type="radio" name="name" value="Öffentliche Antwort"
                                                        class="form-selectgroup-input" *ngIf="answerMode === 'public'"
                                                        checked>

                                                    <input type="radio" name="name" value="Öffentliche Antwort"
                                                        class="form-selectgroup-input" *ngIf="answerMode === 'internal'"
                                                        (click)="changeAnswerMode('public')">

                                                    <span class="form-selectgroup-label">Öffentliche Antwort</span>
                                                </label>
                                            </div>

                                            <span class="form-label">Direkte Erwähnung <small class="text-muted">Gleiche
                                                    Funktion wie &commat;-Tag im Text-Editor</small></span>

                                            <!-- <span *ngFor="let user of users">
                                                <span *ngIf="mentionedUsers.includes(user.name)"
                                                    class="badge badge-outline bg-efs me-2 mb-2 link"
                                                    (click)="removeDirectMention(user.name)">{{ user.name }}</span>
                                                <span *ngIf="!mentionedUsers.includes(user.name)"
                                                    class="badge badge-outline bg-primary-lt me-2 mb-2 link"
                                                    (click)="addDirectMention(user.name)">{{ user.name }}</span>
                                            </span> -->

                                            <span *ngFor="let group of groups">
                                                <span *ngIf="mentionedGroups.includes(group.name)"
                                                    class="badge badge-outline bg-efs me-2 mb-2 link"
                                                    (click)="removeDirectMentionGroup(group.name)">{{ group.name
                                                    }}</span>
                                                <span *ngIf="!mentionedGroups.includes(group.name)"
                                                    class="badge badge-outline bg-secondary-lt me-2 mb-2 link"
                                                    (click)="addDirectMentionGroup(group.name)">{{ group.name }}</span>
                                            </span>

                                            <label class="form-label mt-2">Nachricht</label>

                                            <ckeditor #answerEditor (ready)="onEditorReady($event)" *ngIf="editorLoaded" [config]="editorConfig" [editor]="editor"
                                                [(ngModel)]="answerText">
                                            </ckeditor>

                                            <div class="row mt-3">
                                                <div class="col-lg-6">
                                                    <label class="form-label"><span
                                                            [ngbTooltip]="'Je nachdem welche Antwortziele vorhanden sind, wird automatisch die korrekte Signatur ermittelt.'">Automatisch
                                                            ausgewählte Signatur</span> <span class="ms-1 badge bg-red"
                                                            [ngbTooltip]="'Es wird keine Signatur automatisch angefügt.'"
                                                            *ngIf="disableAutoSignature">Deaktiviert</span>
                                                        <a *ngIf="!showSignature" (click)="showSignature = true"
                                                            class="ms-1 link"
                                                            [ngbTooltip]="'Um die Übersicht zu verbessern wird die Signatur nicht mehr angezeigt. Die Signatur wird beim Senden automatisch angefügt.'">anzeigen</a>
                                                        <a *ngIf="showSignature" (click)="showSignature = false"
                                                            class="ms-1 link"
                                                            [ngbTooltip]="'Um die Übersicht zu verbessern wird die Signatur nicht mehr angezeigt. Die Signatur wird beim Senden automatisch angefügt.'">ausblendet</a>
                                                        <a [routerLink]="'/my-account'" class="ms-1 link">(Signaturen
                                                            bearbeiten)</a>
                                                    </label>
                                                </div>
                                                <div class="col-auto ms-auto">
                                                    <span class="text-end"><input type="checkbox" name="" id=""
                                                            [(ngModel)]="disableAutoSignature"
                                                            (change)="checkRecipients()">
                                                        Automatische Signatur deaktivieren</span>
                                                </div>
                                            </div>



                                            <p class="signature-box" [innerHTML]="selectedSignature"
                                                *ngIf="!disableAutoSignature && showSignature"></p>

                                            <div class="row"
                                                *ngIf="(recipientsCC.length > 0 || recipientsTO.length > 0) && answerMode === 'public'">
                                                <div class="col-lg-12"><label
                                                        class="form-label mt-3">Antwortziel</label></div>
                                                <div class="col-lg-6 col-sm-12 col-12">
                                                    <label class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="radios-inline" [(ngModel)]="recipientMode"
                                                            [value]="'all'">
                                                        <span class="form-check-label"><strong>Alle
                                                                Teilnehmer</strong></span>
                                                    </label>
                                                    <div class="mt-1">
                                                        <ul style="list-style: none;" class="ps-3">
                                                            <li class="mb-2" *ngFor="let recipient of recipientsTO">
                                                                <span class="badge bg-primary me-1">An</span> <span class="link me-2" (click)="quickEditRecipient(recipient)">{{
                                                                    recipient.name ?
                                                                    recipient.name : 'Name unbekannt' }} ({{
                                                                    recipient.address }})</span>

                                                                <a class="link me-1" (click)="switchToCC(recipient)"
                                                                    ngbTooltip="Von AN nach CC wechseln"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="icon icon-tabler icon-tabler-switch-3"
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        stroke-width="2" stroke="currentColor"
                                                                        fill="none" stroke-linecap="round"
                                                                        stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                            fill="none"></path>
                                                                        <path
                                                                            d="M3 17h2.397a5 5 0 0 0 4.096 -2.133l.177 -.253m3.66 -5.227l.177 -.254a5 5 0 0 1 4.096 -2.133h3.397">
                                                                        </path>
                                                                        <path d="M18 4l3 3l-3 3"></path>
                                                                        <path
                                                                            d="M3 7h2.397a5 5 0 0 1 4.096 2.133l4.014 5.734a5 5 0 0 0 4.096 2.133h3.397">
                                                                        </path>
                                                                        <path d="M18 20l3 -3l-3 -3"></path>
                                                                    </svg></a>

                                                                <a class="link"
                                                                    (click)="removeRecipient(recipient)"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="icon icon-tabler icon-tabler-trash"
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        stroke-width="2" stroke="currentColor"
                                                                        fill="none" stroke-linecap="round"
                                                                        stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                            fill="none">
                                                                        </path>
                                                                        <path d="M4 7l16 0"></path>
                                                                        <path d="M10 11l0 6"></path>
                                                                        <path d="M14 11l0 6"></path>
                                                                        <path
                                                                            d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12">
                                                                        </path>
                                                                        <path
                                                                            d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3">
                                                                        </path>
                                                                    </svg></a>
                                                            </li>
                                                            <li class="mb-2" *ngFor="let recipient of recipientsCC">
                                                                <span class="badge bg-secondary me-1">CC</span> <span class="link me-2" (click)="quickEditRecipient(recipient)">{{
                                                                recipient.name ?
                                                                recipient.name : 'Name unbekannt' }} ({{
                                                                recipient.address }})</span>

                                                                <a class="link me-1" (click)="switchToTo(recipient)"
                                                                    ngbTooltip="Von CC nach AN wechseln"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="icon icon-tabler icon-tabler-switch-3"
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        stroke-width="2" stroke="currentColor"
                                                                        fill="none" stroke-linecap="round"
                                                                        stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                            fill="none"></path>
                                                                        <path
                                                                            d="M3 17h2.397a5 5 0 0 0 4.096 -2.133l.177 -.253m3.66 -5.227l.177 -.254a5 5 0 0 1 4.096 -2.133h3.397">
                                                                        </path>
                                                                        <path d="M18 4l3 3l-3 3"></path>
                                                                        <path
                                                                            d="M3 7h2.397a5 5 0 0 1 4.096 2.133l4.014 5.734a5 5 0 0 0 4.096 2.133h3.397">
                                                                        </path>
                                                                        <path d="M18 20l3 -3l-3 -3"></path>
                                                                    </svg></a>

                                                                <a class="link"
                                                                    (click)="removeRecipient(recipient)"><svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="icon icon-tabler icon-tabler-trash"
                                                                        width="24" height="24" viewBox="0 0 24 24"
                                                                        stroke-width="2" stroke="currentColor"
                                                                        fill="none" stroke-linecap="round"
                                                                        stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                            fill="none">
                                                                        </path>
                                                                        <path d="M4 7l16 0"></path>
                                                                        <path d="M10 11l0 6"></path>
                                                                        <path d="M14 11l0 6"></path>
                                                                        <path
                                                                            d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12">
                                                                        </path>
                                                                        <path
                                                                            d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3">
                                                                        </path>
                                                                    </svg></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-sm-12 col-12">
                                                    <label class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="radios-inline" [(ngModel)]="recipientMode"
                                                            [value]="'direct'">
                                                        <span class="form-check-label"><strong>Nur
                                                                Absender</strong></span>
                                                    </label>
                                                    <div class="mt-1">
                                                        <ul style="list-style: none;" class="ps-3">
                                                            <li *ngIf="directRecipient">
                                                                <span class="badge bg-primary me-1">An</span> <span class="link me-2" (click)="quickEditRecipient(directRecipient)">{{
                                                                    directRecipient.name ?
                                                                    directRecipient.name : 'Name unbekannt' }} ({{
                                                                    directRecipient.address }})</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4 col-sm-12">
                                            <div class="card-body">
                                                <label class="form-label">Vordefinierte Antworten</label>
                                                <div *ngIf="myPredefinedAnswers.length > 0">
                                                    Persönliche Vorlagen
                                                    <ul>
                                                        <li *ngFor="let predefinedAnswers of myPredefinedAnswers"
                                                            (click)="insertAnswer(predefinedAnswers)"><a class="link">{{
                                                                predefinedAnswers.description }}</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div *ngIf="sharedPredefinedAnswers.length > 0">
                                                    Geteilte Vorlagen
                                                    <ul>
                                                        <li *ngFor="let predefinedAnswers of sharedPredefinedAnswers"
                                                            (click)="insertAnswer(predefinedAnswers)"><a class="link">{{
                                                                predefinedAnswers.description }}</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div *ngIf="officialPredefinedAnswers.length > 0">
                                                    Offizielle Vorlagen
                                                    <ul>
                                                        <li *ngFor="let predefinedAnswers of officialPredefinedAnswers"
                                                            (click)="insertAnswer(predefinedAnswers)"><a class="link">{{
                                                                predefinedAnswers.description }}</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <a target="_blank"
                                                    [routerLink]="['/admin/predefined-answers']">Vordefinierte Antwort
                                                    erstellen</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-6" *ngIf="answerMode === 'public'">

                                        <label class="form-label mt-3">Antwortziel hinzufügen</label>

                                        <div class="row g-2">
                                            <div class="col-1">

                                                <select class="form-control" [(ngModel)]="newRecipient.mode">
                                                    <option [ngValue]="'CC'">CC</option>
                                                    <option [ngValue]="'TO'">An</option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <input type="text" class="form-control" placeholder="Name"
                                                    [(ngModel)]="newRecipient.name">
                                            </div>
                                            <div class="col">
                                                <input type="text" class="form-control" placeholder="E-Mail Adresse"
                                                    [(ngModel)]="newRecipient.email">
                                            </div>
                                            <div class="col-auto">
                                                <a (click)="addNewRecipient()" class="btn btn-icon" aria-label="Button">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        class="icon icon-tabler icon-tabler-plus" width="24" height="24"
                                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M12 5l0 14"></path>
                                                        <path d="M5 12l14 0"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group mt-3">
                                            <label class="form-label">Anhang</label>
                                            <input type="file" class="form-control" multiple
                                                accept="video/mp4, video/quicktime, message/rfc822, application/pdf, image/*, text/csv, text/xml, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/webm"
                                                (change)="fileUploadEvent($event)">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mt-3">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-6 col-xs-6 mb-2">
                                            <label class="form-label">Status</label>
                                            <select type="file" class="form-control"
                                                [(ngModel)]="ticketStatusUpdate.status">
                                                <option [value]="'open'">Offen</option>
                                                <option [value]="'closed'">Geschlossen</option>
                                                <option [value]="'answered'">Beantwortet</option>
                                                <option [value]="'in_progress'">In Bearbeitung</option>
                                                <option [value]="'more_information_needed'">Mehr Informationen benötigt
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 col-sm-6 col-xs-6 mb-2">
                                            <label class="form-label">Priorität</label>
                                            <select class="form-control" [(ngModel)]="ticketStatusUpdate.priority">
                                                <option [value]="'none'">Keine Eskalation</option>
                                                <option [value]="'low'">Niedrig</option>
                                                <option [value]="'medium'">Mittel</option>
                                                <option [value]="'high'">Hoch</option>
                                                <option [value]="'asap'">ASAP</option>
                                            </select>
                                        </div>


                                        <div class="col-md-3 col-sm-6 col-xs-6 mb-2">
                                            <label class="form-label">Gruppe zuweisen</label>
                                            <select type="file" class="form-control"
                                                [(ngModel)]="ticketStatusUpdate.group_id">
                                                <option [value]="group._id" *ngFor="let group of groups">{{ group.name
                                                    }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 col-sm-6 col-xs-6 mb-2">
                                            <label class="form-label">Benutzer zuweisen</label>
                                            <select type="file" class="form-control"
                                                [(ngModel)]="ticketStatusUpdate.assigned_to_user_sso_id">
                                                <option [value]="undefined">Keine Zuweisung</option>
                                                <option [value]="user.user_sso_id" *ngFor="let user of users">{{
                                                    user.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div [className]="answerMode === 'internal' ? 'card-footer internal' : 'card-footer'">
                                <button type="button" *ngIf="answerMode === 'internal'" class="btn btn-efs"
                                    (click)="answerTicket()" [disabled]="isLoading">Interne Antwort senden</button>
                                <button type="button" *ngIf="answerMode === 'public'" class="btn btn-efs"
                                    (click)="answerTicket()" [disabled]="isLoading">Öffentliche Antwort senden</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>