import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuditComponent } from "./audit/audit.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { IncidentEditComponent } from "./incidents-edit/incidents-edit.component";
import { IncidentsComponent } from "./incidents/incidents.component";
import { ManageTicketComponent } from "./manage-ticket/manage-ticket.component";
import { PredefinedAnswerEditComponent } from "./predefined-answer-edit/predefined-answer-edit.component";
import { PredefinedAnswersComponent } from "./predefined-answers/predefined-answers.component";
import { TemplateEditComponent } from "./template-edit/template-edit.component";
import { TemplatesComponent } from "./templates/templates.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { UserEditComponent } from "./user-edit/user-edit.component";
import { UsersComponent } from "./users/users.component";
import { GroupsComponent } from "./groups/groups.component";
import { GroupEditComponent } from "./group-edit/group-edit.component";
import { LabelEditComponent } from "./label-edit/label-edit.component";
import { LabelsComponent } from "./labels/labels.component";
import { ActivitiesComponent } from "./activities/activities.component";
import { PrioritiesComponent } from "./priorities/priorities.component";
import { PriorityEditComponent } from "./priority-edit/priority-edit.component";
import { AttachmentShowComponent } from "./attachment-show/attachment-show.component";
import { SearchComponent } from "./search/search.component";

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent
    },
    {
        path: 'admin/dashboard',
        component: DashboardComponent
    },
    {
        path: 'admin/tickets',
        component: TicketsComponent
    },
    {
        path: 'admin/bugreports',
        component: TicketsComponent
    },
    {
        path: 'admin/featurerequests',
        component: TicketsComponent
    },
    {
        path: 'admin/activities',
        component: ActivitiesComponent
    },
    {
        path: 'admin/tickets/my',
        component: TicketsComponent
    },
    {
        path: 'admin/bugreports/my',
        component: TicketsComponent
    },
    {
        path: 'admin/featurerequests/my',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/open',
        component: TicketsComponent
    },
    {
        path: 'admin/bugreports/open',
        component: TicketsComponent
    },
    {
        path: 'admin/featurerequests/open',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/inprogress',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/waiting',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/escalated',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/unassigned',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/mail',
        component: TicketsComponent
    },
    {
        path: 'admin/search',
        component: SearchComponent,
    },
    {
        path: 'admin/tickets/landingpage',
        component: TicketsComponent
    },
    {
        path: 'admin/tickets/:id',
        // redirectTo: 't/:id'
        component: ManageTicketComponent
    },
    {
        path: 't/:id',
        component: ManageTicketComponent
    },
    {
        path: 'admin/attachment/:id/:attachment_id',
        component: AttachmentShowComponent
    },
    {
        path: 'admin/incidents',
        component: IncidentsComponent
    },
    {
        path: 'admin/incidents/:id',
        component: IncidentEditComponent
    },
    {
        path: 'admin/templates',
        component: TemplatesComponent
    },
    {
        path: 'admin/templates/:id',
        component: TemplateEditComponent
    },
    {
        path: 'admin/predefined-answers',
        component: PredefinedAnswersComponent
    },
    {
        path: 'admin/predefined-answers/:id',
        component: PredefinedAnswerEditComponent
    },
    {
        path: 'admin/audit-logs',
        component: AuditComponent
    },
    {
        path: 'admin/users',
        component: UsersComponent
    },
    {
        path: 'admin/users/:id',
        component: UserEditComponent
    },
    {
        path: 'admin/groups',
        component: GroupsComponent
    },
    {
        path: 'admin/groups/:id',
        component: GroupEditComponent
    },
    {
        path: 'admin/labels',
        component: LabelsComponent
    },
    {
        path: 'admin/labels/:id',
        component: LabelEditComponent
    },
    {
        path: 'admin/priorities',
        component: PrioritiesComponent
    },
    {
        path: 'admin/priority/:id',
        component: PriorityEditComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class AdminRoutingModule { }
  