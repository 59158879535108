import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, debounceTime, delay, Observable, of, share, startWith, Subject, switchMap, tap } from 'rxjs';
import { TicketDto } from '../../dtos/ticket.dto';
import { showError } from '../../helper/utils.helper';
import { Page } from '../../models/page.model';
import { TicketApiService } from '../../services/ticket-api.service';
import { PreloaderService } from '../../services/preloader.service';
import { Select2Data } from 'ng-select2-component';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyTicketsComponent implements OnInit {

  loading = false;
  filterForm: FormGroup;
  pageDataObserver: (Observable<Page<TicketDto>> | Observable<any>) | undefined;
  pageDataError$ = new Subject<any>();
  page = 1;

  filter = "";

  sort = {
    key: 'ticket_number',
    order: 'desc',
  }

  filtersSelect2: Select2Data = [
    {
      value: 'open',
      label: 'Offen',
      templateId: 'template',
      data: { color: '#0054a6', filterKey: 'status' },
    },
    {
      value: 'closed',
      label: 'Geschlossen',
      templateId: 'template',
      data: { color: '#667382', filterKey: 'status' },
    },
    {
      value: 'answered',
      label: 'Beantwortet',
      templateId: 'template',
      data: { color: '#2fb344', filterKey: 'status' },
    },
    {
      value: 'in_progress',
      label: 'In Bearbeitung',
      templateId: 'template',
      data: { color: '#2fb344', filterKey: 'status' },
    },
    {
      value: 'more_information_needed',
      label: 'Weitere Info. benötigt',
      templateId: 'template',
      data: { color: '#f76707', filterKey: 'status' },
    }
  ];

  constructor(
    private preloadService: PreloaderService,
    private ticketApi: TicketApiService,
  ) {
    this.filterForm = new FormGroup({
      search: new FormControl(),
      page: new FormControl(1),
      statusFilters: new FormControl([]),
    });

    this.preloadService.dataLoaded.subscribe({
      next: () => {
        this.pageDataObserver = this.filterForm.valueChanges.pipe(
          debounceTime(200),
          startWith(this.filterForm.value),
          delay(0),
          tap(() => this.loading = true),
          switchMap(urlOrFilter => {

            urlOrFilter.sort = this.sort.key;
            urlOrFilter.sortOrder = this.sort.order;

            if (urlOrFilter.statusFilters && urlOrFilter.statusFilters.length > 0) {
              urlOrFilter.statusFilters = urlOrFilter.statusFilters.map((f: string) => { return this.filtersSelect2.find((el: any) => el.value === f)});
            }

            return this.ticketApi.getMyTickets(urlOrFilter)
          }),
          share(),
          catchError((error) => {
            showError(error);
            this.loading = false;
            return of();
          }),
          tap(() => this.loading = false),
        );

        this.filterForm.get('search')?.valueChanges.subscribe(val => {
          this.firstPage();
        });
      },
      error: (err) => {
        this.loading = false;
        showError(err);
      },
    });
  }

  setFilter(filter: string) {
    this.filter = filter;
    this.firstPage();
  }

  nextPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current + 1 });
  }

  previousPage() {
    const current = this.filterForm.controls['page'].value;
    if (current > 1) {
      this.filterForm.patchValue({ page: current - 1 });
    }
  }

  firstPage() {
    this.filterForm.patchValue({ page: 1 });
  }

  ngOnInit(): void {
  }

}
