import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './homepage/homepage.component';
import { ViewTicketComponent } from './view-ticket/view-ticket.component';
import { NewTicketComponent } from './new-ticket/new-ticket.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { RouterModule } from '@angular/router';
import { ThemeModule } from '../theme/theme.module';
import { MyAccountComponent } from './my-account/my-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng-select2-component';

@NgModule({
  declarations: [
    HomepageComponent,
    ViewTicketComponent,
    NewTicketComponent,
    MyTicketsComponent,
    MyAccountComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    CKEditorModule,
    NgbTooltipModule,
    Select2Module
  ],
  exports: [
    HomepageComponent,
    ViewTicketComponent,
    NewTicketComponent,
    MyTicketsComponent,
    MyAccountComponent
  ]
})
export class PagesModule { }
