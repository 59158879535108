<div class="page-body">

    <div class="container container-slim py-4" *ngIf="isLoading">
        <div class="text-center">
            <div class="mb-3">
            </div>
            <div class="text-muted mb-3">Lade Daten</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="!isLoading">
        <div class="row row-deck row-cards">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body" *ngIf="userData">

                        <div class="row">

                            <div class="col-auto" *ngIf="userData.picture">
                                <img class="img-fluid rounded" style="height: 100px;" src="" />
                                <span class="avatar avatar-lg rounded"
                                    style="background-image: url(data:image/png;base64,{{ userData.picture }})"></span>
                            </div>

                            <div class="col">

                                <h1 class="fw-bold mb-3">{{ userData.name }} <span class="text-muted">({{
                                        userData.nickname }})</span></h1>


                                <div class="list-inline list-inline-dots">
                                    <div class="list-inline-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at"
                                            width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                            stroke="currentColor" fill="none" stroke-linecap="round"
                                            stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                            <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                                        </svg>
                                        E-Mail: <strong>{{ userData.email }}</strong>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-3">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Signatur <span class="card-subtitle">Lege hier deine Signatur fest, die
                                beim Antworten auf Anliegen automatisch angefügt wird.</span></h2>
                        <div class="card-actions">
                            <a (click)="updateSignature()" class="btn btn-efs">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                                    <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                    <path d="M14 4l0 4l-6 0l0 -4"></path>
                                 </svg>
                                Speichern
                            </a>
                        </div>
                    </div>
                    <div class="card-body">
                        <label class="mb-2" *ngIf="userData.role.includes('efsticket_view')">Signatur für interne Mails</label>
                        <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="signature"></ckeditor>

                    </div>

                    <div class="card-body" *ngIf="userData.role.includes('efsticket_view')">
                        <label class="mb-2">Signatur für EFS Mails <span class="text-muted ms-1">(Empfänger sind Vertriebspartner oder Innendienstler mit offizieller EFS-Mail Adresse)</span></label>
                        <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="signature_company"></ckeditor>
                    </div>

                    <div class="card-body" *ngIf="userData.role.includes('efsticket_view')">
                        <label class="mb-2">Signatur für öffentliche Mails<span class="text-muted ms-1">(Empfänger sind Personen ohne offizielle EFS-Mail Adresse)</span></label>
                        <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="signature_public"></ckeditor>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>