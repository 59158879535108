import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError, retryWhen, delay } from 'rxjs/operators';

export class Page<T> {
  total: number = 0;      // total number of items
  currentPage: number = 1;
  pageSize: number = 25;
  data: Array<T> = [];  // items for the current page

  maxPages() {
    return Math.ceil(this.total / this.pageSize) > 0 ? Math.ceil(this.total / this.pageSize) : 1;
  }
}

/**
 * Function to set query params and use of Paginated results
 * @param http The HttpClient Instance used to send the requests
 * @param baseUrl
 * @param urlOrFilter
 * @param httpOptions use empty object {} if unknown. Usage of null or undefined will lead to errors
 * @returns Observable<Page<T>>
 */
export function queryPaginated<T>(
  http: HttpClient,
  baseUrl: string,
  urlOrFilter?: object | string,
  httpOptions?: any,
  timeoutValue?: number,
): Observable<Page<T>> {

  let params = new HttpParams();
  let url = baseUrl;

  if (typeof urlOrFilter === 'string') {
    // we were given a page URL, use it
    url = urlOrFilter;
  } else if (typeof urlOrFilter === 'object') {
    // we were given filtering criteria, build the query string
    Object.keys(urlOrFilter).sort().forEach(key => {
      const value = (urlOrFilter as any)[key];
      if (value !== null) {
        params = params.set(key, value.toString());
      }
    });
  }

  const headers = httpOptions.headers || new HttpHeaders();

  const options = {params: params, headers: headers};
  const observable = http.get<Page<T>>(url, options);

  if (timeoutValue) {
    return observable.pipe(
      timeout(timeoutValue),
      retryWhen(error => error.pipe(
        delay(500),
        timeout(timeoutValue),
        catchError(err => {
          return throwError(() => new Error('Die API-Anfrage konnte leider nicht schnell genug vom Server beantwortet werden. Bitte versuche es später erneut.'));
        }),
      )),
    );
  }

  return observable;
}
