<div class="page-body">
    <div class="container container-slim py-4" *ngIf="isLoading">
        <div class="text-center">
            <div class="mb-3">
            </div>
            <div class="text-muted mb-3">Lade Daten</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="!isLoading">
        <div class="row row-deck row-cards mb-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Meine Vorlagen</h3>
                        <div class="card-actions">
                            <a [routerLink]="'/admin/predefined-answers/new'" class="btn btn-efs">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12 5l0 14"></path>
                                    <path d="M5 12l14 0"></path>
                                 </svg>
                                Neue Vorlage erstellen
                            </a>
                        </div>
                    </div>

                    <div class="card-body" *ngIf="!isLoading && myPredefinedAnswers.length === 0">
                        <div class="alert alert-warning">Keine geteilten Vorlagen gefunden!</div>
                    </div>

                    <div class="table-responsive" *ngIf="!isLoading && myPredefinedAnswers.length > 0">
                        <table class="table table-vcenter table-mobile-md card-table">
                            <thead>
                                <tr>
                                    <th>Vorlage</th>
                                    <th>Geteilt</th>
                                    <th class="w-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let answer of myPredefinedAnswers">
                                    <td data-label="Vorlage">{{ answer.description }}</td>
                                
                                    <td data-label="Geteilt">
                                        <span class="badge bg-efs" *ngIf="answer.public">Ja</span>
                                        <span class="badge bg-efs" *ngIf="!answer.public">Nein</span>
                                    </td>

                                    <td>
                                        <div class="btn-list flex-nowrap">
                                            <button class="btn" (click)="showPredefinedAnswer(answer)">Anzeigen</button>
                                            <button class="btn" [routerLink]="['/admin/predefined-answers', answer._id]">Bearbeiten</button>
                                            <button class="btn" (click)="deletePredefinedAnswer(answer)">Löschen</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-deck row-cards mb-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Geteilte Vorlagen</h3>
                    </div>

                    <div class="card-body" *ngIf="!isLoading && sharedPredefinedAnswers.length === 0">
                        <div class="alert alert-warning">Keine geteilten Vorlagen gefunden!</div>
                    </div>

                    <div class="table-responsive" *ngIf="!isLoading && sharedPredefinedAnswers.length > 0">
                        <table class="table table-vcenter table-mobile-md card-table">
                            <thead>
                                <tr>
                                    <th>Vorlage</th>
                                    <th class="w-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let answer of sharedPredefinedAnswers">
                                    <td data-label="Vorlage">{{ answer.description }}</td>
                                
                                    <td>
                                        <button (click)="showPredefinedAnswer(answer)" class="btn">Anzeigen</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Öffentliche Vorlagen</h3>
                    </div>

                    <div class="card-body" *ngIf="!isLoading && publicPredefinedAnswers.length === 0">
                        <div class="alert alert-warning">Keine geteilten Vorlagen gefunden!</div>
                    </div>

                    <div class="table-responsive" *ngIf="!isLoading && publicPredefinedAnswers.length > 0">
                        <table class="table table-vcenter table-mobile-md card-table">
                            <thead>
                                <tr>
                                    <th>Vorlage</th>
                                    <th class="w-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let answer of publicPredefinedAnswers">                                
                                    <td data-label="Vorlage">{{ answer.description }}</td>

                                    <td>
                                        <button (click)="showPredefinedAnswer(answer)" class="btn">Anzeigen</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>