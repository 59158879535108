import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AttachmentSizePipe } from './pipes/attachment-size.pipe';
import { TimediffPipe } from './pipes/timediff.pipe';
import { ConvertsecondsPipe } from './pipes/convertseconds.pipe';
import { WorkdaysPipe } from './pipes/workdays.pipe';
import { FileSizePipe } from './pipes/filesize.pipe';



@NgModule({
  declarations: [
    SidebarComponent,
    FooterComponent,
    AttachmentSizePipe,
    TimediffPipe,
    ConvertsecondsPipe,
    WorkdaysPipe,
    FileSizePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SidebarComponent,
    FooterComponent,
    AttachmentSizePipe,
    TimediffPipe,
    ConvertsecondsPipe,
    WorkdaysPipe,
    FileSizePipe
  ],
})
export class ThemeModule { }
