<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title" *ngIf="mode === 'new'">Vordefinierte Antwort erstellen</h3>
                        <h3 class="card-title" *ngIf="mode === 'edit'">Vordefinierte Antwort bearbeiten</h3>
                    </div>

                    <div class="card-body">

                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Eine kurze Beschreibung die den Inhalt der Antwort erahnen lässt">Beschreibung</span></label>
                                    <input type="text" class="form-control" required name="" id="" [(ngModel)]="predefinedAnswer.description">
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Wenn die Antwort speziell für eine App ist, kann hier die App Ausgewählt werden">App</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="predefinedAnswer.app_id">
                                        <option [value]="''"></option>
                                        <option *ngFor="let app of apps" [value]="app._id">{{ app.name }} [{{ app.countries.join(', ').toUpperCase() }}]</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Gibt an ob andere Mitarbeiter diese Antwort verwenden können">Für andere Mitarbeiter teilen</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="predefinedAnswer.public">
                                        <option [ngValue]="true">Ja</option>
                                        <option [ngValue]="false">Nein</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group">
                                    <label class="form-label"><span popperPlacement="right" popper="Gibt an ob die Antwort als offiziell gilt und von allen Mitarbeitern verwendet werden kann. Offizielle Antworten können nur von Administratoren erstellt werden">Offizielle Antworten</span></label>
                                    <select class="form-control" required name="" id="" [(ngModel)]="predefinedAnswer.official">
                                        <option [ngValue]="false">Nein</option>
                                        <option [ngValue]="true">Ja</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label">Inhalt</label>
                                    <p>
                                        Der Platzhalter <strong>%name</strong> wird automatisch durch den Namen und die Anrede des Anliegen-Autors ersetzt.
                                    </p>

                                    <ckeditor [config]="editorConfig" [editor]="editor" [(ngModel)]="predefinedAnswer.text"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-efs" *ngIf="mode === 'edit'" (click)="save()">Speichern</button>
                        <button class="btn btn-efs" *ngIf="mode === 'new'" (click)="create()">Anlegen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>