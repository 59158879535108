import { IncidentHistoryDto } from "./incident-history.dto";

export class IncidentDto extends Object {  
    public _id?: string = "";
    public apps?: string[] = [];
    public state: string = "";
    public severity: string = "";
    public language: string = "";
    public deleteAfterExpiry: boolean = false;
    public publishOnLandingpage: boolean = false;
    public autoResolveAfterEndDate: boolean = false;
    public announceToTickets?: boolean = false;
    public public: boolean = false;
    public created_at?: Date = new Date();
    public updated_at?: Date = new Date();
    public start?: string = '';
    public startReadable?: string = '';
    public end?: string = '';
    public endReadable?: string = '';
    public description: string = "";
    public text: string = "";
    public author_user_sso_id?: string = "";
    public author_name?: string = "";
    public author_email?: string = "";
    public attachedTickets?: string[] = [];
    public history?: IncidentHistoryDto[] = [];
  
    public static fromObject(src: any) {
      return Object.assign(new IncidentDto(), src);
    }
  }