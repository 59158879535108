export class GroupDto extends Object {
    
    public _id?: string = "";  
    public name: string = "";
    public ingest_mailboxes: string[] = [];
    public ldap_group_name?: string[] = [];

    public ingest_mailboxes_string?: string = "";
  
    public static fromObject(src: any) {
      return Object.assign(new GroupDto(), src);
    }
  }